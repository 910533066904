import { useCallback, useEffect, useMemo, useState } from "react";
import { Col, Form, Row, Modal, Button } from "react-bootstrap";
import { ConfirmationModal } from "../../component-library";
import { useAppSettings, useTenants } from "../../contexts";
import { FormProps, Tenant } from "../../models";

interface CustomerFormModel {
    ahcvIdentifier: string;
    name: string;
    parentTenantId: number;
    isActive: boolean;
    usersCount: number;
    stateId: number;
}

export const CustomerForm = ({ onClose, show, type }: FormProps) => {
    const { customerName, partnerName } = useAppSettings();
    const { createTenant, deleteTenant, editTenant, restoreTenant, selectedTenant, tenants, states } = useTenants();

    const [isSubmitting, setIsSubmitting] = useState(false);
    const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false);
    const [showConfirmRestoreModal, setShowConfirmRestoreModal] = useState(false);

    const [errors, setErrors] = useState({
        ahcvIdentifier: "",
        name: "",
        stateId: "",
        parentTenantId: "",
    });

    const [formValues, setFormValues] = useState<CustomerFormModel>({
        ahcvIdentifier: "",
        name: "",
        parentTenantId: 0,
        isActive: true,
        usersCount: 0,
        stateId: 0,
    });

    useEffect(() => {
        if (!selectedTenant) return;
        setFormValues({
            ...selectedTenant,
            parentTenantId: selectedTenant.parentTenantId ?? 0,
            stateId: selectedTenant.stateId ?? 0,
        });
    }, [selectedTenant]);

    const partnerTenants = useMemo(
        () => [...tenants].sort((a, b) => a.name.localeCompare(b.name)).filter((t) => !t.parentTenantId),
        [tenants]
    );

    const isFormValid = useCallback(() => {
        let valid = true;
        if (!formValues.ahcvIdentifier) {
            setErrors((prev) => ({ ...prev, ahcvIdentifier: "State ID is required" }));
            valid = false;
        }
        if (formValues.ahcvIdentifier.length > 128) {
            setErrors((prev) => ({ ...prev, ahcvIdentifier: "State ID must be 128 characters or less" }));
            valid = false;
        }
        if (!formValues.name) {
            setErrors((prev) => ({ ...prev, name: "Name is required" }));
            valid = false;
        }
        if (formValues.name.length > 128) {
            setErrors((prev) => ({ ...prev, name: "Name must be 128 characters or less" }));
            valid = false;
        }
        if (formValues.stateId === 0) {
            setErrors((prev) => ({ ...prev, stateId: "State is required" }));
            valid = false;
        }
        if (formValues.parentTenantId === 0) {
            setErrors((prev) => ({ ...prev, parentTenantId: "System is required" }));
            valid = false;
        }
        return valid;
    }, [formValues]);

    const handleSubmit = useCallback(async () => {
        setErrors({
            ahcvIdentifier: "",
            name: "",
            stateId: "",
            parentTenantId: "",
        });
        if (!isFormValid()) return;
        setIsSubmitting(true);
        let success = false;
        if (type === "add") {
            success = await createTenant({
                tenant: {
                    ...formValues,
                    id: 0,
                    logoUri: "",
                    county: "",
                },
            });
        } else {
            if (!selectedTenant) return;
            success = await editTenant({
                operable: {
                    original: selectedTenant,
                    updated: {
                        ...selectedTenant,
                        ...formValues,
                    },
                },
            });
        }
        if (success) {
            onClose();
        } else {
            setIsSubmitting(false);
        }
    }, [formValues, createTenant, editTenant, selectedTenant, isFormValid]);

    const handleDeleteCustomer = useCallback(
        async (tenant: Tenant) => {
            const success = await deleteTenant(tenant);
            if (success) {
                setShowConfirmDeleteModal(false);
                onClose();
            }
        },
        [deleteTenant, onClose]
    );

    const handleRestoreCustomer = useCallback(
        async (tenant: Tenant) => {
            const success = await restoreTenant(tenant);
            if (success) {
                setShowConfirmRestoreModal(false);
                onClose();
            }
        },
        [restoreTenant, onClose]
    );

    return (
        <>
            <Modal show={show} backdrop="static" keyboard={false} size="lg">
                <Modal.Header>{type === "add" ? `Add ${customerName}` : `Edit ${customerName}`}</Modal.Header>
                <Modal.Body>
                    <Form id="customerForm">
                        <Row>
                            <Col sm={4}>
                                <Form.Group controlId="parentTenant" className="mb-2">
                                    <Form.Label>{partnerName}</Form.Label>
                                    <Form.Select
                                        value={formValues.parentTenantId}
                                        onChange={(e) =>
                                            setFormValues((prev) => ({ ...prev, parentTenantId: +e.target.value }))
                                        }
                                        isInvalid={!!errors.parentTenantId}
                                    >
                                        <option disabled value={0}>
                                            Select a {partnerName}...
                                        </option>
                                        {partnerTenants.map((tenant) => (
                                            <option key={tenant.id} value={tenant.id}>
                                                {tenant.name}
                                            </option>
                                        ))}
                                    </Form.Select>
                                    <Form.Control.Feedback type="invalid">
                                        {errors.parentTenantId}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                            <Col sm={4}>
                                <Form.Group controlId="facilityState" className="mb-2">
                                    <Form.Label>State</Form.Label>
                                    <Form.Select
                                        value={formValues.stateId}
                                        onChange={(e) =>
                                            setFormValues((prev) => ({ ...prev, stateId: +e.target.value }))
                                        }
                                        isInvalid={!!errors.stateId}
                                    >
                                        <option disabled value={0}>
                                            Select a State...
                                        </option>
                                        {states.map((state) => (
                                            <option key={state.abbreviation} value={state.id}>
                                                {state.name}
                                            </option>
                                        ))}
                                    </Form.Select>
                                    <Form.Control.Feedback type="invalid">{errors.stateId}</Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                            <Col sm={4}>
                                <Form.Group controlId="facilityId" className="mb-2">
                                    <Form.Label>State ID</Form.Label>
                                    <Form.Control
                                        value={formValues.ahcvIdentifier}
                                        onChange={(e) =>
                                            setFormValues((prev) => ({ ...prev, ahcvIdentifier: e.target.value }))
                                        }
                                        isInvalid={!!errors.ahcvIdentifier}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.ahcvIdentifier}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                            <Col sm={4}>
                                <Form.Group controlId="customerName" className="mb-2">
                                    <Form.Label>Name</Form.Label>
                                    <Form.Control
                                        value={formValues.name}
                                        onChange={(e) => setFormValues((prev) => ({ ...prev, name: e.target.value }))}
                                        isInvalid={!!errors.name}
                                    />
                                    <Form.Control.Feedback type="invalid">{errors.name}</Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                        </Row>
                    </Form>
                </Modal.Body>
                <Modal.Footer className="d-flex justify-content-between">
                    <Button variant="secondary" onClick={onClose}>
                        Cancel
                    </Button>
                    <div>
                        {type === "edit" && selectedTenant?.isActive && (
                            <Button variant="danger" onClick={() => setShowConfirmDeleteModal(true)}>
                                Deactivate
                            </Button>
                        )}
                        {type === "edit" && !selectedTenant?.isActive && (
                            <Button variant="success" onClick={() => setShowConfirmRestoreModal(true)} className="ms-2">
                                Activate
                            </Button>
                        )}
                        {(!selectedTenant || selectedTenant?.isActive) && (
                            <Button variant="primary" onClick={handleSubmit} className="ms-2" disabled={isSubmitting}>
                                {isSubmitting ? "Saving..." : "Save"}
                            </Button>
                        )}
                    </div>
                </Modal.Footer>
            </Modal>
            {selectedTenant && (
                <>
                    <ConfirmationModal
                        id="confirmDeleteCustomerModal"
                        show={showConfirmDeleteModal}
                        title={`Deactivate ${customerName ?? "customer"}?`}
                        message={
                            `Do you really want to deactivate this $${customerName ?? "customer"}?` +
                            ` They will no longer have access to the portal or documents.`
                        }
                        onCloseButtonClick={() => setShowConfirmDeleteModal(false)}
                        onOkButtonClick={() => handleDeleteCustomer(selectedTenant)}
                        theme={"danger"}
                    />
                    <ConfirmationModal
                        id="confirmRestoreCustomerModal"
                        show={showConfirmRestoreModal}
                        title={`Activate ${customerName ?? "customer"}?`}
                        message={`Do you really want to activate this ${customerName ?? "customer"}?`}
                        onCloseButtonClick={() => setShowConfirmRestoreModal(false)}
                        onOkButtonClick={() => handleRestoreCustomer(selectedTenant)}
                        theme={"danger"}
                    />
                </>
            )}
        </>
    );
};
