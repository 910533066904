import { createContext } from "react";
import { CreateTenant, CustomerMenuItem, EditTenant, State, Tenant } from "../../models";

export interface TenantState {
    tenants: Tenant[];
    selectedTenant?: Tenant;
    currentTenant?: Tenant;
    parentTenant?: Tenant;
    customerMenuItems: CustomerMenuItem[];
    states: State[];
    authorizedTenants: Tenant[];
}

interface ITenantContext extends TenantState {
    getTenants: () => Promise<void>;
    getAuthorizedTenants: () => Promise<void>;
    getParentTenant: (tenantId: number) => Promise<void>;
    getCustomerMenuItems: (tenantId: number) => Promise<void>;
    createTenant: (createTenant: CreateTenant) => Promise<boolean>;
    editTenant: (editTenant: EditTenant) => Promise<boolean>;
    deleteTenant: (tenant: Tenant) => Promise<boolean>;
    restoreTenant: (tenant: Tenant) => Promise<boolean>;
    selectTenant: (tenant: Tenant) => void;
    clearSelectedTenant: () => void;
}

export const TenantContext = createContext<ITenantContext | undefined>(undefined);
TenantContext.displayName = "Tenant";
