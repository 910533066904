interface BooleanIconProps {
    value: boolean;
}

export const BooleanIcon = ({ value }: BooleanIconProps) => {
    if (value) {
        return <i className="fas fa-check" style={{ color: "green" }} />;
    }
    return <i className="fas fa-times" style={{ color: "red" }} />;
};
