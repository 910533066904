import { ColumnDef } from "@tanstack/react-table";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { ConfirmationModal, DataTable } from "../../component-library";
import { useAuth, useTenants } from "../../contexts";
import { useFacilityFiles } from "../../contexts/facility-file";
import { useFileTypes } from "../../contexts/filetype";
import { getDateWithMonthName } from "../../helper-functions/getDateWithMonthName";
import { FormProps } from "../../models";
import { FacilityFileEditForm } from "./FacilityFileEditForm";
import AccessDeniedMessage from "../access-denied/AccessDenied";

export default function FacilityFiles() {
    const { decodedAccessToken } = useAuth();
    const isAdmin = decodedAccessToken.roles === "GlobalAdmin";
    const { getAllFacilityFiles, selectFile, facilityFiles, downloadFacilityFileFromS3, deleteFacilityFile } =
        useFacilityFiles();
    const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState<boolean>(false);
    const [deleteFilename, setDeleteFilename] = useState<string>("");
    const [deleteFileId, setDeleteFileId] = useState<number>(0);
    const { tenants, states } = useTenants();
    const { fileTypes } = useFileTypes();
    const [fileEditFormProps, setFileEditFormProps] = useState<FormProps>({
        show: false,
        type: "add",
        onClose: () => {},
    });

    useEffect(() => {
        getAllFacilityFiles();
    }, []);

    const facilityFileData = useMemo(
        () =>
            Object.entries(facilityFiles.filter((ff) => !ff.deletedOn)).map(([key, value]) => ({
                filename: value.filename,
                fileType: fileTypes.find((ft) => ft.id === value.fileTypeId)?.name,
                system: tenants.find((t) => t.id === value.systemId)?.name,
                state: states.find((s) => s.id === value.stateId)?.name,
                county: value.county,
                isActive: value.isActive ? "Yes" : "No",
                id: value.id,
                linkname: value.linkName,
                uploadDate: getDateWithMonthName(value.createdOn),
                key: key,
            })),
        [facilityFiles, fileTypes, states, tenants]
    );

    const hideFileEditModal = () => setFileEditFormProps((prev) => ({ ...prev, show: false }));
    const showFileEditModal = useCallback(
        (id: number) => {
            const facilityFile = facilityFiles.find((ff) => ff.id === id);
            selectFile(facilityFile!);
            setFileEditFormProps({
                onClose: hideFileEditModal,
                show: true,
                type: "edit",
            });
        },
        [facilityFiles, selectFile]
    );

    //Download File
    const downloadFileFromS3 = useCallback(
        (awsFileName: string, filename: string) => {
            downloadFacilityFileFromS3(awsFileName, filename);
        },
        [downloadFacilityFileFromS3]
    );

    const showDeleteModal = useCallback((fileId: number, filename: string) => {
        setShowConfirmDeleteModal(true);
        setDeleteFilename(filename);
        setDeleteFileId(fileId);
    }, []);

    const handleDeleteFile = useCallback(async () => {
        const facilityFile = facilityFiles.find((ff) => ff.id === deleteFileId);
        if (!facilityFile) return;
        const success = await deleteFacilityFile({
            original: facilityFile,
            updated: {
                ...facilityFile,
                isActive: false,
            },
        });
        if (success) {
            setShowConfirmDeleteModal(false);
        }
    }, [deleteFacilityFile, deleteFileId, facilityFiles]);

    const columns = useMemo(
        (): ColumnDef<any>[] => [
            {
                header: () => <div style={{ whiteSpace: "nowrap" }}>Display Name</div>,
                accessorKey: "linkname",
            },
            {
                header: "Filename",
                accessorKey: "filename",
            },
            {
                header: () => <div style={{ whiteSpace: "nowrap" }}>File Type</div>,
                accessorKey: "fileType",
            },
            {
                header: "System",
                accessorKey: "system",
            },
            {
                header: "State",
                accessorKey: "state",
            },
            {
                header: "County",
                accessorKey: "county",
            },
            {
                header: () => <div style={{ whiteSpace: "nowrap" }}>Upload Date</div>,
                accessorKey: "uploadDate",
            },
            {
                header: "Active",
                accessorKey: "isActive",
            },
            {
                header: "",
                id: "actions",
                cell: ({ row }) => {
                    return (
                        <div className="d-flex justify-items-end align-items-center">
                            <Button
                                size="sm"
                                variant="outline-info"
                                title="Edit"
                                onClick={() => showFileEditModal(row.original.id)}
                            >
                                <i className="fas fa-edit" />
                            </Button>
                            <Button
                                size="sm"
                                variant="danger"
                                title="Delete"
                                onClick={() => {
                                    showDeleteModal(row.original.id, row.original.filename);
                                }}
                            >
                                <i className="fas fa-trash" />
                            </Button>
                            <Button
                                size="sm"
                                variant="outline-info"
                                title="Download"
                                onClick={() => downloadFileFromS3(row.original.filename, row.original.filename)}
                            >
                                <i className="fas fa-download" />
                            </Button>
                        </div>
                    );
                },
            },
        ],
        [downloadFileFromS3, showDeleteModal, showFileEditModal]
    );

    return isAdmin ? (
        <Container fluid className="pt-2">
            <Row>
                <Col>
                    <h1>File Manager</h1>
                </Col>
            </Row>

            <DataTable
                data={facilityFileData}
                columns={columns}
                paginated={facilityFileData.length > 20}
                filterColumns
            />

            <ConfirmationModal
                id="confirmDeleteFileModal"
                show={showConfirmDeleteModal}
                title="Delete File?"
                message={`Do you really want to delete this file: ${deleteFilename}?`}
                onCloseButtonClick={() => setShowConfirmDeleteModal(false)}
                onOkButtonClick={handleDeleteFile}
                theme={"danger"}
            />

            {fileEditFormProps.show && <FacilityFileEditForm {...fileEditFormProps} />}
        </Container>
    ) : (
        <Container fluid className="pt-2">
            <AccessDeniedMessage />
        </Container>
    );
}
