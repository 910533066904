import { createContext } from "react";
import { Filter } from "../../models/filter/Filter";

export interface FilterState {
    filter: Filter;
}

interface IFilterContext extends FilterState {
    setFilter: (filter: Filter) => void;
    /*setState: (state: number) => void;
    setParentTenant: (parentTenantId: number) => void;
    setTenant: (tenantId: number) => void;
    setProgram: (programId: number) => void;
    setYear: (year: number) => void;
    setRegion: (region: string) => void;
    setCounty: (county: string) => void;*/
}

export const FilterContext = createContext<IFilterContext | undefined>(undefined);
FilterContext.displayName = "Filter";
