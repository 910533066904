import { Col, Container, Row } from "react-bootstrap";

export function LegalDisclaimer() {
    return (
        <Container fluid className="pt-2">
            <Row>
                <Col>
                    <h1>Legal Disclaimer</h1>
                    <p>
                        The Client Portal including all pages within it (such as the main Portal dashboard, payment
                        database, file manager, and any other page) (the “Portal”) is prepared by Adelanto HealthCare
                        Ventures L.L.C. (the “Company”) for the benefit of its clients (the “Hospital”).
                    </p>
                    <p>
                        The Hospital is not to construe the contents of the Portal as legal, business, or tax advice.
                        The content provided in the Portal regarding the anticipated business operations and plans,
                        financial plans, expected financial results, and other such matters are solely for the purpose
                        of allowing the Hospital to prepare its internal accounting and financial records and the
                        Company gives no assurance that actual results and developments will conform with the
                        projections and predictions contained in the Portal or, even if substantially realized, that
                        they will have the expected consequences or effects. A number of factors and uncertainties could
                        cause actual results to differ materially from the information contained in the Portal,
                        including, without limitation, general economic conditions, business opportunities (or lack
                        thereof) that may be presented or pursued, changes in laws or regulations and other factors,
                        most of which are beyond the control of the Company. The Portal has been developed based on
                        publicly available information that was not prepared, or validated by the Company, and any
                        misstatements or errors in such base information would materially alter the determinations of
                        the Portal.
                    </p>
                    <p>
                        The Hospital is sophisticated as to the matters reflected in the Portal. While the information
                        in the Portal has been prepared in good faith, (A) (i) no representation or warranty, express or
                        implied, is or will be made, and (ii) the Hospital is not relying on, and waives any right to
                        bring claims (except to the extent arising out of common law fraud) against the Company, or any
                        of its officers, members, employees, advisors or agents, as to or in relation to the accuracy or
                        completeness of the Portal, and (b) the Company expressly disclaims any liability associated
                        therewith. Any liability is accordingly expressly disclaimed even if any loss or damage is
                        caused by any act or omission on part of the aforesaid, whether negligent or otherwise. There is
                        no guarantee that profitability will be achieved by the Company, or if achieved, that it will be
                        sustainable.
                    </p>
                    <p>
                        The information contained in the Portal speaks as to the date hereof, and neither the Company,
                        nor any officer, employee, or member of the Company, has any obligation and will not undertake
                        to update any such information.
                    </p>
                </Col>
            </Row>
        </Container>
    );
}
