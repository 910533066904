import { PropsWithChildren, useCallback, useEffect, useMemo, useState } from "react";
import { createPatch, Operation } from "rfc6902";
import { CreateTenant, CustomerMenuItem, EditTenant, HttpOptions, Operable, State, Tenant } from "../../models";
import { useContext, useStoredState } from "../../hooks";
import { TenantContext, TenantState, useAuth, useHttp } from "..";
import { replaceInCollection } from "../../helper-functions";

export const defaultTenantState: TenantState = {
    tenants: [],
    customerMenuItems: [],
    states: [],
    authorizedTenants: [],
};

export const TenantProvider = (props: PropsWithChildren) => {
    const { decodedAccessToken, isAuthenticated } = useAuth();
    const { tenant_id } = decodedAccessToken;
    const { get, post, patchUnique, del } = useHttp();
    const [httpOptions] = useState<HttpOptions>({ path: "tenants" });

    const [tenantState, setTenantState] = useStoredState("tenant", defaultTenantState);
    const { currentTenant, parentTenant, tenants } = tenantState;

    useEffect(() => {
        if (tenants.length && tenant_id) {
            const tenant = tenants.find((t) => t.id === +tenant_id);
            if (tenant?.id === currentTenant?.id) return;
            setTenantState((prev) => ({ ...prev, currentTenant: tenant }));
        }
    }, [tenants, tenant_id]);

    const getParentTenant = useCallback(
        async (tenantId: number) => {
            const parentTenant = await get<Tenant>({ path: `tenants/${tenantId}` });
            if (parentTenant) {
                setTenantState((prev) => ({ ...prev, parentTenant }));
            }
        },
        [get]
    );

    useEffect(() => {
        if (currentTenant?.parentTenantId && !parentTenant) {
            getParentTenant(currentTenant.parentTenantId);
        }
    }, [currentTenant, parentTenant, getParentTenant]);

    const getStates = useCallback(async () => {
        const states = await get<State[]>({ path: "states" });
        if (states) {
            setTenantState((prev) => ({ ...prev, states }));
        }
    }, [get, httpOptions]);

    useEffect(() => {
        getStates();
    }, []);

    const getTenants = useCallback(async () => {
        if (!isAuthenticated) return;
        const tenants = await get<Tenant[]>(httpOptions);
        if (tenants) {
            setTenantState((prev) => ({ ...prev, tenants }));
        }
    }, [get, httpOptions, isAuthenticated]);

    const getAuthorizedTenants = useCallback(async () => {
        if (!isAuthenticated) return;
        const authorizedTenants = await get<Tenant[]>({ path: `tenants/authorized-tenants/${tenant_id}` });
        if (authorizedTenants) {
            setTenantState((prev) => ({ ...prev, authorizedTenants: authorizedTenants }));
        }
    }, [get, isAuthenticated, setTenantState, tenant_id]);

    useEffect(() => {
        getTenants();
        getAuthorizedTenants();
    }, []);

    const getCustomerMenuItems = useCallback(
        async (tenantId: number) => {
            const customerMenuItems = await get<CustomerMenuItem[]>({ path: `customer-menu-items/tenant/${tenantId}` });
            if (customerMenuItems) {
                setTenantState((prev) => ({ ...prev, customerMenuItems }));
            }
        },
        [get, httpOptions, isAuthenticated]
    );

    const createCustomerMenuItem = useCallback(
        async (tenantId: number, customerMenuItem: CustomerMenuItem) => {
            const createdMenuItem = await post<CustomerMenuItem>({
                path: "customer-menu-items",
                body: {
                    ...customerMenuItem,
                    id: 0,
                    tenantId,
                },
            });
            if (createdMenuItem) {
                setTenantState((prev) => ({
                    ...prev,
                    customerMenuItems: [...prev.customerMenuItems, createdMenuItem],
                }));
            }
            return !!createdMenuItem;
        },
        [httpOptions, post]
    );

    const editCustomerMenuItem = useCallback(
        async ({ original, updated }: Operable<CustomerMenuItem>) => {
            const patchedItem = await patchUnique<Operation[], CustomerMenuItem>({
                path: `customer-menu-items/${original.id}`,
                body: createPatch(original, updated),
            });
            if (patchedItem) {
                setTenantState((prev) => ({
                    ...prev,
                    customerMenuItems: replaceInCollection([...prev.customerMenuItems], { original, updated }),
                }));
            }
            return !!patchedItem;
        },
        [httpOptions, post]
    );

    const deleteCustomerMenuItem = useCallback(
        async (customerMenuItem: CustomerMenuItem) => {
            const deleted = await del({ path: `customer-menu-items/${customerMenuItem.id}` });
            if (deleted) {
                setTenantState((prev) => ({
                    ...prev,
                    customerMenuItems: replaceInCollection([...prev.customerMenuItems], {
                        original: customerMenuItem,
                        updated: { ...customerMenuItem, isActive: false },
                    }),
                }));
            }
            return deleted;
        },
        [httpOptions, del]
    );

    const createTenant = useCallback(
        async ({ tenant, customerMenuItems }: CreateTenant) => {
            const createdTenant = await post<Tenant>({ ...httpOptions, body: tenant });
            if (createdTenant) {
                setTenantState((prev) => ({
                    ...prev,
                    tenants: [...prev.tenants, createdTenant],
                }));

                if (!!customerMenuItems && !!customerMenuItems.length) {
                    for (const menuItem of customerMenuItems) {
                        await createCustomerMenuItem(createdTenant.id, menuItem);
                    }
                }
            }
            return !!createdTenant;
        },
        [httpOptions, post]
    );

    const editTenant = useCallback(
        async ({
            operable: { original, updated },
            customerMenuItemsToCreate,
            customerMenuItemsToDelete,
            customerMenuItemsToEdit,
            originalCustomerMenuItems,
        }: EditTenant) => {
            const patchedTenant = await patchUnique<Operation[], Tenant>({
                path: `tenants/${original.id}`,
                body: createPatch(original, updated),
            });
            if (patchedTenant) {
                setTenantState((prev) => ({
                    ...prev,
                    tenants: replaceInCollection([...prev.tenants], { original, updated }),
                }));

                if (customerMenuItemsToCreate && customerMenuItemsToCreate.length) {
                    for (const menuItem of customerMenuItemsToCreate) {
                        await createCustomerMenuItem(patchedTenant.id, menuItem);
                    }
                }

                if (customerMenuItemsToEdit && customerMenuItemsToEdit.length) {
                    for (const menuItem of customerMenuItemsToEdit) {
                        const original = originalCustomerMenuItems?.find((item) => item.id === menuItem.id);
                        if (original) {
                            await editCustomerMenuItem({
                                original,
                                updated: menuItem,
                            });
                        }
                    }
                }

                if (customerMenuItemsToDelete && customerMenuItemsToDelete.length) {
                    for (const menuItem of customerMenuItemsToDelete) {
                        await deleteCustomerMenuItem(menuItem);
                    }
                }
            }
            return !!patchedTenant;
        },
        [patchUnique]
    );

    const deleteTenant = useCallback(
        async (tenant: Tenant) => {
            const deleted = await del({ path: `tenants/${tenant.id}` });
            if (deleted) {
                setTenantState((prev) => ({
                    ...prev,
                    tenants: replaceInCollection([...prev.tenants], {
                        original: tenant,
                        updated: { ...tenant, isActive: false },
                    }),
                }));
            }
            return deleted;
        },
        [del, tenants]
    );

    const restoreTenant = useCallback(
        async (original: Tenant): Promise<boolean> => {
            const updated = { ...original, isActive: true };
            const patchedTenant = await editTenant({
                operable: { original, updated },
            });
            return !!patchedTenant;
        },
        [editTenant]
    );

    const selectTenant = useCallback(
        (selectedTenant: Tenant) => setTenantState((prev) => ({ ...prev, selectedTenant })),
        []
    );

    const clearSelectedTenant = useCallback(
        () => setTenantState((prev) => ({ ...prev, selectedTenant: undefined })),
        []
    );

    const value = useMemo(
        () => ({
            ...tenantState,
            getTenants,
            getAuthorizedTenants,
            getParentTenant,
            getCustomerMenuItems,
            createTenant,
            editTenant,
            deleteTenant,
            restoreTenant,
            selectTenant,
            clearSelectedTenant,
        }),
        [
            tenantState,
            getTenants,
            getAuthorizedTenants,
            getParentTenant,
            getCustomerMenuItems,
            createTenant,
            editTenant,
            deleteTenant,
            restoreTenant,
            selectTenant,
            clearSelectedTenant,
        ]
    );

    return <TenantContext.Provider value={value} {...props} />;
};

export const useTenants = () => useContext(TenantContext);
