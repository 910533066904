import React from "react";
import {
    flexRender,
    getCoreRowModel,
    useReactTable,
    ColumnDef,
    getPaginationRowModel,
    getSortedRowModel,
    SortingState,
    ColumnFiltersState,
    FilterFn,
    getFilteredRowModel,
    getFacetedRowModel,
    getFacetedUniqueValues,
    getFacetedMinMaxValues,
    Row,
} from "@tanstack/react-table";
import { rankItem } from "@tanstack/match-sorter-utils";
import { Table as BootstrapTable, Form, InputGroup } from "react-bootstrap";
import _ from "lodash";
import { ColumnFilter, DataTablePagination } from ".";

export interface DataTableProps {
    columns: ColumnDef<any>[];
    data: any[];
    onRowClick?: Function;
    paginated?: boolean;
    showPageSize?: boolean;
    pageSize?: number;
    className?: string;
    showGlobalFilter?: boolean;
    filterColumns?: boolean;
    disableSort?: boolean;
    noStripes?: boolean;
    noHover?: boolean;
    globalSearchValue?: string;
    getRowClassName?: (row: Row<any>) => string;
}

export const DataTable = (props: DataTableProps) => {
    const {
        columns,
        data,
        onRowClick,
        paginated,
        showPageSize,
        pageSize,
        className,
        showGlobalFilter,
        filterColumns,
        disableSort,
        noStripes,
        noHover,
        globalSearchValue,
        getRowClassName,
    } = props;

    const [sorting, setSorting] = React.useState<SortingState>([]);
    const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>([]);
    const [globalFilter, setGlobalFilter] = React.useState("");

    const debounceGlobalSearch = _.debounce((value: string) => setGlobalFilter(value), 500);

    const fuzzyFilter: FilterFn<any> = (row, columnId, value, addMeta) => {
        const itemRank = rankItem(row.getValue(columnId), value);
        addMeta({
            itemRank,
        });
        return itemRank.passed;
    };

    const table = useReactTable({
        data,
        columns,
        filterFns: {
            fuzzy: fuzzyFilter,
        },
        state: {
            sorting,
            columnFilters,
            globalFilter: globalSearchValue || globalFilter,
        },
        globalFilterFn: fuzzyFilter,
        onSortingChange: setSorting,
        initialState: { pagination: { pageSize: pageSize || 20 } },
        getCoreRowModel: getCoreRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        getSortedRowModel: getSortedRowModel(),
        onColumnFiltersChange: setColumnFilters,
        onGlobalFilterChange: setGlobalFilter,
        getFilteredRowModel: getFilteredRowModel(),
        getFacetedRowModel: getFacetedRowModel(),
        getFacetedUniqueValues: getFacetedUniqueValues(),
        getFacetedMinMaxValues: getFacetedMinMaxValues(),
    });

    const { getHeaderGroups, getRowModel } = table;

    return (
        <>
            {showGlobalFilter && (
                <div className="d-flex justify-content-end">
                    <Form.Group className="mb-1">
                        <InputGroup>
                            <Form.Control
                                type="text"
                                onChange={(e) => debounceGlobalSearch(e.target.value)}
                                placeholder="Search all columns..."
                                style={{ maxWidth: "200px" }}
                            />
                            <InputGroup.Text className="bg-primary text-white">
                                <i className="fas fa-search" />
                            </InputGroup.Text>
                        </InputGroup>
                    </Form.Group>
                </div>
            )}
            {paginated && <DataTablePagination table={table} showPageSize={showPageSize} />}
            <BootstrapTable striped={!noStripes} hover={!noHover} className={className || ""}>
                <thead>
                    {getHeaderGroups().map((headerGroup, i) => (
                        <React.Fragment key={`headerGroup-${i}`}>
                            <tr>
                                {headerGroup.headers.map((header, j) => (
                                    <th key={`headerGroup-${i}-header-${j}`} colSpan={header.colSpan}>
                                        {header.isPlaceholder ? null : (
                                            <>
                                                <div
                                                    {...{
                                                        className:
                                                            !disableSort && header.column.getCanSort()
                                                                ? "clickable"
                                                                : "",
                                                        onClick: disableSort
                                                            ? () => {}
                                                            : header.column.getToggleSortingHandler(),
                                                    }}
                                                >
                                                    {flexRender(header.column.columnDef.header, header.getContext())}
                                                    {{
                                                        asc: <i className="fas fa-sort-up text-info ms-2" />,
                                                        desc: <i className="fas fa-sort-down text-info ms-2" />,
                                                    }[header.column.getIsSorted() as string] ?? null}
                                                </div>
                                                {filterColumns && header.column.getCanFilter() ? (
                                                    <div>
                                                        <ColumnFilter column={header.column} table={table} />
                                                    </div>
                                                ) : null}
                                            </>
                                        )}
                                    </th>
                                ))}
                            </tr>
                        </React.Fragment>
                    ))}
                </thead>
                <tbody>
                    {getRowModel().rows.map((row, i) => (
                        <tr
                            key={`row-${i}`}
                            onClick={() => onRowClick?.(row)}
                            className={getRowClassName ? getRowClassName(row) : ""}
                        >
                            {row.getVisibleCells().map((cell, j) => (
                                <td key={`row-${i}-cell-${j}`}>
                                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                </td>
                            ))}
                        </tr>
                    ))}
                </tbody>
            </BootstrapTable>
            {paginated && <DataTablePagination table={table} showPageSize={showPageSize} />}
        </>
    );
};
