/* eslint-disable react-hooks/exhaustive-deps */

import { useMemo } from "react";
import { Modal, Button } from "react-bootstrap";
import { DataTable } from "../../component-library";
import { ColumnDef } from "@tanstack/react-table";
import { usePayments, usePrograms, useTenants } from "../../contexts";
import { FormProps, Payment } from "../../models";
import _ from "lodash";

export const ViewFacilitiesTable = ({ onClose, show, type }: FormProps) => {
    const { tenants } = useTenants();
    const { programs } = usePrograms();
    const { payments } = usePayments();
    const facilityGrouped = _.groupBy(payments, (p) => p.tenantId);

    var facilityData = Object.entries(facilityGrouped).map(([key, value]) => ({
        facility: tenants.find((p) => p.id === value[0].tenantId)?.name,
        system: tenants.find((t) => t.id === tenants.find((p) => p.id === value[0].tenantId)?.parentTenantId)?.name,
        program: programs.find((p) => p.id === value[0].programId)?.name,
        revenue: `$${value
            .reduce((a, v) => (a = a + v.amount), 0)
            .toLocaleString(undefined, { maximumFractionDigits: 0 })}`,
    }));

    const columnDefs = useMemo(
        (): ColumnDef<Payment>[] => [
            {
                header: `Facility`,
                accessorKey: "facility",
            },
            {
                header: `System`,
                accessorKey: "system",
            },
            {
                header: "Program",
                accessorKey: "program",
            },
            {
                header: "Revenue",
                accessorKey: "revenue",
            },
        ],
        [payments]
    );
    return (
        <>
            <Modal show={show} backdrop="static" keyboard={false} size="lg">
                <Modal.Header>Facility Information</Modal.Header>
                <Modal.Body>
                    <DataTable
                        data={facilityData}
                        columns={columnDefs}
                        filterColumns
                        paginated={facilityData.length > 10}
                        pageSize={10}
                    />
                </Modal.Body>
                <Modal.Footer className="d-flex justify-content-between">
                    <Button variant="primary" onClick={onClose} className="ms-2">
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};
