/* eslint-disable react-hooks/exhaustive-deps */

import { useMemo } from "react";
import { Modal, Button } from "react-bootstrap";
import { DataTable } from "../../component-library";
import { ColumnDef } from "@tanstack/react-table";
import { usePayments } from "../../contexts";
import { FormProps, Payment } from "../../models";
import _ from "lodash";
import { formatCurrencyWithoutDecimal } from "../../helper-functions/formatCurrencyWithoutDecimal";

export const ViewHospitalTypeTable = ({ onClose, show, type }: FormProps) => {
    const { payments } = usePayments();
    const hospitalTypeGrouped = _.groupBy(payments, (p) => p.hospitalType);

    var hospitalTypeData = Object.entries(hospitalTypeGrouped).map(([key, value]) => ({
        hospitalType: value[0].hospitalType,
        amount: value.reduce((a, v) => (a = a + v.amount), 0),
    }));

    const columnDefs = useMemo(
        (): ColumnDef<Payment>[] => [
            {
                header: `Hospital Type`,
                accessorKey: "hospitalType",
            },
            {
                header: () => {
                    return <div className="currency-align">Revenue</div>;
                },
                accessorKey: "amount",
                cell: ({ row }) => {
                    return <div className="currency-align">{formatCurrencyWithoutDecimal(row.original.amount)}</div>;
                },
            },
        ],
        [payments]
    );

    return (
        <>
            <Modal show={show} backdrop="static" keyboard={false} size="lg">
                <Modal.Header>Hospital Type Information</Modal.Header>
                <Modal.Body>
                    <DataTable
                        data={hospitalTypeData}
                        columns={columnDefs}
                        filterColumns
                        paginated={hospitalTypeData.length > 10}
                        pageSize={10}
                    />
                </Modal.Body>
                <Modal.Footer className="d-flex justify-content-between">
                    <Button variant="primary" onClick={onClose} className="ms-2">
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};
