import axios from "axios";
import _ from "lodash";
import { useState, useMemo, useEffect } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { useTenants, usePrograms, usePayments, useAuth, useRegionCounties } from "../../contexts";
import { useStoredState } from "../../hooks";
import { Filter } from "../../models/filter/Filter";
import { API_BASE_URL } from "../../config/config";
import { RegionCounty } from "../../models";

export interface FacilityFilters {
    stateId: number;
    systemId: number;
    facilityId: number;
    programId: number;
    year: number;
    county: string;
    region: string;
}

export const initialFilterState: FacilityFilters = {
    stateId: 0,
    systemId: 0,
    facilityId: 0,
    programId: 0,
    year: 0,
    county: "",
    region: "",
};

export const FacilityFilterForm = () => {
    const { tenants, states } = useTenants();
    const { getPayments, payments } = usePayments();
    const { programs } = usePrograms();
    const { accessToken } = useAuth();
    const { getRegionCounties, regionCounties } = useRegionCounties();

    const [filterState, setFilterState] = useStoredState("filter", initialFilterState);
    const [yearOptions, setYearOptions] = useState<number[]>([]);

    const [errors, setErrors] = useState({
        ahcvIdentifier: "",
        name: "",
        stateId: "",
        systemId: "",
        facilityId: "",
        programId: "",
    });

    useEffect(() => {
        const updatedYearOptions = _.groupBy(payments, (payment) => payment.year);
        const fetchedYears = Object.keys(updatedYearOptions).map(Number);
        setYearOptions((prev) => Array.from(new Set([...prev, ...fetchedYears])).sort((a, b) => a - b));
    }, [filterState, payments]);

    const systems = useMemo(
        () => [...tenants].sort((a, b) => a.name.localeCompare(b.name)).filter((t) => !t.parentTenantId),
        [tenants]
    );

    const facilities = useMemo(
        () =>
            [...tenants]
                .sort((a, b) => a.name.localeCompare(b.name))
                .filter((t) =>
                    filterState.stateId > 0
                        ? t.parentTenantId === filterState.systemId && t.stateId === filterState.stateId
                        : t.parentTenantId === filterState.systemId
                ),
        [tenants, filterState.systemId, filterState.stateId]
    );

    const programData = Object.entries(programs.sort((a, b) => a.name.localeCompare(b.name))).map(([key, value]) => ({
        value: value.id,
        name: value.name,
    }));

    const [isDisabled, setIsDisabled] = useState<boolean>(true);

    useEffect(() => {
        let filter: Filter = {
            stateId: filterState.stateId,
            parentTenantId: filterState.systemId,
            tenantId: filterState.facilityId,
            programId: filterState.programId,
            year: filterState.year,
            county: filterState.county,
            region: filterState.region,
            paymentDbAccess: true,
        };
        getPayments(filter);
    }, [filterState]);

    //Only need to update when stateId changes
    useEffect(() => {
        if (filterState.stateId) getRegionCounties(filterState.stateId);
    }, [filterState.stateId]);

    const countyData = _.groupBy(
        regionCounties.filter((rc) => rc.county),
        (rc) => rc.county
    );
    const counties = Object.entries(countyData).map(([key, value]) => ({
        name: value[0].county,
        value: value[0].county,
    }));

    const regionData = _.groupBy(
        regionCounties.filter((rc) => rc.region),
        (rc) => rc.region
    );
    const regions = Object.entries(regionData).map(([key, value]) => ({
        name: value[0].region,
        value: value[0].region,
    }));

    const download = function () {
        let filter: Filter = {
            stateId: filterState.stateId,
            parentTenantId: filterState.systemId,
            tenantId: filterState.facilityId,
            programId: filterState.programId,
            year: filterState.year,
            county: filterState.county,
            region: filterState.region,
            paymentDbAccess: true,
        };
        axios({
            method: "post",
            url: `${API_BASE_URL}actions/download`,
            data: filter,
            responseType: "blob",
            headers: {
                Authorization: `Bearer ${accessToken}`,
            },
        }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");

            link.href = url;
            link.setAttribute("download", `AhcvPayments_${Date.now()}.xlsx`);
            document.body.appendChild(link);
            link.click();
        });
    };

    return (
        <>
            <Row>
                <Col sm={12}>
                    <Form className="keysys-form" id="SelectForm">
                        <Form.Label>State</Form.Label>
                        <Form.Select
                            value={filterState.stateId}
                            onChange={(e) => {
                                if (+e.target.value === 0)
                                    setFilterState((prev) => ({
                                        ...prev,
                                        stateId: +e.target.value,
                                        programId: 0,
                                        year: 0,
                                        county: "",
                                        region: "",
                                    }));
                                else setFilterState((prev) => ({ ...prev, stateId: +e.target.value }));
                                localStorage.setItem("FilterStateId", e.target.value);
                            }}
                        >
                            <option value={0}>All</option>
                            {states.map((state) => (
                                <option key={state.abbreviation} value={state.id}>
                                    {state.name}
                                </option>
                            ))}
                        </Form.Select>
                        <Form.Control.Feedback type="invalid">{errors.stateId}</Form.Control.Feedback>

                        <Form.Label>System</Form.Label>
                        <Form.Select
                            value={filterState.systemId}
                            onChange={(e) => {
                                if (+e.target.value === 0)
                                    setFilterState((prev) => ({
                                        ...prev,
                                        facilityId: 0,
                                        programId: 0,
                                        year: 0,
                                        systemId: +e.target.value,
                                    }));
                                else setFilterState((prev) => ({ ...prev, systemId: +e.target.value }));
                            }}
                        >
                            <option value={0}>All</option>
                            {systems.map((tenant) => (
                                <option key={tenant.id} value={tenant.id}>
                                    {tenant.name}
                                </option>
                            ))}
                        </Form.Select>

                        <Form.Label>Facility</Form.Label>
                        <Form.Select
                            value={filterState.facilityId}
                            onChange={(e) => {
                                setFilterState((prev) => ({ ...prev, facilityId: +e.target.value }));
                            }}
                        >
                            <option value={0}>All</option>
                            {facilities.map((tenant) => (
                                <option key={tenant.id} value={tenant.id}>
                                    {tenant.name}
                                </option>
                            ))}
                        </Form.Select>

                        <Form.Label>Program</Form.Label>
                        <Form.Select
                            value={filterState.programId}
                            onChange={(e) => {
                                setFilterState((prev) => ({ ...prev, programId: +e.target.value }));
                            }}
                        >
                            <option value={0}>All</option>
                            {programData.map((program) => (
                                <option key={program.value} value={program.value}>
                                    {program.name}
                                </option>
                            ))}
                        </Form.Select>

                        <Form.Label>Year</Form.Label>
                        <Form.Select
                            value={filterState.year}
                            onChange={(e) => {
                                setFilterState((prev) => ({ ...prev, year: +e.target.value }));
                            }}
                        >
                            <option value={0}>All</option>
                            {yearOptions.map((year) => (
                                <option key={year} value={year}>
                                    {year}
                                </option>
                            ))}
                        </Form.Select>

                        <Form.Label>County</Form.Label>
                        <Form.Select
                            disabled={isDisabled ? filterState.stateId === 0 : false}
                            value={filterState.county}
                            onChange={(e) => {
                                setFilterState((prev) => ({ ...prev, county: e.target.value }));
                            }}
                        >
                            <option value={""}>All</option>
                            {counties
                                .sort((a, b) => a.name.localeCompare(b.name))
                                .map((county) => (
                                    <option key={county.value} value={county.value}>
                                        {county.name}
                                    </option>
                                ))}
                        </Form.Select>

                        <Form.Label>Region</Form.Label>
                        <Form.Select
                            disabled={isDisabled ? filterState.stateId === 0 : false}
                            value={filterState.region}
                            onChange={(e) => {
                                setFilterState((prev) => ({ ...prev, region: e.target.value }));
                            }}
                        >
                            <option value={""}>All</option>
                            {regions
                                .sort((a, b) => a.name.localeCompare(b.name))
                                .map((region) => (
                                    <option key={region.value} value={region.value}>
                                        {region.name}
                                    </option>
                                ))}
                        </Form.Select>
                        <br />
                        <span>
                            <a href="#" onClick={() => download()}>
                                Download Results
                            </a>
                        </span>
                    </Form>
                </Col>
            </Row>
        </>
    );
};
