import { createContext } from "react";
import { Photo, AwsFile, FileUrl, CreatePhotoRequest } from "../../models";

export interface PhotosState {
    photos: Photo[];
    awsFiles: AwsFile[];
    blobUrls: FileUrl[];
}

interface IPhotosContext extends PhotosState {
    createPhoto: (createPhotoRequest: CreatePhotoRequest) => Promise<boolean>;
    uploadImage: (awsFile: AwsFile) => Promise<void>;
    clearImages: () => void;
    getUrl: (fileName: string) => Promise<string | void>;
}

export const PhotosContext = createContext<IPhotosContext | undefined>(undefined);
PhotosContext.displayName = "Photos";