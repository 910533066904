import { useEffect } from "react";
import { useTenants, useAuth } from "../../contexts";
import { useKeyDates } from "../../contexts/key-date";
import { getDateWithMonthName } from "../../helper-functions/getDateWithMonthName";
import { useKeyDateSystemTypes } from "../../contexts/key-date-system-type";

interface IKeyDates {
    facilityIdState: number;
    stateIds: (number | undefined)[];
}

export function KeyDates({ facilityIdState, stateIds }: IKeyDates) {
    const { decodedAccessToken } = useAuth();
    const isGlobalAdmin = decodedAccessToken.roles === "GlobalAdmin";
    const { tenants, states } = useTenants();
    const { keyDates, getAllUpcomingKeyDates } = useKeyDates();
    const { keyDateSystemTypes } = useKeyDateSystemTypes();

    useEffect(() => {
        getAllUpcomingKeyDates();
    }, []);

    //Facility State
    const facility = tenants.find((t) => t.id === facilityIdState);
    const facilityState = states.find((s) => s.id === facility?.stateId);

    const parentTenantId = tenants.find((t) => t.id === facilityIdState)?.parentTenantId;
    const systemId = parentTenantId ? parentTenantId : facilityIdState;

    const childrenStates = states.filter((s) => stateIds.includes(s.id))?.map((s) => s.abbreviation);

    const allFloridaKeyDates = keyDates.filter(
        (kd) => kd.keyDateSystemTypeId === keyDateSystemTypes.find((kds) => kds.name === "All Florida")?.id
    );

    const allTexasKeyDates = keyDates.filter(
        (kd) => kd.keyDateSystemTypeId === keyDateSystemTypes.find((kds) => kds.name === "All Texas")?.id
    );

    return (
        <div className="chart-border" style={{ flexGrow: 1 }}>
            <h2 className="modal-header">Upcoming Key Dates</h2>
            <div className="panel-body">
                {keyDates.filter(
                    (kd) => kd.keyDateSystemTypeId === keyDateSystemTypes.find((kds) => kds.name === "All")?.id
                ).length > 0 && (
                    <>
                        <div>AHCV</div>
                        {keyDates
                            .filter(
                                (kd) =>
                                    kd.keyDateSystemTypeId === keyDateSystemTypes.find((kds) => kds.name === "All")?.id
                            )
                            .map((kd) => (
                                <ul key={`ul-kd-${kd.id}`} className="no-bullet-list">
                                    <li key={`li-kd-${kd.id}`} className="padding">
                                        {getDateWithMonthName(kd.eventDate)} - {kd.title}
                                    </li>
                                </ul>
                            ))}
                    </>
                )}

                {keyDates
                    .filter(
                        (kd) =>
                            kd.keyDateSystemTypeId === keyDateSystemTypes.find((kds) => kds.name === "Custom")?.id &&
                            kd.keyDateSystems.filter((kds) => kds.systemId === systemId)
                    )
                    .map((kd) => (
                        <ul key={`ul-kd-${kd.id}`} className="no-bullet-list">
                            <li key={`li-kd-${kd.id}`} className="padding">
                                {getDateWithMonthName(kd.eventDate)} - {kd.title}
                            </li>
                        </ul>
                    ))}

                {((isGlobalAdmin && facilityIdState === 0) ||
                    facilityState?.abbreviation === "FL" ||
                    childrenStates.includes("FL")) &&
                    allFloridaKeyDates.length > 0 && (
                        <>
                            <div>Florida</div>
                            {allFloridaKeyDates.map((kd) => (
                                <ul key={`ul-kd-${kd.id}`} className="no-bullet-list">
                                    <li key={`li-kd-${kd.id}`} className="padding">
                                        {getDateWithMonthName(kd.eventDate)} - {kd.title}
                                    </li>
                                </ul>
                            ))}
                        </>
                    )}

                {((isGlobalAdmin && facilityIdState === 0) ||
                    facilityState?.abbreviation === "TX" ||
                    childrenStates.includes("TX")) &&
                    allTexasKeyDates.length > 0 && (
                        <>
                            <div>Texas</div>
                            {allTexasKeyDates.map((kd) => (
                                <ul key={`ul-kd-${kd.id}`} className="no-bullet-list">
                                    <li key={`li-kd-${kd.id}`} className="padding">
                                        {getDateWithMonthName(kd.eventDate)} - {kd.title}
                                    </li>
                                </ul>
                            ))}
                        </>
                    )}
            </div>
        </div>
    );
}
