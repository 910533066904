import { createContext } from "react";
import { Program } from "../../models";

export interface ProgramState {
    programs: Program[];
}

interface IProgramContext extends ProgramState {
    getPrograms: () => Promise<void>;
}

export const ProgramContext = createContext<IProgramContext | undefined>(undefined);
ProgramContext.displayName = "Program";
