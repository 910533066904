import _ from "lodash";
import { useCallback } from "react";
import { Link } from "react-router-dom";
import { useTenants } from "../../contexts";
import { useFacilityFiles } from "../../contexts/facility-file";
import { useFileTypes } from "../../contexts/filetype";

interface IAhcvFiles {
    facilityIdState: number;
}

export function AhcvFiles({ facilityIdState }: IAhcvFiles) {
    const { tenants, states } = useTenants();
    const { fileTypes } = useFileTypes();
    const { facilityFiles, downloadFacilityFileFromS3 } = useFacilityFiles();
    const parentTenantId = tenants.find((t) => t.id === facilityIdState)?.parentTenantId;

    // LPPF Files
    const lppfFiles = facilityFiles.filter(
        (sf) => sf.fileTypeId === fileTypes.find((ft) => ft.prefix === "LPPF")?.id && sf.linkName
    );
    const lppfGrouped = _.groupBy(lppfFiles, (f) => f.stateId);
    const lppfGroupedData = Object.entries(lppfGrouped).map(([key, value]) => ({
        name: states.find((s) => s.id === value[0].stateId)?.name,
        stateId: value[0].stateId,
    }));
    const lppfData = Object.entries(lppfFiles).map(([key, value]) => ({
        name: tenants.find((t) => t.id === value.systemId)?.name,
        filename: value.filename,
        awsFilename: value.awsFilename,
        stateId: value.stateId,
        id: value.id,
        linkName: value.linkName,
    }));

    // Budget Analysis Files
    const budgetAnalysisFiles = parentTenantId
        ? facilityFiles.filter(
              (sf) =>
                  sf.stateId === 3 && sf.fileTypeId === fileTypes.find((ft) => ft.prefix === "BA")?.id && sf.linkName
          )
        : facilityFiles.filter((sf) => sf.fileTypeId === fileTypes.find((ft) => ft.prefix === "BA")?.id && sf.linkName);
    const budgetAnalysisGrouped = _.groupBy(budgetAnalysisFiles, (f) => f.stateId);
    const budgetAnalysisGroupedData = Object.entries(budgetAnalysisGrouped).map(([key, value]) => ({
        name: states.find((s) => s.id === value[0].stateId)?.name,
        stateId: value[0].stateId,
    }));

    const budgetAnalysisData = Object.entries(budgetAnalysisFiles).map(([key, value]) => ({
        name: tenants.find((t) => t.id === value.systemId)?.name,
        filename: value.filename,
        awsFilename: value.awsFilename,
        stateId: value.stateId,
        id: value.id,
        linkName: value.linkName,
    }));

    // Other Files
    const otherFiles = facilityFiles.filter(
        (sf) => sf.fileTypeId === fileTypes.find((ft) => ft.prefix === "OTHER")?.id && sf.linkName
    );
    const otherGrouped = _.groupBy(otherFiles, (f) => f.stateId);
    const otherGroupedData = Object.entries(otherGrouped).map(([key, value]) => ({
        name: states.find((s) => s.id === value[0].stateId)?.name,
        stateId: value[0].stateId,
    }));
    const otherData = Object.entries(otherFiles).map(([key, value]) => ({
        name: states.find((s) => s.id === value.stateId)?.name,
        filename: value.filename,
        awsFilename: value.awsFilename,
        stateId: value.stateId,
        id: value.id,
        linkName: value.linkName,
    }));

    // Monthly Cashflow Files
    const monthlyCashflowFiles = facilityFiles.filter(
        (sf) => sf.fileTypeId === fileTypes.find((ft) => ft.prefix === "MCS")?.id && sf.linkName
    );
    const monthlyCashflowGrouped = _.groupBy(monthlyCashflowFiles, (f) => f.stateId);
    const monthlyCashflowGroupedData = Object.entries(monthlyCashflowGrouped).map(([key, value]) => ({
        name: states.find((s) => s.id === value[0].stateId)?.name,
        stateId: value[0].stateId,
    }));
    const monthlyCashflowData = Object.entries(monthlyCashflowFiles).map(([key, value]) => ({
        name: tenants.find((s) => s.id === value.systemId)?.name,
        filename: value.filename,
        awsFilename: value.awsFilename,
        stateId: value.stateId,
        id: value.id,
        linkName: value.linkName,
    }));

    const downloadFileFromS3 = useCallback(
        (awsFileName: string, filename: string) => {
            downloadFacilityFileFromS3(awsFileName, filename);
        },
        [downloadFacilityFileFromS3]
    );

    return (
        <div className="chart-border" style={{ flexGrow: 1 }}>
            <h2 className="modal-header">AHCV Files</h2>
            <div className="panel-body">
                {facilityIdState > 0 && (
                    <>
                        {budgetAnalysisFiles.length > 0 && (
                            <>
                                <h2>Budget Analysis</h2>
                                {budgetAnalysisGroupedData.map((f) => (
                                    <ul key={`ul-ba-${f.stateId}`} className="no-bullet-list">
                                        {f.name}
                                        {budgetAnalysisData
                                            .filter((bad) => bad.stateId === f.stateId)
                                            .map((bd) => (
                                                <li key={`li-bd-${bd.id}-${bd.stateId}`} className="padding">
                                                    <Link
                                                        to=""
                                                        onClick={() => downloadFileFromS3(bd.filename, bd.filename)}
                                                    >
                                                        {bd.linkName}
                                                    </Link>
                                                </li>
                                            ))}
                                    </ul>
                                ))}
                            </>
                        )}

                        {monthlyCashflowFiles && monthlyCashflowFiles.length > 0 && (
                            <>
                                <h2>Monthly Cashflow Summary</h2>
                                {monthlyCashflowGroupedData.map((f) => (
                                    <ul key={`ul-mcs-${f.stateId}`} className="no-bullet-list">
                                        {f.name}
                                        {monthlyCashflowData
                                            .filter((other) => other.stateId === f.stateId)
                                            .map((mcs) => (
                                                <li key={`li-mcs-${f.stateId}-${f.name}`} className="padding">
                                                    <Link
                                                        to=""
                                                        onClick={() => downloadFileFromS3(mcs.filename, mcs.filename)}
                                                    >
                                                        {mcs.linkName}
                                                    </Link>
                                                </li>
                                            ))}
                                    </ul>
                                ))}
                            </>
                        )}

                        {lppfFiles.length > 0 && (
                            <>
                                <h2>LPPF Workbooks</h2>
                                {lppfGroupedData
                                    .sort((a, b) => a.name!.localeCompare(b.name!))
                                    .map((f) => (
                                        <ul key={`ul-lppf-${f.stateId}`} className="no-bullet-list">
                                            {f.name}
                                            {lppfData
                                                .sort((a, b) => a.linkName.localeCompare(b.linkName))
                                                .filter((lppf) => lppf.stateId === f.stateId)
                                                .map((ld) => (
                                                    <li key={`li-lppf-${ld.id}`} className="padding">
                                                        <Link
                                                            to=""
                                                            onClick={() => downloadFileFromS3(ld.filename, ld.filename)}
                                                        >
                                                            {ld.linkName}
                                                        </Link>
                                                    </li>
                                                ))}
                                        </ul>
                                    ))}
                            </>
                        )}

                        {otherFiles.length > 0 && (
                            <>
                                <h2>Other</h2>
                                {otherGroupedData.map((f) => (
                                    <ul key={`ul-other-${f.stateId}`} className="no-bullet-list">
                                        {f.name}
                                        {otherData
                                            .filter((other) => other.stateId === f.stateId)
                                            .map((od) => (
                                                <li key={`li-other-${f.stateId}-${f.name}`} className="padding">
                                                    <Link
                                                        to=""
                                                        onClick={() => downloadFileFromS3(od.filename, od.filename)}
                                                    >
                                                        {od.linkName}
                                                    </Link>
                                                </li>
                                            ))}
                                    </ul>
                                ))}
                            </>
                        )}
                    </>
                )}
            </div>
        </div>
    );
}
