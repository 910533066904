import { ColumnDef } from "@tanstack/react-table";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { BooleanIcon, DataTable } from "../../component-library";
import { useAppSettings, useAuth, useTenants } from "../../contexts";
import { FormProps, Tenant } from "../../models";
import { CustomerForm } from "./CustomerForm";
import { PartnerForm } from "./PartnerForm";
import { ImmutableRole } from "../../models/role/ImmutableRole";

export const Tenants = () => {
    const {
        decodedAccessToken: { roles },
    } = useAuth();
    const { partnerName, customerName } = useAppSettings();
    const { clearSelectedTenant, getTenants, selectTenant, tenants } = useTenants();
    const navigate = useNavigate();

    const [partnerFormProps, setPartnerFormProps] = useState<FormProps>({
        show: false,
        type: "add",
        onClose: () => {},
    });
    const [customerFormProps, setCustomerFormProps] = useState<FormProps>({
        show: false,
        type: "add",
        onClose: () => {},
    });

    useEffect(() => {
        if (roles !== ImmutableRole.GlobalAdmin && roles !== ImmutableRole.PartnerAdmin) {
            navigate("/");
        }
        if (tenants.length === 0) {
            getTenants();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const hidePartnerForm = useCallback(() => setPartnerFormProps((prev) => ({ ...prev, show: false })), []);
    const hideCustomerForm = useCallback(() => setCustomerFormProps((prev) => ({ ...prev, show: false })), []);

    const showPartnerForm = useCallback(() => {
        clearSelectedTenant();
        setPartnerFormProps({
            onClose: hidePartnerForm,
            show: true,
            type: "add",
        });
    }, [clearSelectedTenant, hidePartnerForm]);
    const showCustomerForm = useCallback(() => {
        clearSelectedTenant();
        setCustomerFormProps({
            onClose: hideCustomerForm,
            show: true,
            type: "add",
        });
    }, [clearSelectedTenant, hideCustomerForm]);

    const handleShowEditForm = useCallback(
        (tenantId: number) => {
            const tenant = tenants.find((t) => t.id === tenantId);
            if (!tenant) return;
            selectTenant(tenant);
            if (tenant.parentTenantId === undefined || tenant.parentTenantId === null) {
                setPartnerFormProps({
                    onClose: hidePartnerForm,
                    show: true,
                    type: "edit",
                });
            } else if (tenant.parentTenantId === 1) {
                setPartnerFormProps({
                    onClose: hidePartnerForm,
                    show: true,
                    type: "edit",
                });
            } else {
                setCustomerFormProps({
                    onClose: hideCustomerForm,
                    show: true,
                    type: "edit",
                });
            }
        },
        [tenants, selectTenant, hidePartnerForm, hideCustomerForm]
    );

    const columns = useMemo(
        (): ColumnDef<Tenant>[] => [
            {
                header: `${partnerName}/${customerName}`,
                accessorKey: "name",
            },
            {
                header: "# of Users",
                accessorKey: "usersCount",
            },
            {
                header: "AHCV Id",
                accessorKey: "id",
                cell: ({ row }) => {
                    return row.original.parentTenantId ? "" : row.original.id;
                },
            },
            {
                header: "Active?",
                accessorKey: "isActive",
                cell: ({ getValue }) => (
                    <div className="text-center">
                        <BooleanIcon value={getValue() as boolean} />
                    </div>
                ),
            },
            {
                header: "",
                id: "actions",
                cell: ({ row }) => {
                    return (
                        <div className="d-flex justify-content-end">
                            <Button
                                size="sm"
                                variant="outline-info"
                                title="Edit"
                                onClick={() => handleShowEditForm(row.original.id)}
                                hidden={roles !== ImmutableRole.GlobalAdmin}
                            >
                                <i className="fas fa-edit" />
                            </Button>
                        </div>
                    );
                },
            },
        ],
        [partnerName, customerName, roles, handleShowEditForm]
    );

    return (
        <Container fluid className="pt-2">
            <Row>
                <Col>
                    <h1>Tenants</h1>
                </Col>
            </Row>
            <Row>
                <Col sm={2}>
                    {roles === ImmutableRole.GlobalAdmin && (
                        <Button
                            className={"add-partner-button finosec-button-info"}
                            onClick={() => showPartnerForm()}
                            data-testid={"add-partner-button"}
                        >
                            Add {partnerName}
                        </Button>
                    )}
                </Col>
                <Col sm={2}>
                    <Button
                        className={"add-customer-button finosec-button-info"}
                        onClick={() => showCustomerForm()}
                        data-testid={"add-customer-button"}
                    >
                        Add {customerName}
                    </Button>
                </Col>
            </Row>

            <DataTable
                data={tenants}
                columns={columns}
                paginated={tenants.length > 20}
                showGlobalFilter
                filterColumns
            />

            {partnerFormProps.show && <PartnerForm {...partnerFormProps} />}
            {customerFormProps.show && <CustomerForm {...customerFormProps} />}
        </Container>
    );
};
