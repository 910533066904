export const ChartTooltip = ({ active, payload, label }: any) => {
    if (active && payload && payload.length) {
        return (
            <div className="custom-tooltip">
                <p className="label" style={{ paddingTop: "15px", paddingLeft: "15px", paddingRight: "15px" }}>
                    {`${payload[0].name} : $${payload[0].value.toLocaleString()}`}
                </p>
            </div>
        );
    }
    return null;
};
