/* eslint-disable react-hooks/exhaustive-deps */

import { useCallback, useMemo, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { DataTable } from "../../component-library";
import { ColumnDef } from "@tanstack/react-table";
import { useEmailArchives } from "../../contexts";
import { FormProps } from "../../models";
import { EmailArchive } from "../../models/emailArchive/EmailArchive";
import { EmailArchiveFormProps } from "../../models/emailArchive/EmailArchiveFormProps";
import { ViewEmailArchive } from "./ViewEmailArchive";
import _ from "lodash";
import { getDateWithMonthName } from "../../helper-functions/getDateWithMonthName";

export const ViewEmailArchiveTable = ({ onClose, show, type }: FormProps) => {
    const { emailArchives } = useEmailArchives();

    //Single Email Archive
    const [viewEmailArchiveProps, setViewEmailArchiveProps] = useState<EmailArchiveFormProps>({
        show: false,
        id: 0,
        onClose: () => {},
    });

    const hideEmailArchive = useCallback(() => setViewEmailArchiveProps((prev) => ({ ...prev, show: false })), []);

    const showEmailArchive = useCallback(
        (id: number) => {
            setViewEmailArchiveProps({
                onClose: hideEmailArchive,
                show: true,
                id: id,
            });
        },
        [hideEmailArchive]
    );

    const emailArchivesSorted = _.orderBy(emailArchives, (ea) => ea.receivedDate, "desc");
    const emailArchiveData = Object.entries(emailArchivesSorted).map(([key, value]) => ({
        receivedDate: getDateWithMonthName(value.receivedDate),
        id: value.id,
        body: value.body,
        subject: value.subject,
    }));

    const columnDefs = useMemo(
        (): ColumnDef<EmailArchive>[] => [
            {
                header: "Received Date",
                accessorKey: "receivedDate",
            },
            {
                header: "",
                id: "actions",
                cell: ({ row }) => {
                    return (
                        <a href="#" onClick={() => showEmailArchive(row.original.id)}>
                            <i className="fas fa-eye" />
                        </a>
                    );
                },
            },
        ],
        [emailArchivesSorted]
    );
    return (
        <>
            <Modal show={show} backdrop="static" keyboard={false} size="lg">
                <Modal.Header>AHCV Nightly Breifing</Modal.Header>
                <Modal.Body>
                    <DataTable
                        data={emailArchiveData}
                        columns={columnDefs}
                        filterColumns
                        paginated={emailArchivesSorted.length > 10}
                        pageSize={10}
                    />
                </Modal.Body>
                <Modal.Footer className="d-flex justify-content-between">
                    <Button variant="primary" onClick={onClose} className="ms-2">
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
            {viewEmailArchiveProps.show && <ViewEmailArchive {...viewEmailArchiveProps} />}
        </>
    );
};
