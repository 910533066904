import { useCallback, useEffect, useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { useKeyDates } from "../../contexts/key-date";
import { useKeyDateSystemTypes } from "../../contexts/key-date-system-type";
import { FormProps, Tenant } from "../../models";
import DatePicker from "react-datepicker";
import { getSafeDateValue } from "../../helper-functions/getSafeDateValue";
import { useTenants } from "../../contexts";
import Multiselect from "multiselect-react-dropdown";
import { KeyDateSystem } from "../../models/key-date-system/KeyDateSystem";
import { useKeyDateSystems } from "../../contexts/key-date-system";

interface KeyDateFromModel {
    id: number;
    title: string;
    description: string;
    keyDateSystemTypeId: number;
    eventDate: string;
    eventDisplayStartDate: string;
    eventDisplayEndDate: string;
}
export const KeyDateForm = ({ onClose, show, type }: FormProps) => {
    const { createKeyDate, editKeyDate, selectedKeyDate, getAllKeyDates } = useKeyDates();
    const { upsertKeyDateSystems } = useKeyDateSystems();
    const { keyDateSystemTypes } = useKeyDateSystemTypes();
    const { tenants } = useTenants();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [eventDate, setEventDate] = useState<Date>();
    const [displayStartDate, setDisplayStartDate] = useState<Date>();
    const [displayEndDate, setDisplayEndDate] = useState<Date>();

    const [formValues, setFormValues] = useState<KeyDateFromModel>({
        id: selectedKeyDate?.id ? selectedKeyDate.id : 0,
        title: "",
        description: "",
        keyDateSystemTypeId: 0,
        eventDate: "",
        eventDisplayStartDate: "",
        eventDisplayEndDate: "",
    });
    const [selectedSystems, setSelectedSystems] = useState<Tenant[]>([]);
    const systems = tenants.filter((t) => !t.parentTenantId);
    const preSelectedSystemsIds = selectedKeyDate?.keyDateSystems.map((s) => s.systemId);
    const preSelectedSystems: Tenant[] = systems.filter((ss) => preSelectedSystemsIds?.includes(ss.id));

    useEffect(() => {
        if (!selectedKeyDate) return;
        setFormValues({ ...selectedKeyDate });
    }, [selectedKeyDate]);

    useEffect(() => {
        setSelectedSystems({ ...selectedSystems });
    }, []);

    const [errors, setErrors] = useState({
        title: "",
        description: "",
        keyDateSystemTypeId: "",
        eventDate: "",
        eventDisplayStartDate: "",
        eventDisplayEndDate: "",
        systems: "",
    });

    const isFormValid = useCallback(() => {
        let valid = true;
        const { title, description, keyDateSystemTypeId, eventDate, eventDisplayStartDate, eventDisplayEndDate } =
            formValues;
        if (!title) {
            setErrors((prev) => ({ ...prev, title: "Title is required" }));
            valid = false;
        }
        if (!description) {
            setErrors((prev) => ({ ...prev, description: "Description is required" }));
            valid = false;
        }
        if (!keyDateSystemTypeId || keyDateSystemTypeId === 0) {
            setErrors((prev) => ({ ...prev, keyDateSystemTypeId: "Key date type is required" }));
            valid = false;
        }
        if (!eventDate) {
            setErrors((prev) => ({ ...prev, eventDate: "Event date is required" }));
            valid = false;
        }
        if (!eventDisplayStartDate) {
            setErrors((prev) => ({ ...prev, eventDisplayStartDate: "Event display start date is required" }));
            valid = false;
        }
        if (!eventDisplayEndDate) {
            setErrors((prev) => ({ ...prev, eventDisplayEndDate: "Event display end date is required" }));
            valid = false;
        }
        if (keyDateSystemTypeId === 4 && selectedSystems.length === 0) {
            setErrors((prev) => ({ ...prev, systems: "System(s) is required" }));
            valid = false;
        }
        return valid;
    }, [formValues, selectedSystems]);

    const handleSubmit = useCallback(async () => {
        setErrors({
            title: "",
            description: "",
            keyDateSystemTypeId: "",
            eventDate: "",
            eventDisplayStartDate: "",
            eventDisplayEndDate: "",
            systems: "",
        });

        if (!isFormValid()) return;
        setIsSubmitting(true);
        let success = false;
        let createdKeyDateId = 0;
        if (type === "add") {
            const createdKeyDate = await createKeyDate({
                ...formValues,
                id: 0,
                keyDateSystems: [],
                isActive: true,
            });
            if (createdKeyDate) {
                success = true;
                createdKeyDateId = createdKeyDate.id;
            }
        } else {
            if (!selectedKeyDate) return;
            success = await editKeyDate({
                original: selectedKeyDate,
                updated: {
                    ...selectedKeyDate,
                    ...formValues,
                },
            });
        }

        if (success && formValues.keyDateSystemTypeId === keyDateSystemTypes.find((kds) => kds.name === "Custom")?.id) {
            var keyDateSystems: KeyDateSystem[] = Object.entries(selectedSystems).map(([key, value]) => ({
                id: 0,
                systemId: value.id,
                keyDateId: type === "add" ? createdKeyDateId : formValues.id,
                isActive: true,
            }));
            success = await upsertKeyDateSystems(keyDateSystems);
        }

        if (success) {
            getAllKeyDates();
            onClose();
        } else {
            setIsSubmitting(false);
        }
    }, [
        isFormValid,
        type,
        formValues,
        createKeyDate,
        selectedKeyDate,
        editKeyDate,
        selectedSystems,
        upsertKeyDateSystems,
        getAllKeyDates,
        onClose,
    ]);

    return (
        <>
            <Modal show={show} backdrop="static" keyboard={false} size="lg">
                <Modal.Header>{type === "add" ? "Add" : "Edit"} Key Date</Modal.Header>
                <Modal.Body>
                    <Form id="keyDateForm">
                        <Row>
                            <Col sm={4}>
                                <Form.Group controlId="title" className="mb-2">
                                    <Form.Label>Title</Form.Label>
                                    <Form.Control
                                        value={formValues.title}
                                        onChange={(e) => setFormValues((prev) => ({ ...prev, title: e.target.value }))}
                                        isInvalid={!!errors.title}
                                    />
                                    <Form.Control.Feedback type="invalid">{errors.title}</Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                            <Col sm={4}>
                                <Form.Group controlId="description" className="mb-2">
                                    <Form.Label>Description</Form.Label>
                                    <Form.Control
                                        value={formValues.description}
                                        onChange={(e) =>
                                            setFormValues((prev) => ({ ...prev, description: e.target.value }))
                                        }
                                        isInvalid={!!errors.description}
                                    />
                                    <Form.Control.Feedback type="invalid">{errors.description}</Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                            <Col sm={4}>
                                <Form.Group controlId="keyDateType" className="mb-2">
                                    <Form.Label>Key Date Type</Form.Label>
                                    <Form.Select
                                        value={formValues.keyDateSystemTypeId}
                                        onChange={(e) =>
                                            setFormValues((prev) => ({ ...prev, keyDateSystemTypeId: +e.target.value }))
                                        }
                                        isInvalid={!!errors.keyDateSystemTypeId}
                                    >
                                        <option disabled value={0}>
                                            Select a key date type...
                                        </option>
                                        {keyDateSystemTypes.map((kdst) => (
                                            <option key={kdst.id} value={kdst.id}>
                                                {kdst.name}
                                            </option>
                                        ))}
                                    </Form.Select>
                                    <Form.Control.Feedback type="invalid">
                                        {errors.keyDateSystemTypeId}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={4}>
                                <Form.Group controlId="eventDate" className="mb-2">
                                    <Form.Label>Event Date</Form.Label>
                                    <DatePicker
                                        className="form-control"
                                        onChange={(e) => {
                                            setDisplayStartDate(e!);
                                            setFormValues((prev) => ({ ...prev, eventDate: e?.toLocaleDateString()! }));
                                        }}
                                        selected={getSafeDateValue(formValues.eventDate)}
                                        minDate={eventDate}
                                    />
                                    {!!errors.eventDate && <div className="error-feedback">{errors.eventDate}</div>}
                                </Form.Group>
                            </Col>
                            <Col sm={4}>
                                <Form.Group controlId="eventDisplayStartDate" className="mb-2">
                                    <Form.Label>Display Start Date</Form.Label>
                                    <DatePicker
                                        className="form-control"
                                        onChange={(e) => {
                                            setDisplayEndDate(e!);
                                            setEventDate(e!);
                                            setFormValues((prev) => ({
                                                ...prev,
                                                eventDisplayStartDate: e?.toLocaleDateString()!,
                                            }));
                                        }}
                                        selected={getSafeDateValue(formValues.eventDisplayStartDate)}
                                        maxDate={displayStartDate}
                                    />
                                    {!!errors.eventDisplayStartDate && (
                                        <div className="error-feedback">{errors.eventDisplayStartDate}</div>
                                    )}
                                </Form.Group>
                            </Col>
                            <Col sm={4}>
                                <Form.Group controlId="eventDisplayEndDate" className="mb-2">
                                    <Form.Label>Display End Date</Form.Label>
                                    <DatePicker
                                        className="form-control"
                                        onChange={(e) => {
                                            setDisplayStartDate(e!);
                                            setFormValues((prev) => ({
                                                ...prev,
                                                eventDisplayEndDate: e?.toLocaleDateString()!,
                                            }));
                                        }}
                                        selected={getSafeDateValue(formValues.eventDisplayEndDate)}
                                        minDate={displayEndDate}
                                    />
                                    {!!errors.eventDisplayEndDate && (
                                        <div className="error-feedback">{errors.eventDisplayEndDate}</div>
                                    )}
                                </Form.Group>
                            </Col>
                        </Row>
                        {formValues.keyDateSystemTypeId ===
                            keyDateSystemTypes.find((kds) => kds.name === "Custom")?.id && (
                            <Row>
                                <Col sm={4}>
                                    <Form.Group controlId="systems" className="mb-2">
                                        <Form.Label>Systems</Form.Label>
                                        <Multiselect
                                            options={systems}
                                            selectedValues={preSelectedSystems}
                                            displayValue="name"
                                            onSelect={(e) => {
                                                setSelectedSystems(e);
                                            }}
                                            onRemove={(e) => {
                                                setSelectedSystems(e);
                                            }}
                                            placeholder="Select Systems"
                                        />
                                        {!!errors.systems && <div className="error-feedback">{errors.systems}</div>}
                                    </Form.Group>
                                </Col>
                            </Row>
                        )}
                    </Form>
                </Modal.Body>
                <Modal.Footer className="d-flex justify-content-between">
                    <Button variant="secondary" onClick={onClose}>
                        Cancel
                    </Button>
                    <Button variant="primary" onClick={handleSubmit} className="ms-2" disabled={isSubmitting}>
                        {isSubmitting ? "Saving..." : "Save"}
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};
