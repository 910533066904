import { Column, Table } from "@tanstack/react-table";
import { useMemo } from "react";
import { Form } from "react-bootstrap";

interface ColumnFilterProps {
    column: Column<any, unknown>;
    table: Table<any>;
}

export const ColumnFilter = ({ column, table }: ColumnFilterProps) => {
    const firstValue = table.getPreFilteredRowModel().flatRows[0]?.getValue(column.id);

    const columnFilterValue = column.getFilterValue();
    const facetedUniqueValues = column.getFacetedUniqueValues();

    const sortedUniqueValues = useMemo(
        () => (typeof firstValue === "number" ? [] : Array.from(facetedUniqueValues.keys()).sort()),
        [facetedUniqueValues, firstValue]
    );

    if (typeof firstValue === "boolean") {
        return (
            <div>
                <Form.Select
                    value={columnFilterValue === undefined ? "all" : columnFilterValue ? "yes" : "no"}
                    onChange={(e) =>
                        column.setFilterValue(e.target.value === "all" ? undefined : e.target.value === "yes")
                    }
                    size="sm"
                >
                    <option value={"all"}>All</option>
                    <option value={"yes"}>Yes</option>
                    <option value={"no"}>No</option>
                </Form.Select>
            </div>
        );
    }

    if (typeof firstValue === "number") {
        return <div style={{ height: "30px" }} />;
    }

    if (sortedUniqueValues.length < 11) {
        return (
            <Form.Select
                size="sm"
                value={(columnFilterValue || "") as string}
                onChange={(e) => column.setFilterValue(e.target.value || undefined)}
            >
                <option value="">All</option>
                {sortedUniqueValues.slice(0, 5000).map((value: any, i) => (
                    <option value={value} key={`${value}-${i}`}>
                        {value}
                    </option>
                ))}
            </Form.Select>
        );
    }

    return (
        <>
            <datalist id={column.id + "-list"}>
                {sortedUniqueValues.slice(0, 5000).map((value: any, i) => (
                    <option value={value} key={`${value}-${i}`} />
                ))}
            </datalist>
            <Form.Control
                type="text"
                size="sm"
                value={(columnFilterValue || "") as string}
                onChange={(e) => column.setFilterValue(e.target.value)}
                placeholder="Search..."
                list={column.id + "-list"}
            />
        </>
    );
};
