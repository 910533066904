import { PropsWithChildren, useCallback, useEffect, useMemo, useState } from "react";
import { useContext, useStoredState } from "../../hooks";
import { HttpOptions } from "../../models";
import { FileType } from "../../models/filetype/FileType";
import { useHttp } from "../http";
import { FileTypeContext, FileTypeState } from "./FileTypeContext";

export const initialFileTypeState: FileTypeState = {
    fileTypes: [],
};

export const FileTypeProvider = (props: PropsWithChildren) => {
    const { get } = useHttp();
    const [httpOptions] = useState<HttpOptions>({ path: "file-type" });

    const [fileTypeState, setFileTypeState] = useStoredState("fileType", initialFileTypeState);

    const getFileTypes = useCallback(async () => {
        const fileTypes = await get<FileType[]>(httpOptions);
        if (!fileTypes) return;
        setFileTypeState((prev) => ({ ...prev, fileTypes }));
    }, [get, httpOptions]);

    useEffect(() => {
        getFileTypes();
    }, []);

    const value = useMemo(
        () => ({
            ...fileTypeState,
            getFileTypes,
        }),
        [fileTypeState, getFileTypes]
    );

    return <FileTypeContext.Provider value={value} {...props} />;
};

export const useFileTypes = () => useContext(FileTypeContext);
