import { useCallback, useState } from "react";
import { Col, Form, Modal, Row, Button, Alert } from "react-bootstrap";
import { useAuth, useTenants } from "../../contexts";
import { useFileTypes } from "../../contexts/filetype";
import { State, Tenant } from "../../models";
import { Multiselect } from "multiselect-react-dropdown";
import { FileFormProps } from "../../models/form/FileFormProps";
import { useFacilityFiles } from "../../contexts/facility-file";
import { uploadFileToS3WithPreSignedUrl, getPreSignedUrl } from "../../helper-functions/S3";
import { AWS_CONFIG } from "../../config/config";
import _ from "lodash";
import { PreSignedUrlType } from "../../models/pre-signed-url/PreSignedUrlType";

interface SingleFileFormModel {
    filename: string;
    fileTypeId: number;
    systems: Tenant[];
    states: State[];
    stateId: number;
    systemId: number;
    linkname: string;
    isValidFileType: boolean;
}

export const initialSingleFileState: SingleFileFormModel = {
    filename: "",
    fileTypeId: 0,
    systems: [],
    states: [],
    stateId: 0,
    systemId: 0,
    linkname: "",
    isValidFileType: false,
};

export const SingleFileUpload = ({ onClose, onSave, show, files }: FileFormProps) => {
    const [isSubmitting, setIsSubmitting] = useState(false);
    const { fileTypes } = useFileTypes();
    const { tenants, states } = useTenants();
    const { decodedAccessToken, accessToken } = useAuth();
    const { createFacilityFile } = useFacilityFiles();
    const [systemsError, setSystemsError] = useState<string>("");
    const [statesError, setStatesError] = useState<string>("");

    const [errors, setErrors] = useState({
        system: "",
        state: "",
        filetype: "",
        linkname: "",
    });

    const getFileTypeId = (filename: string) => {
        const fileNameSplit = filename.split("_");
        if (fileNameSplit.length === 1) return 0;

        const prefix = fileNameSplit[0];
        const fileTypeId = fileTypes.find((ft) => ft.prefix === prefix)?.id;
        if (!fileTypeId) return 0;

        return fileTypeId;
    };

    const getLinkname = (filename: string) => {
        const fileNameSplit = filename.split("_");
        return fileNameSplit[fileNameSplit.length - 1].replace(/\.[^/.]+$/, "");
    };

    const [formValues, setFormValues] = useState<SingleFileFormModel>({
        filename: files[0].path!,
        fileTypeId: getFileTypeId(files[0].path!),
        systems: [],
        states: [],
        stateId: 0,
        systemId: 0,
        linkname: getLinkname(files[0].path!),
        isValidFileType: getFileTypeId(files[0].path!) > 0,
    });

    const systems = tenants.filter((t) => !t.parentTenantId);
    systems.push({
        id: 0,
        name: "All",
        ahcvIdentifier: "All",
        logoUri: "",
        isActive: true,
        usersCount: 0,
        county: "",
    });
    const sortedSystems = _.orderBy(systems, [(s) => s.id !== 0, (s) => s.name]);
    const singleSelectSystems = sortedSystems.filter((s) => s.id !== 0);

    const isFormValid = useCallback(() => {
        let valid = true;
        const { systemId, systems, fileTypeId, states, stateId, linkname } = formValues;
        if (!fileTypeId) {
            setErrors((prev) => ({ ...prev, filetype: "File type is required" }));
            valid = false;
        }
        if ((fileTypeId === 1 || fileTypeId === 3 || fileTypeId === 4) && systems.length === 0) {
            setSystemsError("Systems is required");
            valid = false;
        }
        if (fileTypeId === 2 && states.length === 0) {
            setStatesError("State is required");
            valid = false;
        }
        if (fileTypeId === 5) {
            if (!systemId) {
                setErrors((prev) => ({ ...prev, system: "System is required" }));
                valid = false;
            }
            if (!stateId) {
                setErrors((prev) => ({ ...prev, state: "State is required" }));
                valid = false;
            }
        }
        if ((fileTypeId === 1 || fileTypeId === 3 || fileTypeId === 4) && !stateId) {
            setErrors((prev) => ({ ...prev, state: "State is required" }));
            valid = false;
        }
        if (!linkname) {
            setErrors((prev) => ({ ...prev, linkname: "Linkname is required" }));
            valid = false;
        }
        return valid;
    }, [formValues]);

    const handleSubmit = useCallback(async () => {
        setErrors({
            system: "",
            state: "",
            filetype: "",
            linkname: "",
        });
        setStatesError("");
        setSystemsError("");
        if (!isFormValid()) return;
        setIsSubmitting(true);
        files.forEach(async (file) => {
            if (!file.path) return;
            const { systemId, systems, fileTypeId, states, stateId, linkname } = formValues;
            const filename = file.path;
            const filenameWithoutExtension = filename.replace(/\.[^/.]+$/, "");

            var preSignedUrl = await getPreSignedUrl(
                accessToken,
                filename,
                AWS_CONFIG.bucketName!,
                PreSignedUrlType.Upload
            );
            if (!preSignedUrl) {
                setIsSubmitting(false);
                return;
            }

            const awsResult = await uploadFileToS3WithPreSignedUrl(file, preSignedUrl);

            if (awsResult === 200) {
                if (fileTypeId === 1 || fileTypeId === 3 || fileTypeId === 4) {
                    systems.forEach((s) => {
                        createFacilityFile({
                            tenantId: parseInt(decodedAccessToken.tenant_id.toString()),
                            filename: filename,
                            awsFilename: `Uploads/${filename}`,
                            fileTypeId: fileTypeId,
                            isActive: true,
                            id: 0,
                            systemId: s.id,
                            stateId: stateId,
                            county: "",
                            linkName: linkname,
                        });
                    });
                } else if (fileTypeId === 2) {
                    states.forEach((s) => {
                        createFacilityFile({
                            tenantId: parseInt(decodedAccessToken.tenant_id.toString()),
                            filename: filename,
                            awsFilename: `Uploads/${filename}`,
                            fileTypeId: fileTypeId,
                            isActive: true,
                            id: 0,
                            systemId: systemId,
                            stateId: s.id,
                            county: "",
                            linkName: linkname,
                        });
                    });
                } else {
                    createFacilityFile({
                        tenantId: parseInt(decodedAccessToken.tenant_id.toString()),
                        filename: filename,
                        awsFilename: `Uploads/${filename}`,
                        fileTypeId: fileTypeId,
                        isActive: true,
                        id: 0,
                        systemId: systemId,
                        stateId: stateId,
                        county: "",
                        linkName: linkname,
                    });
                }
                setIsSubmitting(false);
                onSave();
            }
        });
    }, [createFacilityFile, decodedAccessToken.tenant_id, files, formValues, isFormValid, onSave]);

    return (
        <>
            <Modal show={show} backdrop="static" keyboard={false} size="lg">
                <Modal.Header>Select File Attributes</Modal.Header>
                <Modal.Body>
                    <Form id="singleFileUpload">
                        <Row>
                            <Col sm={4}>
                                <Form.Group controlId="filename" className="mb-2">
                                    <Form.Label>Filename</Form.Label>
                                    <Form.Control
                                        value={files[0].path}
                                        onChange={(e) =>
                                            setFormValues((prev) => ({ ...prev, filename: e.target.value }))
                                        }
                                        onLoad={(e) => setFormValues((prev) => ({ ...prev, filename: files[0].path! }))}
                                        disabled={true}
                                    />
                                </Form.Group>
                            </Col>
                            <Col sm={4}>
                                <Form.Group controlId="fileType" className="mb-2">
                                    <Form.Label>File Type</Form.Label>
                                    <Form.Select
                                        value={formValues.fileTypeId}
                                        onChange={(e) => {
                                            setFormValues((prev) => ({
                                                ...prev,
                                                fileTypeId: +e.target.value,
                                                isValidFileType: false,
                                            }));
                                        }}
                                        isInvalid={!!errors.filetype}
                                        disabled={formValues.isValidFileType && formValues.fileTypeId > 0}
                                    >
                                        <option disabled value={0}>
                                            Select File Type
                                        </option>
                                        {fileTypes.map((ft) => (
                                            <option key={ft.name} value={ft.id}>
                                                {ft.name}
                                            </option>
                                        ))}
                                    </Form.Select>
                                    <Form.Control.Feedback type="invalid">{errors.filetype}</Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                            {(formValues.fileTypeId === 1 ||
                                formValues.fileTypeId === 3 ||
                                formValues.fileTypeId === 4) && (
                                <Col sm={4}>
                                    <Form.Group controlId="systems" className="mb-2">
                                        <Form.Label>Systems</Form.Label>
                                        <Multiselect
                                            options={sortedSystems}
                                            displayValue="name"
                                            onSelect={(e) => {
                                                setFormValues((prev) => ({ ...prev, systems: e }));
                                            }}
                                            placeholder="Select System"
                                        />
                                        {!!systemsError && <div className="error-feedback">{systemsError}</div>}
                                    </Form.Group>
                                </Col>
                            )}
                            {formValues.fileTypeId === 2 && (
                                <Col sm={4}>
                                    <Form.Group controlId="states" className="mb-2">
                                        <Form.Label>State</Form.Label>
                                        <Multiselect
                                            options={states}
                                            displayValue="name"
                                            onSelect={(e) => {
                                                setFormValues((prev) => ({ ...prev, states: e }));
                                            }}
                                            placeholder="Select State"
                                        />
                                        {!!statesError && <div className="error-feedback">{statesError}</div>}
                                    </Form.Group>
                                </Col>
                            )}
                            {(formValues.fileTypeId === 1 ||
                                formValues.fileTypeId === 3 ||
                                formValues.fileTypeId === 4 ||
                                formValues.fileTypeId === 5) && (
                                <Col sm={4}>
                                    <Form.Group controlId="state" className="mb-2">
                                        <Form.Label>State</Form.Label>
                                        <Form.Select
                                            value={formValues.stateId}
                                            onChange={(e) => {
                                                setFormValues((prev) => ({ ...prev, stateId: +e.target.value }));
                                            }}
                                            isInvalid={!!errors.state}
                                        >
                                            <option disabled value={0}>
                                                Select State
                                            </option>
                                            {states.map((s) => (
                                                <option key={s.name} value={s.id}>
                                                    {s.name}
                                                </option>
                                            ))}
                                        </Form.Select>
                                        <Form.Control.Feedback type="invalid">{errors.state}</Form.Control.Feedback>
                                    </Form.Group>
                                </Col>
                            )}
                        </Row>
                        <Row>
                            <Col sm={4}>
                                <Form.Group controlId="linkname" className="mb-2">
                                    <Form.Label>Display name</Form.Label>
                                    <Form.Control
                                        value={formValues.linkname}
                                        onChange={(e) =>
                                            setFormValues((prev) => ({ ...prev, linkname: e.target.value }))
                                        }
                                        onLoad={(e) =>
                                            setFormValues((prev) => ({ ...prev, linkname: formValues.linkname }))
                                        }
                                        isInvalid={!!errors.linkname}
                                    />
                                    <Form.Control.Feedback type="invalid">{errors.linkname}</Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                            {formValues.fileTypeId === 5 && (
                                <Col sm={4}>
                                    <Form.Group controlId="system" className="mb-2">
                                        <Form.Label>System</Form.Label>
                                        <Form.Select
                                            value={formValues.systemId}
                                            onChange={(e) => {
                                                setFormValues((prev) => ({ ...prev, systemId: +e.target.value }));
                                            }}
                                            isInvalid={!!errors.system}
                                        >
                                            <option disabled value={0}>
                                                Select System
                                            </option>
                                            {singleSelectSystems.map((s) => (
                                                <option key={s.name} value={s.id}>
                                                    {s.name}
                                                </option>
                                            ))}
                                        </Form.Select>
                                        <Form.Control.Feedback type="invalid">{errors.system}</Form.Control.Feedback>
                                    </Form.Group>
                                </Col>
                            )}
                        </Row>
                    </Form>
                </Modal.Body>
                <Modal.Footer className="d-flex justify-content-between">
                    <Button variant="secondary" onClick={onClose}>
                        Cancel
                    </Button>
                    <Button variant="primary" onClick={handleSubmit} className="ms-2" disabled={isSubmitting}>
                        {isSubmitting ? "Uploading..." : "Upload"}
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};
