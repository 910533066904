import React, { createContext } from "react";
import { DecodedAccessToken } from "../../models";

export interface AuthState {
    accessToken: string;
    decodedAccessToken: DecodedAccessToken;
    failedLoginAttempts: number;
    isAuthenticated: boolean;
    isLoggingOut: boolean;
    isLoggingIn: boolean;
    refreshToken: string;
    shouldLogout: boolean;
}

interface IAuthContext extends AuthState {
    isAuthorized: boolean;
    canRefresh: boolean;
    setAuthState: React.Dispatch<React.SetStateAction<AuthState>>;
    shouldLogin: boolean;
}

export const AuthContext = createContext<IAuthContext | undefined>(undefined);
AuthContext.displayName = "Auth";