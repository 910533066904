import { PropsWithChildren, useCallback, useMemo, useState } from "react";
import { useStoredState, useContext } from "../../hooks";
import { HttpOptions } from "../../models";
import { KeyDateSystem } from "../../models/key-date-system/KeyDateSystem";
import { useHttp } from "../http";
import { KeyDateSystemContext, KeyDateSystemState } from "./KeyDateSystemContext";

export const initialKeyDateSystemState: KeyDateSystemState = {
    keyDateSystems: [],
};

export const KeyDateSystemProvider = (props: PropsWithChildren) => {
    const { get, post } = useHttp();
    const [httpOptions] = useState<HttpOptions>({ path: "key-date-system" });
    const [keyDateSystemState, setKeyDateSystemState] = useStoredState<KeyDateSystemState>(
        "keyDateSystem",
        initialKeyDateSystemState
    );

    const getAllKeyDateSystems = useCallback(async () => {
        const keyDateSystems = await get<KeyDateSystem[]>({ path: `key-date-system` });
        if (!keyDateSystems) return;
        setKeyDateSystemState((prev) => ({ ...prev, keyDateSystems }));
    }, [get, setKeyDateSystemState]);

    const upsertKeyDateSystems = useCallback(
        async (keyDateSystems: KeyDateSystem[]) => {
            const createdKeyDateSystems = await post<KeyDateSystem[]>({ ...httpOptions, body: keyDateSystems });
            if (createdKeyDateSystems) {
                console.log(createdKeyDateSystems);
            }
            return !!createdKeyDateSystems;
        },
        [httpOptions, post]
    );

    const value = useMemo(
        () => ({
            ...keyDateSystemState,
            getAllKeyDateSystems,
            upsertKeyDateSystems,
        }),
        [upsertKeyDateSystems, getAllKeyDateSystems, keyDateSystemState]
    );

    return <KeyDateSystemContext.Provider value={value} {...props} />;
};

export const useKeyDateSystems = () => useContext(KeyDateSystemContext);
