import { RegionCounty } from "../../models/regionCounties/RegionCounty";
import { createContext } from "react";

export interface RegionCountyState {
    regionCounties: RegionCounty[];
}

interface IRegionCountyContext extends RegionCountyState {
    getRegionCounties: (stateId: number) => Promise<void>;
}

export const RegionCountyContext = createContext<IRegionCountyContext | undefined>(undefined);
RegionCountyContext.displayName = "RegionCounty";
