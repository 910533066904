import { FileType } from "../../models/filetype/FileType";
import { createContext } from "react";

export interface FileTypeState {
    fileTypes: FileType[];
}

interface IFileTypeContext extends FileTypeState {
    getFileTypes: () => Promise<void>;
}

export const FileTypeContext = createContext<IFileTypeContext | undefined>(undefined);
FileTypeContext.displayName = "FileType";
