import { useCallback, useEffect, useMemo, useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { ColumnDef } from "@tanstack/react-table";
import { User, Identifiable, FormProps } from "../../models";
import { useAppSettings, useRoles, useTenants, useUsers } from "../../contexts";
import { BooleanIcon, ConfirmationModal, DataTable } from "../../component-library";
import { UserForm } from "./UserForm";

interface UserWithFullDetails extends Identifiable {
    fullName: string;
    email: string;
    tenant: string;
    partnerTenant: string;
    customerTenant: string;
    role: string;
    isActive: boolean;
}

export default function Users() {
    const { partnerName, customerName } = useAppSettings();
    const { users, selectedUser, getUsers, selectUser, clearSelectedUser, restoreUser, deleteUser } = useUsers();
    const { roles } = useRoles();
    const { getTenants } = useTenants();

    const [shouldShowInactiveUsers, setShouldshowInactiveUsers] = useState<boolean>(false);

    const [userFormProps, setUserFormProps] = useState<FormProps>({ show: false, type: "add", onClose: () => {} });

    const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState<boolean>(false);
    const [showConfirmRestoreModal, setShowConfirmRestoreModal] = useState<boolean>(false);

    const getRole = useCallback(
        (id: number) => {
            return roles.find((r) => r.id === id)?.name;
        },
        [roles]
    );

    const usersWithFullDetails = useMemo(() => {
        let details: UserWithFullDetails[];
        details = users.map(
            (user: User) =>
                ({
                    id: user.id,
                    fullName: `${user.lastName}, ${user.firstName}`,
                    email: user.emailAddress,
                    tenant: user.tenantName,
                    partnerTenant: user.partnerTenantName,
                    customerTenant: user.customerTenantName,
                    role: getRole(user.roleId),
                    isActive: user.isActive,
                } as UserWithFullDetails)
        );
        return shouldShowInactiveUsers ? details : details.filter((u) => u.isActive);
    }, [users, getRole, shouldShowInactiveUsers]);

    useEffect(() => {
        if (users.length < 1) {
            getUsers();
        }
    }, [getUsers, users]);

    useEffect(() => {
        getTenants();
    }, []);

    const hideUserModal = () => setUserFormProps((prev) => ({ ...prev, show: false }));

    const showAddUserModal = useCallback(() => {
        clearSelectedUser();
        setUserFormProps({
            onClose: hideUserModal,
            show: true,
            type: "add",
        });
    }, [clearSelectedUser, hideUserModal]);

    const handleDeleteUser = useCallback(() => {
        if (selectedUser) {
            deleteUser(selectedUser);
            setShowConfirmDeleteModal(false);
        }
    }, [deleteUser, selectedUser]);

    const handleRestoreUser = useCallback(() => {
        if (selectedUser) {
            restoreUser(selectedUser);
            setShowConfirmRestoreModal(false);
        }
    }, [selectedUser, restoreUser]);

    const handleShowEditModal = useCallback(
        (userId: number) => {
            const user = users.find((user) => user.id === userId);
            if (!user) return;
            selectUser(user);
            setUserFormProps({
                onClose: hideUserModal,
                show: true,
                type: "edit",
            });
        },
        [users, selectUser]
    );

    const handleShowDeleteModal = useCallback(
        (userId: number) => {
            const user = users.find((user: User) => user.id === userId);
            if (!user) return;
            selectUser(user);
            setShowConfirmDeleteModal(true);
        },
        [users, selectUser]
    );

    const handleShowRestoreModal = useCallback(
        (userId: number) => {
            const user = users.find((user: User) => user.id === userId);
            if (!user) return;
            selectUser(user);
            setShowConfirmRestoreModal(true);
        },
        [users, selectUser]
    );

    const columns = useMemo(
        (): ColumnDef<UserWithFullDetails>[] => [
            {
                header: "Name",
                accessorKey: "fullName",
            },
            {
                header: "Email",
                accessorKey: "email",
            },
            {
                header: partnerName,
                accessorKey: "partnerTenant",
            },
            {
                header: customerName,
                accessorKey: "customerTenant",
            },
            {
                header: "Role",
                accessorKey: "role",
            },
            {
                header: "",
                id: "actions",
                cell: ({ row }) => {
                    return (
                        <div className="d-flex justify-content-end align-items-center">
                            <Button
                                size="sm"
                                variant="outline-info"
                                title="Edit"
                                onClick={() => handleShowEditModal(row.original.id)}
                            >
                                <i className="fas fa-edit" />
                            </Button>

                            {row.original.isActive ? (
                                <Button
                                    size="sm"
                                    className="ms-2"
                                    variant="outline-danger"
                                    title="Delete"
                                    onClick={() => handleShowDeleteModal(row.original.id)}
                                >
                                    <i className="fas fa-trash" />
                                </Button>
                            ) : (
                                <Button
                                    size="sm"
                                    className="ms-2"
                                    variant="outline-success"
                                    title="Restore"
                                    onClick={() => handleShowRestoreModal(row.original.id)}
                                >
                                    <i className="fas fa-redo" />
                                </Button>
                            )}
                        </div>
                    );
                },
            },
        ],
        [partnerName, customerName, handleShowEditModal, handleShowDeleteModal, handleShowRestoreModal]
    );

    const columnsWithActive = useMemo(
        (): ColumnDef<UserWithFullDetails>[] => [
            {
                header: "Name",
                accessorKey: "fullName",
            },
            {
                header: "Email",
                accessorKey: "email",
            },
            {
                header: partnerName,
                accessorKey: "partnerTenant",
            },
            {
                header: customerName,
                accessorKey: "customerTenant",
            },
            {
                header: "Role",
                accessorKey: "role",
            },
            {
                header: "Active?",
                accessorKey: "isActive",
                cell: ({ getValue }) => (
                    <div className="text-center">
                        <BooleanIcon value={getValue() as boolean} />
                    </div>
                ),
            },
            {
                header: "",
                id: "actions",
                cell: ({ row }) => {
                    return (
                        <div className="d-flex justify-content-end align-items-center">
                            <Button
                                size="sm"
                                variant="outline-info"
                                title="Edit"
                                onClick={() => handleShowEditModal(row.original.id)}
                            >
                                <i className="fas fa-edit" />
                            </Button>

                            {row.original.isActive ? (
                                <Button
                                    size="sm"
                                    className="ms-2"
                                    variant="outline-danger"
                                    title="Deactivate"
                                    onClick={() => handleShowDeleteModal(row.original.id)}
                                >
                                    <i className="fas fa-trash" />
                                </Button>
                            ) : (
                                <Button
                                    size="sm"
                                    className="ms-2"
                                    variant="outline-success"
                                    title="Activate"
                                    onClick={() => handleShowRestoreModal(row.original.id)}
                                >
                                    <i className="fas fa-redo" />
                                </Button>
                            )}
                        </div>
                    );
                },
            },
        ],
        [partnerName, customerName, handleShowEditModal, handleShowDeleteModal, handleShowRestoreModal]
    );

    return (
        <Container fluid className="pt-2">
            <Row>
                <Col>
                    <h1>Users</h1>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Button onClick={showAddUserModal} variant="primary">
                        New User
                    </Button>
                </Col>
            </Row>
            <Row>
                <Col className="d-flex justify-content-end">
                    <label onClick={() => setShouldshowInactiveUsers((x) => !x)}>
                        <i
                            className={!shouldShowInactiveUsers ? "far fa-circle" : "fas fa-check-circle"}
                            style={{
                                fontSize: "1.2rem",
                                color: !shouldShowInactiveUsers ? "gray" : "green",
                            }}
                        />{" "}
                        Show Inactive Users
                    </label>
                </Col>
            </Row>

            <DataTable
                data={usersWithFullDetails}
                columns={shouldShowInactiveUsers ? columnsWithActive : columns}
                paginated={usersWithFullDetails.length > 20}
                showGlobalFilter
                filterColumns
            />

            {userFormProps.show && <UserForm {...userFormProps} />}
            <ConfirmationModal
                id="confirmDeleteUserModal"
                show={showConfirmDeleteModal}
                title="Deactivate User?"
                message={"Do you really want to deactivate this user?"}
                onCloseButtonClick={() => setShowConfirmDeleteModal(false)}
                onOkButtonClick={handleDeleteUser}
                theme={"danger"}
            />
            <ConfirmationModal
                id="confirmRestoreUserModal"
                show={showConfirmRestoreModal}
                title="Activate User?"
                message={"Do you really want to activate this user?"}
                onCloseButtonClick={() => setShowConfirmRestoreModal(false)}
                onOkButtonClick={handleRestoreUser}
                theme={"danger"}
            />
        </Container>
    );
}
