import { PropsWithChildren, useCallback, useMemo, useState } from "react";
import { createPatch, Operation } from "rfc6902";
import { replaceInCollection } from "../../helper-functions";
import { useContext, useStoredState } from "../../hooks";
import { HttpOptions, Operable } from "../../models";
import { KeyDate } from "../../models/key-date/KeyDate";
import { useHttp } from "../http";
import { KeyDateContext, KeyDateState } from "./KeyDateContext";

export const initialKeyDateState: KeyDateState = {
    keyDates: [],
};

export const KeyDateProvider = (props: PropsWithChildren) => {
    const { get, post, patchUnique } = useHttp();
    const [httpOptions] = useState<HttpOptions>({ path: "key-date" });
    const [keyDateState, setKeyDateState] = useStoredState<KeyDateState>("keyDate", initialKeyDateState);

    const getAllKeyDates = useCallback(async () => {
        const keyDates = await get<KeyDate[]>({ path: `key-date` });
        if (!keyDates) return;
        setKeyDateState((prev) => ({ ...prev, keyDates }));
    }, [get, setKeyDateState]);

    const getAllUpcomingKeyDates = useCallback(async () => {
        const keyDates = await get<KeyDate[]>({ path: `key-date/upcoming` });
        if (!keyDates) return;
        setKeyDateState((prev) => ({ ...prev, keyDates }));
    }, [get, setKeyDateState]);

    const createKeyDate = useCallback(
        async (keyDate: KeyDate): Promise<KeyDate | void> => {
            const createdKeyDate = await post<KeyDate>({ ...httpOptions, body: keyDate });
            if (createdKeyDate) {
                console.log(createdKeyDate);
            }
            return createdKeyDate;
        },
        [httpOptions, post]
    );

    const editKeyDate = useCallback(
        async ({ original, updated }: Operable<KeyDate>) => {
            const patchedKeyDate = await patchUnique<Operation[], KeyDate>({
                path: `key-date/${original.id}`,
                body: createPatch(original, updated),
            });
            if (patchedKeyDate) {
                setKeyDateState((prev) => ({
                    ...prev,
                    keyDates: replaceInCollection([...prev.keyDates], { original, updated }),
                }));
            }
            return !!patchedKeyDate;
        },
        [patchUnique, setKeyDateState]
    );

    const selectKeyDate = useCallback(
        (selectedKeyDate: KeyDate | undefined) => setKeyDateState((prev) => ({ ...prev, selectedKeyDate })),
        []
    );

    const clearSelectedKeyDate = useCallback(
        () => setKeyDateState((prev) => ({ ...prev, selectedKeyDate: undefined })),
        []
    );

    const value = useMemo(
        () => ({
            ...keyDateState,
            getAllKeyDates,
            getAllUpcomingKeyDates,
            createKeyDate,
            editKeyDate,
            selectKeyDate,
            clearSelectedKeyDate,
        }),
        [
            clearSelectedKeyDate,
            createKeyDate,
            editKeyDate,
            getAllKeyDates,
            getAllUpcomingKeyDates,
            keyDateState,
            selectKeyDate,
        ]
    );
    return <KeyDateContext.Provider value={value} {...props} />;
};

export const useKeyDates = () => useContext(KeyDateContext);
