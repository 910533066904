/* eslint-disable react-hooks/exhaustive-deps */

import { Modal, Button, Row } from "react-bootstrap";
import { useEmailArchives } from "../../contexts";
import { EmailArchiveFormProps } from "../../models/emailArchive/EmailArchiveFormProps";
import dompurify from "dompurify";
import { getDateWithMonthName } from "../../helper-functions/getDateWithMonthName";

export const ViewEmailArchive = ({ onClose, show, id }: EmailArchiveFormProps) => {
    const { emailArchives } = useEmailArchives();
    var emailArchive = emailArchives.find((ea) => ea.id === id);

    return (
        <>
            <Modal show={show} backdrop="static" keyboard={false} size="lg">
                <Modal.Header>AHCV Nightly Brefing: {getDateWithMonthName(emailArchive?.receivedDate)}</Modal.Header>
                <Modal.Body>
                    <Row>{<div dangerouslySetInnerHTML={{ __html: dompurify.sanitize(emailArchive?.body!) }} />}</Row>
                </Modal.Body>
                <Modal.Footer className="d-flex justify-content-between">
                    <Button variant="primary" onClick={onClose} className="ms-2">
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};
