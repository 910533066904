import { createContext } from "react";
import { KeysysToastProps } from "../../component-library";

interface IToastContext {
    activeToasts: KeysysToastProps[];
    showToast: (toastProps: KeysysToastProps) => void;
    hideToast: (name: string) => void;
}

export const ToastContext = createContext<IToastContext | undefined>(undefined);
ToastContext.displayName = "Toast";