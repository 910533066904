import _ from "lodash";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import Select from "react-select";
import { usePayments, useTenants, useEmailArchives, useAuth, useUpcomingTransactions } from "../../contexts";
import { Filter, FormProps } from "../../models";
import { ViewEmailArchiveTable } from "../emailArchive/ViewEmailArchiveTable";
import { EmailArchiveFormProps } from "../../models/emailArchive/EmailArchiveFormProps";
import { ViewEmailArchive } from "../emailArchive/ViewEmailArchive";
import { useFacilityFiles } from "../../contexts/facility-file";
import { useFileTypes } from "../../contexts/filetype";
import { getMonthName } from "../../helper-functions/getMonthName";
import { AhcvFiles } from "./AhcvFiles";
import { MedicaidSuppPaymentProgramRevenue } from "./MedicaidSuppPaymentProgramRevenue";
import { KeyDates } from "./KeyDates";
import { UpcomingTransactions } from "./UpcomingTransactions";

export const Portal = () => {
    const { decodedAccessToken } = useAuth();
    const isAdmin = decodedAccessToken.roles === "GlobalAdmin";
    const hasPaymentDatabaseAccess = decodedAccessToken.payment_database_access === "True";
    const [facilityIdState, setFacilityIdState] = useState<number>(0);
    const { tenants, authorizedTenants, states, getTenants, getAuthorizedTenants } = useTenants();
    const { fileTypes } = useFileTypes();
    const sortedFacilities = _.orderBy(authorizedTenants, (t) => t.name);
    const { getPayments } = usePayments();
    const { emailArchives, getEmailArchives } = useEmailArchives();
    const { facilityFiles, getFacilityFilesBySystemId, downloadFacilityFileFromS3 } = useFacilityFiles();
    const { getUpcomingTransactions } = useUpcomingTransactions();
    const [paymentsLoadedForFacility, setPaymentsLoadedForFacility] = useState<boolean>(false);
    const [filesLoadedForFacility, setFilesLoadedForFacility] = useState<boolean>(false);

    useEffect(() => {
        getTenants();
        getAuthorizedTenants();
    }, []);

    const parentTenantId = useMemo(
        () => tenants.find((t) => t.id === facilityIdState)?.parentTenantId,
        [facilityIdState, tenants]
    );

    useEffect(() => {}, [parentTenantId]);
    const systemId = useMemo(
        () => (parentTenantId ? parentTenantId : facilityIdState),
        [facilityIdState, parentTenantId]
    );

    const stateIds = useMemo(() => {
        //!parentId means it is a system
        if (!parentTenantId) {
            return tenants.filter((t) => t.parentTenantId === facilityIdState).map((t) => t.stateId);
        }
        return tenants.filter((t) => t.id === facilityIdState).map((t) => t.stateId);
    }, [facilityIdState, parentTenantId, tenants]);

    const facilityOptions = useMemo(() => {
        let result = [];
        result.push(
            ...sortedFacilities.map((facility) => ({
                label: facility.name.length >= 50 ? facility.name.substring(0, 50) + " ..." : facility.name,
                value: facility.id,
            }))
        );
        return result;
    }, [sortedFacilities]);

    //EmailArchives
    const [viewEmailArchiveTableProps, setViewEmailArchiveTableProps] = useState<FormProps>({
        show: false,
        type: "add",
        onClose: () => {},
    });

    const hideEmailArchiveTable = useCallback(
        () => setViewEmailArchiveTableProps((prev) => ({ ...prev, show: false })),
        []
    );

    const showEmailArchiveTable = useCallback(() => {
        setViewEmailArchiveTableProps({
            onClose: hideEmailArchiveTable,
            show: true,
            type: "add",
        });
    }, [hideEmailArchiveTable]);

    //Single Email Archive
    const [viewEmailArchiveProps, setViewEmailArchiveProps] = useState<EmailArchiveFormProps>({
        show: false,
        id: 0,
        onClose: () => {},
    });

    const hideEmailArchive = useCallback(() => setViewEmailArchiveProps((prev) => ({ ...prev, show: false })), []);

    const showEmailArchive = useCallback(
        (id: number) => {
            setViewEmailArchiveProps({
                onClose: hideEmailArchive,
                show: true,
                id: id,
            });
        },
        [hideEmailArchive]
    );

    useEffect(() => {
        if (!paymentsLoadedForFacility) {
            setPaymentsLoadedForFacility(true);
            const filter: Filter = {
                stateId: 0,
                parentTenantId: parentTenantId ? 0 : facilityIdState,
                tenantId: !parentTenantId ? 0 : facilityIdState,
                programId: 0,
                year: 0,
                county: "",
                region: "",
                paymentDbAccess: false,
            };
            getPayments(filter);
            getUpcomingTransactions(facilityIdState);
        }
    }, [facilityIdState, getPayments, parentTenantId, paymentsLoadedForFacility]);

    useEffect(() => {
        setPaymentsLoadedForFacility(false);
    }, [facilityIdState]);

    useEffect(() => {
        if (systemId !== undefined && !filesLoadedForFacility) {
            setFilesLoadedForFacility(true);
            getFacilityFilesBySystemId(systemId);
        }
    }, [filesLoadedForFacility, getFacilityFilesBySystemId, systemId]);

    useEffect(() => {
        setFilesLoadedForFacility(false);
    }, [facilityIdState]);

    useEffect(() => {
        getEmailArchives();
    }, []);
    const emailArchiveData = _.orderBy(emailArchives, (ea) => ea.receivedDate, "desc").slice(0, 7);

    // Supplemental Files
    const supplementalFiles = facilityFiles.filter(
        (sf) => sf.fileTypeId === fileTypes.find((ft) => ft.prefix === "SPT")?.id && stateIds.includes(sf.stateId)
    );

    const supplementalGrouped = _.groupBy(supplementalFiles, (f) => f.stateId);
    const supplementalGroupedData = Object.entries(supplementalGrouped).map(([key, value]) => ({
        name: states.find((s) => s.id === value[0].stateId)?.name,
        stateId: value[0].stateId,
    }));

    const supplementalData = Object.entries(supplementalFiles).map(([key, value]) => ({
        name: states.find((s) => s.id === value.stateId)?.name,
        filename: value.filename,
        awsFilename: value.awsFilename,
        stateId: value.stateId,
        id: value.id,
        linkName: value.linkName,
    }));

    // Download File
    const downloadFileFromS3 = useCallback(
        (awsFileName: string, filename: string) => {
            downloadFacilityFileFromS3(awsFileName, filename);
        },
        [downloadFacilityFileFromS3]
    );

    return (
        <Container fluid className="pt-2">
            <Row className="d-flex align-items-stretch gx-2">
                <Col xl={3} lg={12} className="d-flex flex-column mb-2">
                    <div className="chart-border mb-2">
                        <h2 className="modal-header">Facilities</h2>
                        <Select
                            options={facilityOptions}
                            onChange={(e) => {
                                setFacilityIdState(Number(e?.value));
                            }}
                            placeholder="Select Facility"
                            value={facilityOptions.find((opt) => +opt.value === facilityIdState)}
                        />
                    </div>
                    <AhcvFiles facilityIdState={facilityIdState} />
                </Col>
                <Col xl={6} lg={9} className="mb-2">
                    <MedicaidSuppPaymentProgramRevenue facilityIdState={facilityIdState} />
                </Col>
                <Col lg={3} className="mb-2">
                    <div className="chart-border h-100 d-flex flex-column justify-content-stretch">
                        <h2 className="modal-header">AHCV Nightly Briefing</h2>
                        {emailArchiveData.map((ea) => {
                            const receivedDate = new Date(ea.receivedDate);
                            return (
                                <Button
                                    key={`btn-ea-${ea.id}`}
                                    className="update-btn mb-2"
                                    onClick={() => showEmailArchive(ea.id)}
                                >
                                    {getMonthName(receivedDate.getMonth())} {receivedDate.getDate()},{" "}
                                    {receivedDate.getFullYear()}
                                    <i className="fas fa-arrow-circle-up update" />
                                </Button>
                            );
                        })}
                        <Button className="mt-3" onClick={() => showEmailArchiveTable()}>
                            Archive
                        </Button>
                    </div>
                </Col>
            </Row>
            <Row className="d-flex align-items-stretch gx-2">
                <Col lg={3} className="d-flex flex-column no-bg mb-2">
                    <div className="chart-border" style={{ flexGrow: 1 }}>
                        <h2 className="modal-header">Supplemental Payment Timelines</h2>
                        <div className="panel-body">
                            {supplementalGroupedData.map((sgd) => (
                                <ul key={`ul-sgd-${sgd.name}-${sgd.stateId}`} className="no-bullet-list">
                                    {sgd.name}
                                    {supplementalData
                                        .filter((sd) => sd.stateId === sgd.stateId)
                                        .map((sd) => (
                                            <li key={`li-sd-${sd.id}-${sd.stateId}`} className="padding">
                                                <Link
                                                    to=""
                                                    onClick={() => downloadFileFromS3(sd.filename, sd.filename)}
                                                >
                                                    {sd.linkName}
                                                </Link>
                                            </li>
                                        ))}
                                </ul>
                            ))}
                        </div>
                    </div>
                    <Row className="m-0 pt-1">
                        {isAdmin || hasPaymentDatabaseAccess ? (
                            <Link className="btn btn-primary m-0" to="/payments-dashboard">
                                <i className="fas fa-lock" /> Payment Database
                            </Link>
                        ) : (
                            <button className="btn btn-primary m-0" disabled>
                                <i className="fas fa-lock" /> Payment Database
                            </button>
                        )}
                    </Row>
                </Col>
                <Col lg={6} className="no-bg mb-2">
                    <UpcomingTransactions facilityIdState={facilityIdState} />
                </Col>
                <Col lg={3} className="no-bg mb-2 row gx-2">
                    <div className="chart-border mb-2">
                        <h2 className="modal-header">Additional Resources</h2>
                        <div className="mb-2">
                            <a href="https://tehp.org/" className="padding" target="_blank" rel="noreferrer">
                                TEHP
                            </a>
                        </div>
                        <div className="mb-2">
                            <a href="https://fehp.org/" className="padding" target="_blank" rel="noreferrer">
                                FEHP
                            </a>
                        </div>
                        <div className="mb-2">
                            <a
                                className="padding"
                                href="https://www.kff.org/medicaid/state-indicator/federal-matching-rate-and-multiplier/?currentTimeframe=0&sortModel=%7B%22colId%22:%22Location%22,%22sort%22:%22asc%22%7D"
                                target="_blank"
                                rel="noreferrer"
                            >
                                FMAP
                            </a>
                        </div>
                    </div>
                    <KeyDates facilityIdState={facilityIdState} stateIds={stateIds} />
                </Col>
            </Row>
            {viewEmailArchiveTableProps.show && <ViewEmailArchiveTable {...viewEmailArchiveTableProps} />}
            {viewEmailArchiveProps.show && <ViewEmailArchive {...viewEmailArchiveProps} />}
        </Container>
    );
};
