import { useMemo, useCallback, PropsWithChildren } from "react";
import { PhotosContext, PhotosState, useHttp } from "..";
import { useContext, useStoredState } from "../../hooks";
import { AwsFile, CreatePhotoRequest, Photo } from "../../models";

export const PhotoProvider = (props: PropsWithChildren) => {
    const { getBlob, postUnique, post } = useHttp();
    const [photosState, setPhotosState] = useStoredState<PhotosState>("photos", {
        photos: [],
        awsFiles: [],
        blobUrls: []
    });
    const { blobUrls } = photosState;

    const createPhoto = useCallback(
        async (createPhotoRequest: CreatePhotoRequest): Promise<boolean> => {
            const photo = await postUnique<CreatePhotoRequest, Photo>({
                path: "photos",
                body: createPhotoRequest
            });
            if (photo) {
                setPhotosState(prev => ({
                    ...prev,
                    photos: [...prev.photos, photo]
                }));
            }
            return !!photo;
        },
        [postUnique]
    );

    const uploadImage = useCallback(
        async (awsFile: AwsFile) => {
            const file = await post<AwsFile>({
                path: "aws",
                body: awsFile
            });
            if (file) {
                setPhotosState(prev => ({
                    ...prev,
                    awsFiles: [...prev.awsFiles, file]
                }));
            }
        },
        [post]
    );

    const clearImages = useCallback(() => {
        setPhotosState(prev => ({
            ...prev,
            awsFiles: []
        }));
    }, []);

    const getUrl = useCallback(
        async (fileName: string): Promise<string | void> => {
            const existingUrl = blobUrls.find((b) => b.fileName === fileName);
            if (existingUrl) {
                return existingUrl.url;
            }
            const blob = await getBlob({ path: `photos/download?fileName=${fileName}` });
            if (blob) {
                const url = URL.createObjectURL(blob);
                setPhotosState(prev => ({
                    ...prev,
                    blobUrls: [...prev.blobUrls, { fileName, url }]
                }));
                return url;
            }
        },
        [blobUrls, getBlob]
    );

    const value = useMemo(
        () => ({
            ...photosState,
            createPhoto,
            uploadImage,
            clearImages,
            getUrl,
        }),
        [photosState, createPhoto, uploadImage, clearImages, getUrl]
    );

    return <PhotosContext.Provider value={value} {...props} />;
}

export const usePhotos = () => useContext(PhotosContext);
