import { createContext } from "react";
import { AppSettings, Operable } from "../../models";

export interface AppSettingsState {
    customerName: string;
    partnerName: string;
    logoUri: string;
    siteName: string;
    menuBackgroundColor: string;
    menuSelectedColor: string;
}

interface IAppSettingsContext extends AppSettingsState {
    createAppSettings: (settings: AppSettings) => Promise<AppSettings | void>;
    editAppSettings: (settings: Operable<AppSettings>) => Promise<AppSettings | void>;
}

export const AppSettingsContext = createContext<IAppSettingsContext | undefined>(undefined);
AppSettingsContext.displayName = "AppSettings";