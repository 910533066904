import { PropsWithChildren } from "react";
import {
    AppSettingsProvider,
    AuthProvider,
    HttpProvider,
    OidcProvider,
    PhotoProvider,
    ProgramProvider,
    RoleProvider,
    TenantProvider,
    ToastProvider,
    UserProvider,
    PaymentProvider,
    FilterProvider,
    EmailArchiveProvider,
    UpcomingTransactionProvider,
} from ".";
import { FacilityFileProvider } from "./facility-file";
import { FileTypeProvider } from "./filetype";
import { KeyDateProvider } from "./key-date";
import { KeyDateSystemProvider } from "./key-date-system";
import { KeyDateSystemTypeProvider } from "./key-date-system-type";
import { RegionCountyProvider } from "./region-county";

export const Provider = ({ children }: PropsWithChildren) => (
    <ToastProvider>
        <AuthProvider>
            <HttpProvider>
                <OidcProvider>
                    <AppSettingsProvider>
                        <RoleProvider>
                            <TenantProvider>
                                <UserProvider>
                                    <PhotoProvider>
                                        <FilterProvider>
                                            <PaymentProvider>
                                                <ProgramProvider>
                                                    <FileTypeProvider>
                                                        <EmailArchiveProvider>
                                                            <FacilityFileProvider>
                                                                <UpcomingTransactionProvider>
                                                                    <KeyDateSystemTypeProvider>
                                                                        <KeyDateSystemProvider>
                                                                            <KeyDateProvider>
                                                                                <RegionCountyProvider>
                                                                                    {children}
                                                                                </RegionCountyProvider>
                                                                            </KeyDateProvider>
                                                                        </KeyDateSystemProvider>
                                                                    </KeyDateSystemTypeProvider>
                                                                </UpcomingTransactionProvider>
                                                            </FacilityFileProvider>
                                                        </EmailArchiveProvider>
                                                    </FileTypeProvider>
                                                </ProgramProvider>
                                            </PaymentProvider>
                                        </FilterProvider>
                                    </PhotoProvider>
                                </UserProvider>
                            </TenantProvider>
                        </RoleProvider>
                    </AppSettingsProvider>
                </OidcProvider>
            </HttpProvider>
        </AuthProvider>
    </ToastProvider>
);
