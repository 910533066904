import { User, UserManager } from "oidc-client-ts";
import { PropsWithChildren, useCallback, useEffect, useMemo, useState } from "react";
import jwt_decode from "jwt-decode";
import { USER_MANAGER_SETTINGS } from "../../config/config";
import { DecodedAccessToken, HttpOptionsWithBody } from "../../models";
import { defaultAuthState, OidcContext, useAuth, useHttp } from "..";
import { useContext } from "../../hooks";
import { getErrorMessage } from "../../helper-functions";

export const OidcProvider = (props: PropsWithChildren) => {
    const { canRefresh, refreshToken, setAuthState, shouldLogout } = useAuth();
    const { postUnique } = useHttp();
    const [userManager] = useState(new UserManager(USER_MANAGER_SETTINGS));

    const redirectToLogin = useCallback(() => userManager.signinRedirect(), [userManager]);

    const loginFail = useCallback(async () => {
        setAuthState((prev) => ({
            ...defaultAuthState,
            failedLoginAttempts: prev.failedLoginAttempts + 1,
        }));
        await userManager.signoutRedirect();
    }, [userManager]);

    const logout = useCallback(async () => {
        setAuthState(defaultAuthState);
        await userManager.signoutRedirect();
    }, [userManager]);

    useEffect(() => {
        if (shouldLogout) {
            logout();
        }
    }, [logout, shouldLogout]);

    const login = useCallback(async () => {
        try {
            // throw Error("testing loginFail()");
            setAuthState((prev) => ({ ...prev, isLoggingIn: true }));
            const { access_token, refresh_token } = await userManager.signinRedirectCallback();
            const decodedAccessToken = jwt_decode(access_token) as DecodedAccessToken;
            setAuthState({
                ...defaultAuthState,
                accessToken: access_token,
                decodedAccessToken,
                isAuthenticated: true,
                refreshToken: refresh_token ?? "",
            });
        } catch (error) {
            const { message } = getErrorMessage(error);
            loginFail();
        }
    }, [loginFail, setAuthState, userManager]);

    const refresh = useCallback(async () => {
        if (!refreshToken) return;
        const httpOptions: HttpOptionsWithBody<any> = {
            path: "connect/token",
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
            body: new URLSearchParams({
                client_id: USER_MANAGER_SETTINGS.client_id,
                grant_type: "refresh_token",
                refresh_token: refreshToken,
            }).toString(),
            stringify: false,
        };
        const user = await postUnique<any, User>(httpOptions);
        if (user) {
            const { access_token, refresh_token } = user;
            const { exp, tenant_id, user_id } = jwt_decode(access_token) as DecodedAccessToken;
            setAuthState((prev) => ({
                ...prev,
                accessToken: access_token,
                decodedAccessToken: {
                    ...prev.decodedAccessToken,
                    exp,
                    tenant_id,
                    user_id,
                },
                refreshToken: refresh_token ?? "",
            }));
        }
    }, [postUnique, refreshToken, setAuthState]);

    useEffect(() => {
        if (canRefresh) {
            refresh();
        }
    }, [canRefresh, refresh]);

    const value = useMemo(
        () => ({
            login,
            loginFail,
            logout,
            redirectToLogin,
            refresh,
        }),
        [login, loginFail, logout, redirectToLogin, refresh]
    );

    return <OidcContext.Provider value={value} {...props} />;
};

export const useOidc = () => useContext(OidcContext);
