import { useState, useCallback, useMemo, useEffect } from "react";
import { Col, Form, Row, Modal, Button } from "react-bootstrap";
import { FormProps, Role, SelectOption, User, UserTypes } from "../../models";
import { useUsers, useRoles, useAuth, useTenants } from "../../contexts";
import { ConfirmationModal } from "../../component-library";
import { ImmutableRole } from "../../models/role/ImmutableRole";
import { ROOT_TENANT_ID } from "../../config/config";

interface UserFormModel {
    firstName: string;
    lastName: string;
    emailAddress: string;
    phoneNumber: string;
    isActive: boolean;
    roleId: number;
    tenantId: number;
    mfaOptionId: number;
    mfaEnabled: boolean;
    paymentDatabaseAccess: boolean;
    receiveNotifications: boolean;
}

export const UserForm = ({ onClose, show, type }: FormProps) => {
    const { decodedAccessToken } = useAuth();
    const { tenant_name: claimTenantName, roles: currentUserRole, user_id, qr_code_image } = decodedAccessToken;

    const { roles } = useRoles();
    const { tenants } = useTenants();
    const { createUser, deleteUser, editUser, hasAdminAccess, restoreUser, selectedUser, userType } = useUsers();

    const [isSubmitting, setIsSubmitting] = useState(false);
    const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false);
    const [showConfirmRestoreModal, setShowConfirmRestoreModal] = useState(false);

    const [errors, setErrors] = useState({
        firstName: "",
        lastName: "",
        emailAddress: "",
        phoneNumber: "",
        roleId: "",
        tenantId: "",
        mfaOptionId: "",
    });

    const [formValues, setFormValues] = useState<UserFormModel>({
        firstName: "",
        lastName: "",
        emailAddress: "",
        phoneNumber: "",
        isActive: true,
        roleId: 0,
        tenantId: 0,
        mfaOptionId: 1,
        mfaEnabled: true,
        paymentDatabaseAccess: false,
        receiveNotifications: false,
    });

    useEffect(() => {
        if (!selectedUser) return;
        setFormValues({ ...selectedUser });
    }, [selectedUser]);

    const handleDeleteUser = useCallback(
        async (user: User) => {
            const success = await deleteUser(user);
            if (success) {
                setShowConfirmDeleteModal(false);
                onClose();
            }
        },
        [deleteUser, onClose]
    );

    const handleRestoreUser = useCallback(
        async (user: User) => {
            const success = await restoreUser(user);
            if (success) {
                setShowConfirmRestoreModal(false);
                onClose();
            }
        },
        [restoreUser, onClose]
    );

    const rootTenantId = ROOT_TENANT_ID ? +ROOT_TENANT_ID : 1;

    const tenantOptions = useMemo(() => {
        const systems = tenants.filter((t) => !t.parentTenantId);
        switch (currentUserRole) {
            case "CustomerAdmin":
                return systems.filter((t) => t.name === claimTenantName);
            case "PartnerAdmin":
                return systems.filter((t) => t.id !== rootTenantId);
            default:
                return systems;
        }
    }, [tenants, currentUserRole, claimTenantName, rootTenantId]);

    const getRoleId = useCallback(
        (tenantId: number) => {
            const tenant = tenantId
                ? tenants.filter((t) => t.id === tenantId)[0]
                : tenants.filter((t) => t.name === claimTenantName)[0];

            const role =
                tenant.id === rootTenantId
                    ? ImmutableRole.GlobalAdmin
                    : tenant.parentTenantId
                    ? ImmutableRole.CustomerAdmin
                    : ImmutableRole.PartnerAdmin;

            return roles.filter((r) => r.name === role)[0].id;
        },
        [tenants, roles, rootTenantId]
    );

    const getTenantId = useCallback(() => {
        return tenants.filter((t) => t.name === claimTenantName)[0].id;
    }, [tenants]);

    const getTenantName = useCallback(
        (tenantId: number): string => {
            return tenantId ? tenants.filter((t) => t.id === tenantId)[0].name : claimTenantName;
        },
        [tenants, claimTenantName]
    );

    const isFormValid = useCallback(() => {
        let valid = true;
        const { tenantId, firstName, lastName, emailAddress, phoneNumber, mfaOptionId } = formValues;
        if (tenantId === 0) {
            setErrors((prev) => ({ ...prev, tenantId: "Tenant is required" }));
            valid = false;
        }
        if (!firstName) {
            setErrors((prev) => ({ ...prev, firstName: "First Name is required" }));
            valid = false;
        }
        if (firstName.length > 50) {
            setErrors((prev) => ({ ...prev, firstName: "First Name must be 50 characters or less" }));
            valid = false;
        }
        if (!lastName) {
            setErrors((prev) => ({ ...prev, lastName: "Last Name is required" }));
            valid = false;
        }
        if (lastName.length > 50) {
            setErrors((prev) => ({ ...prev, lastName: "Last Name must be 50 characters or less" }));
            valid = false;
        }
        if (!emailAddress) {
            setErrors((prev) => ({ ...prev, emailAddress: "Email is required" }));
            valid = false;
        }
        if (emailAddress.length > 100) {
            setErrors((prev) => ({ ...prev, emailAddress: "Email must be 100 characters or less" }));
            valid = false;
        }
        if (!!phoneNumber && phoneNumber.length < 10) {
            setErrors((prev) => ({ ...prev, phoneNumber: "Phone Number must be 10 characters" }));
            valid = false;
        }
        if (mfaOptionId === 2 && !phoneNumber) {
            setErrors((prev) => ({ ...prev, phoneNumber: "Phone Number is required for SMS MFA Type" }));
            valid = false;
        }
        return valid;
    }, [formValues]);

    const handleSubmit = useCallback(async () => {
        setErrors({
            firstName: "",
            lastName: "",
            emailAddress: "",
            phoneNumber: "",
            roleId: "",
            tenantId: "",
            mfaOptionId: "",
        });
        if (!isFormValid()) return;
        setIsSubmitting(true);
        let success = false;
        const { tenantId } = formValues;
        if (type === "add") {
            success = await createUser({
                ...formValues,
                id: 0,
                roleId: getRoleId(tenantId),
                tenantId: currentUserRole === "CustomerAdmin" ? getTenantId() : tenantId,
                tenantName: getTenantName(tenantId),
                mfaSetupComplete: false,
            });
        } else {
            if (!selectedUser) return;
            success = await editUser({
                original: selectedUser,
                updated: {
                    ...selectedUser,
                    ...formValues,
                    roleId: getRoleId(tenantId),
                    tenantId: currentUserRole === "CustomerAdmin" ? getTenantId() : tenantId,
                    tenantName: getTenantName(tenantId),
                },
            });
        }
        if (success) {
            onClose();
        } else {
            setIsSubmitting(false);
        }
    }, [createUser, editUser, type, selectedUser, onClose, getRoleId, getTenantId, getTenantName, isFormValid]);

    return (
        <>
            <Modal show={show} backdrop="static" keyboard={false} size="lg">
                <Modal.Header>{type === "add" ? "New User" : "Edit User"}</Modal.Header>
                <Modal.Body>
                    <Form id="userForm">
                        <Row>
                            <Col sm={4}>
                                {currentUserRole === "CustomerAdmin" ? (
                                    <h3>{claimTenantName}</h3>
                                ) : (
                                    <Form.Group controlId="tenantId" className="mb-2">
                                        <Form.Label>System/Facility</Form.Label>
                                        <Form.Select
                                            value={formValues.tenantId}
                                            onChange={(e) =>
                                                setFormValues((prev) => ({ ...prev, tenantId: +e.target.value }))
                                            }
                                            isInvalid={!!errors.tenantId}
                                            disabled={type === "edit"}
                                        >
                                            <option disabled value={0}>
                                                Select a System/Facility
                                            </option>
                                            {tenantOptions.map((t) => (
                                                <option key={t.id} value={t.id}>
                                                    {t.name}
                                                </option>
                                            ))}
                                        </Form.Select>
                                        <Form.Control.Feedback type="invalid">{errors.tenantId}</Form.Control.Feedback>
                                    </Form.Group>
                                )}
                            </Col>
                            <Col sm={4}>
                                <Form.Group controlId="firstName" className="mb-2">
                                    <Form.Label>First Name</Form.Label>
                                    <Form.Control
                                        value={formValues.firstName}
                                        onChange={(e) =>
                                            setFormValues((prev) => ({ ...prev, firstName: e.target.value }))
                                        }
                                        isInvalid={!!errors.firstName}
                                    />
                                    <Form.Control.Feedback type="invalid">{errors.firstName}</Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                            <Col sm={4}>
                                <Form.Group controlId="lastName" className="mb-2">
                                    <Form.Label>Last Name</Form.Label>
                                    <Form.Control
                                        value={formValues.lastName}
                                        onChange={(e) =>
                                            setFormValues((prev) => ({ ...prev, lastName: e.target.value }))
                                        }
                                        isInvalid={!!errors.lastName}
                                    />
                                    <Form.Control.Feedback type="invalid">{errors.lastName}</Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                            <Col sm={6}>
                                <Form.Group controlId="email" className="mb-2">
                                    <Form.Label>Email</Form.Label>
                                    <Form.Control
                                        value={formValues.emailAddress}
                                        onChange={(e) =>
                                            setFormValues((prev) => ({ ...prev, emailAddress: e.target.value }))
                                        }
                                        isInvalid={!!errors.emailAddress}
                                    />
                                    <Form.Control.Feedback type="invalid">{errors.emailAddress}</Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                            <Col sm={6}>
                                <Form.Group controlId="phone" className="mb-2">
                                    <Form.Label>Phone Number</Form.Label>
                                    <Form.Control
                                        value={formValues.phoneNumber}
                                        onChange={(e) =>
                                            setFormValues((prev) => ({ ...prev, phoneNumber: e.target.value }))
                                        }
                                        isInvalid={!!errors.phoneNumber}
                                    />
                                    <Form.Control.Feedback type="invalid">{errors.phoneNumber}</Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                            {currentUserRole === ImmutableRole.GlobalAdmin && (
                                <Col sm={4}>
                                    <Form.Group controlId="tenantId" className="mb-2">
                                        <Form.Label>Payment Database Access</Form.Label>
                                        <Form.Select
                                            value={Number(formValues.paymentDatabaseAccess)}
                                            onChange={(e) =>
                                                setFormValues((prev) => ({
                                                    ...prev,
                                                    paymentDatabaseAccess: Boolean(+e.target.value),
                                                }))
                                            }
                                        >
                                            <option value={0}>No</option>
                                            <option value={1}>Yes</option>
                                        </Form.Select>
                                    </Form.Group>
                                </Col>
                            )}
                            {hasAdminAccess && (
                                <Col sm={4}>
                                    <Form.Group controlId="receiveNotifications" className="mb-2">
                                        <Form.Label>Receive Notifications</Form.Label>
                                        <Form.Select
                                            value={Number(formValues.receiveNotifications)}
                                            onChange={(e) =>
                                                setFormValues((prev) => ({
                                                    ...prev,
                                                    receiveNotifications: Boolean(+e.target.value),
                                                }))
                                            }
                                        >
                                            <option value={0}>No</option>
                                            <option value={1}>Yes</option>
                                        </Form.Select>
                                    </Form.Group>
                                </Col>
                            )}

                            {currentUserRole === ImmutableRole.GlobalAdmin && (
                                <Col sm={4}>
                                    <Form.Group controlId="tenantId" className="mb-2">
                                        <Form.Label>Enable MFA</Form.Label>
                                        <Form.Select
                                            value={Number(formValues.mfaEnabled)}
                                            onChange={(e) =>
                                                setFormValues((prev) => ({
                                                    ...prev,
                                                    mfaEnabled: Boolean(+e.target.value),
                                                }))
                                            }
                                        >
                                            <option value={0}>No</option>
                                            <option value={1}>Yes</option>
                                        </Form.Select>
                                    </Form.Group>
                                </Col>
                            )}
                            {formValues.mfaEnabled && selectedUser?.id === +user_id && (
                                <>
                                    <Col sm={4}>
                                        <Form.Group controlId="tenantId" className="mb-2">
                                            <Form.Label>MFA Options</Form.Label>
                                            <Form.Select
                                                value={formValues.mfaOptionId}
                                                onChange={(e) =>
                                                    setFormValues((prev) => ({ ...prev, mfaOptionId: +e.target.value }))
                                                }
                                            >
                                                <option value={1}>Email</option>
                                                <option value={2}>SMS</option>
                                                <option value={3}>Authenticator App</option>
                                            </Form.Select>
                                        </Form.Group>
                                    </Col>
                                </>
                            )}
                        </Row>
                        {formValues.mfaOptionId === 2 && (
                            <Row className="mt-2">
                                <Col className="d-flex justify-content-center">
                                    <strong>
                                        By clicking Save, you are opting in to receive MFA one-time passcode text
                                        messages from AHCV App.
                                        <br />
                                        The AHCV App cannot be accessed without this code.
                                        <br />
                                        Carrier Message and Data rates may apply.
                                    </strong>
                                </Col>
                            </Row>
                        )}
                        {formValues.mfaOptionId === 3 && (
                            <>
                                <Row className="mt-2 mb-4">
                                    <Col className="d-flex justify-content-center">
                                        <strong>
                                            Please download either Google Authenticator or Microsoft Authenticator.
                                            <br />
                                            Open the app and scan the code below to set up MFA.
                                        </strong>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className="d-flex justify-content-center">
                                        <img src={qr_code_image} />
                                    </Col>
                                </Row>
                            </>
                        )}
                    </Form>
                </Modal.Body>
                <Modal.Footer className="d-flex justify-content-between">
                    <Button variant="secondary" onClick={onClose}>
                        Cancel
                    </Button>
                    <div>
                        <Row>
                            <Col>
                                {type === "edit" && selectedUser?.isActive && (
                                    <Button variant="danger" onClick={() => setShowConfirmDeleteModal(true)}>
                                        Deactivate
                                    </Button>
                                )}
                                {type === "edit" && !selectedUser?.isActive && (
                                    <Button
                                        variant="success"
                                        onClick={() => setShowConfirmRestoreModal(true)}
                                        className="ms-2"
                                    >
                                        Activate
                                    </Button>
                                )}
                            </Col>
                            <Col>
                                {(!selectedUser || selectedUser?.isActive) && (
                                    <Button
                                        variant="primary"
                                        onClick={handleSubmit}
                                        className="ms-2"
                                        disabled={isSubmitting}
                                    >
                                        {isSubmitting ? "Saving..." : "Save"}
                                    </Button>
                                )}
                            </Col>
                        </Row>
                    </div>
                </Modal.Footer>
            </Modal>

            {selectedUser && (
                <>
                    <ConfirmationModal
                        id="confirmDeleteUserModal"
                        show={showConfirmDeleteModal}
                        title="Deactivate User?"
                        message={"Do you really want to deactivate this user?"}
                        onCloseButtonClick={() => setShowConfirmDeleteModal(false)}
                        onOkButtonClick={() => handleDeleteUser(selectedUser)}
                        theme={"danger"}
                    />
                    <ConfirmationModal
                        id="confirmRestoreUserModal"
                        show={showConfirmRestoreModal}
                        title="Activate User?"
                        message={"Do you really want to activate this user?"}
                        onCloseButtonClick={() => setShowConfirmRestoreModal(false)}
                        onOkButtonClick={() => handleRestoreUser(selectedUser)}
                        theme={"danger"}
                    />
                </>
            )}
        </>
    );
};
