import { Route, Routes } from "react-router-dom";
import _ from "lodash";
import { useEffect } from "react";
import { useAuth, useOidc, useToasts, useUsers } from "../../contexts";
import Header from "../header/Header";
import { KeysysToast } from "../../component-library";
import { UserTypes } from "../../models";
import AppSettings from "../app-settings/AppSettings";
import Users from "../users/Users";
import { Tenants } from "../tenants/Tenants";
import { Facilities } from "../facilities/Facilities";
import { Portal } from "../portal/Portal";
import { FileUpload } from "../dropzone/FileUpload";
import FacilityFiles from "../file-manager/FacilityFiles";
import KeyDates from "../key-date/KeyDates";
import { LegalDisclaimer } from "../legal-disclaimer/LegalDisclaimer";

export default function App() {
    const { isAuthorized, shouldLogin, decodedAccessToken } = useAuth();
    const isAdmin = decodedAccessToken.roles === "GlobalAdmin";
    const hasPaymentDatabaseAccess = isAdmin || decodedAccessToken.payment_database_access === "True";
    const { redirectToLogin } = useOidc();
    const { activeToasts } = useToasts();
    const { userType } = useUsers();

    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);
    window.addEventListener("resize", () => {
        vh = window.innerHeight * 0.01;
        debouncedSetViewportHeight(vh);
    });
    const debouncedSetViewportHeight = _.debounce((vh: number) => {
        document.documentElement.style.setProperty("--vh", `${vh}px`);
    }, 100);

    useEffect(() => {
        if (shouldLogin) {
            redirectToLogin();
        }
    }, [shouldLogin, redirectToLogin]);

    if (!isAuthorized) {
        return null;
    }

    return (
        <>
            {!!activeToasts &&
                activeToasts.length > 0 &&
                activeToasts.map((toast, index) => <KeysysToast key={`${toast.name}-${index}`} {...toast} />)}
            <Header />
            <div className="main-content">
                <Routes>
                    {userType === UserTypes.GlobalAdmin && (
                        <>
                            <Route path="/app-settings" element={<AppSettings />} />
                        </>
                    )}
                    <Route path="/portal" element={<Portal />} />
                    <Route path="/file-upload" element={isAdmin ? <FileUpload /> : <Portal />} />
                    <Route
                        path="/payments-dashboard"
                        element={hasPaymentDatabaseAccess ? <Facilities /> : <Portal />}
                    />
                    <Route path="/tenants" element={<Tenants />} />
                    <Route path="/Facilities" element={hasPaymentDatabaseAccess ? <Facilities /> : <Portal />} />
                    <Route path="/file-manager" element={isAdmin ? <FacilityFiles /> : <Portal />} />
                    <Route path="/key-dates" element={isAdmin ? <KeyDates /> : <Portal />} />
                    <Route path="/users" element={<Users />} />
                    <Route path="/legal-disclaimer" element={<LegalDisclaimer />} />
                    <Route index element={<Portal />} />
                </Routes>
            </div>
        </>
    );
}
