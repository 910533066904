import { useState } from "react";
import { ErrorToast } from "../component-library";
import { useToasts } from "../contexts";
import { getErrorMessage } from "../helper-functions";
import { KeyValue } from "../models";

export const useLocalStorage = <T>({ key, value }: KeyValue<T>) => {
    const { showToast } = useToasts();
    const storageAvailable = () => {
        let storage: Storage | null = null;
        try {
            storage = window["localStorage"];
            var x = "__storage_test__";
            storage.setItem(x, x);
            storage.removeItem(x);
            return true;
        } catch (e) {
            return (
                e instanceof DOMException &&
                // everything except Firefox
                (e.code === 22 ||
                    // Firefox
                    e.code === 1014 ||
                    // test name field too, because code might not be present
                    // everything except Firefox
                    e.name === "QuotaExceededError" ||
                    // Firefox
                    e.name === "NS_ERROR_DOM_QUOTA_REACHED") &&
                // acknowledge QuotaExceededError only if there's something already stored
                storage &&
                storage.length !== 0
            );
        }
    };

    const [storedValue, setStoredValue] = useState<T>(() => {
        if (typeof window === "undefined" || !storageAvailable()) return value;

        try {
            const item = window.localStorage.getItem(key);
            return item ? JSON.parse(item) : value;
        } catch (error) {
            const { message } = getErrorMessage(error);
            showToast(ErrorToast(message));
            return value;
        }
    });

    const setValue = (value: T | ((val: T) => T)) => {
        try {
            const valueToStore = value instanceof Function ? value(storedValue) : value;
            setStoredValue(valueToStore);
            if (typeof window !== "undefined") {
                window.localStorage.setItem(key, JSON.stringify(valueToStore));
            }
        } catch (error) {
            const { message } = getErrorMessage(error);
            showToast(ErrorToast(message));
        }
    };

    return [storedValue, setValue] as const;
};
