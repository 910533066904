export const ADD_CONTACT_SUCCESS = "ADD_CONTACT_SUCCESS";
export const EDIT_CONTACT_SUCCESS = "EDIT_CONTACT_SUCCESS";
export const DELETE_CONTACT_SUCCESS = "DELETE_CONTACT_SUCCESS";
export const RESTORE_CONTACT_SUCCESS = "RESTORE_CONTACT_SUCCESS";

export const ADD_ROLE_SUCCESS = "ADD_ROLE_SUCCESS";
export const EDIT_ROLE_SUCCESS = "EDIT_ROLE_SUCCESS";
export const DELETE_ROLE_SUCCESS = "DELETE_ROLE_SUCCESS";
export const RESTORE_ROLE_SUCCESS = "RESTORE_ROLE_SUCCESS";

export const ADD_USER_SUCCESS = "ADD_USER_SUCCESS";
export const EDIT_USER_SUCCESS = "EDIT_USER_SUCCESS";
export const DELETE_USER_SUCCESS = "DELETE_USER_SUCCESS";
export const RESTORE_USER_SUCCESS = "RESTORE_USER_SUCCESS";

export const ADD_TENANT_SUCCESS = "ADD_TENANT_SUCCESS";
export const EDIT_TENANT_SUCCESS = "EDIT_TENANT_SUCCESS";
export const DEACTIVATE_TENANT_SUCCESS = "DEACTIVATE_TENANT_SUCCESS";

export const ADD_REPORT_SUCCESS = "ADD_REPORT_SUCCESS";
export const EDIT_REPORT_SUCCESS = "EDIT_REPORT_SUCCESS";
export const DEACTIVATE_REPORT_SUCCESS = "DEACTIVATE_REPORT_SUCCESS";

export const ADD_SUB_REPORT_SUCCESS = "ADD_SUB_REPORT_SUCCESS";
export const EDIT_SUB_REPORT_SUCCESS = "EDIT_SUB_REPORT_SUCCESS";
export const DEACTIVATE_SUB_REPORT_SUCCESS = "DEACTIVATE_SUB_REPORT_SUCCESS";

export const ADD_SUB_REPORT_ATTRIBUTE_SUCCESS = "ADD_SUB_REPORT_ATTRIBUTE_SUCCESS";
export const EDIT_SUB_REPORT_ATTRIBUTE_SUCCESS = "EDIT_SUB_REPORT_ATTRIBUTE_SUCCESS";
export const DEACTIVATE_SUB_REPORT_ATTRIBUTE_SUCCESS = "DEACTIVATE_SUB_REPORT_ATTRIBUTE_SUCCESS";

export const ADD_CUSTOMER_IMPORT_SUCCESS = "ADD_CUSTOMER_IMPORT_SUCCESS";
export const EDIT_CUSTOMER_IMPORT_SUCCESS = "EDIT_CUSTOMER_IMPORT_SUCCESS";
export const DEACTIVATE_CUSTOMER_IMPORT_SUCCESS = "DEACTIVATE_CUSTOMER_IMPORT_SUCCESS";

export const ADD_CUSTOMER_IMPORT_FILE_SUCCESS = "ADD_CUSTOMER_IMPORT_FILE_SUCCESS";
export const EDIT_CUSTOMER_IMPORT_FILE_SUCCESS = "EDIT_CUSTOMER_IMPORT_FILE_SUCCESS";
export const DEACTIVATE_CUSTOMER_IMPORT_FILE_SUCCESS = "DEACTIVATE_CUSTOMER_IMPORT_FILE_SUCCESS";

export const ADD_SYSTEM_SUCCESS = "ADD_SYSTEM_SUCCESS";

export const ERROR = "ERROR";
