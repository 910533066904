import { createContext } from "react";
import { KeyDateSystem } from "../../models/key-date-system/KeyDateSystem";

export interface KeyDateSystemState {
    keyDateSystems: KeyDateSystem[];
    selectedKeyDateSystem?: KeyDateSystem;
}

interface IKeyDateSystemContext extends KeyDateSystemState {
    getAllKeyDateSystems: () => Promise<void>;
    upsertKeyDateSystems: (keyDateSystems: KeyDateSystem[]) => Promise<boolean>;
}

export const KeyDateSystemContext = createContext<IKeyDateSystemContext | undefined>(undefined);
KeyDateSystemContext.displayName = "KeyDateSystemContext";
