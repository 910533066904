import { PropsWithChildren, useCallback, useMemo, useState } from "react";
import { KeysysToastProps } from "../../component-library/toast/KeysysToastProps";
import { useContext } from "../../hooks";
import { ToastContext } from "./ToastContext";

export const ToastProvider = (props: PropsWithChildren) => {
    const [activeToasts, setActiveToasts] = useState<KeysysToastProps[]>([]);

    const showToast = useCallback((toastProps: KeysysToastProps) => {
        setActiveToasts((current) => [...current, toastProps]);
    }, []);

    const hideToast = useCallback((name: string) => {
        setActiveToasts((current) => [...current.filter((t) => t.name !== name)]);
    }, []);

    const value = useMemo(
        () => ({
            activeToasts,
            showToast,
            hideToast,
        }),
        [activeToasts, showToast, hideToast]
    );
    
    return <ToastContext.Provider value={value} {...props} />;
};

export const useToasts = () => useContext(ToastContext);
