import { PropsWithChildren, useCallback, useMemo, useState } from "react";
import { useContext } from "../../hooks";
import { HttpOptions } from "../../models";
import { Payment } from "../../models/payment/Payment";
import { Filter } from "../../models/filter/Filter";
import { useHttp } from "../http";
import { PaymentState, PaymentContext } from "./PaymentContext";
import axios from "axios";
import { useAuth } from "../auth";
import { API_BASE_URL } from "../../config/config";

export const initialPaymentState: PaymentState = {
    payments: [],
    yoyPayments: [],
};

export const PaymentProvider = (props: PropsWithChildren) => {
    const { postUnique } = useHttp();
    const { accessToken } = useAuth();
    const [httpOptions] = useState<HttpOptions>({ path: "actions/filter" });
    const [payments, setPayments] = useState<PaymentState>(initialPaymentState);

    const getPayments = useCallback(
        async (filter: Filter) => {
            const payments = await postUnique<Filter, Payment[]>({
                ...httpOptions,
                body: filter,
            });

            if (!payments) return;

            if (filter.year > 0) {
                const filteredPayments = payments.filter((p) => p.year === filter.year);
                setPayments((prev) => ({ ...prev, payments: filteredPayments, yoyPayments: payments }));
            } else {
                var currentYear = payments.reduce((a, v) => (a = a > v.year ? a : v.year), 0);
                var previousYear = currentYear - 1;
                var yoyPayments = payments.filter((p) => p.year === currentYear || p.year === previousYear);
                setPayments((prev) => ({ ...prev, payments: payments, yoyPayments: yoyPayments }));
            }
        },
        [httpOptions, postUnique]
    );

    const downloadPayments = useCallback(
        (filter: Filter) => {
            axios({
                method: "post",
                url: `${API_BASE_URL}actions/download`,
                data: filter,
                responseType: "blob",
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            }).then((response) => {
                if (response) return response;
            });
        },
        [accessToken]
    );

    const value = useMemo(
        () => ({ ...payments, getPayments, downloadPayments }),
        [payments, getPayments, downloadPayments]
    );
    return <PaymentContext.Provider value={value} {...props} />;
};

export const usePayments = () => useContext(PaymentContext);
