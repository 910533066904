import { PropsWithChildren, useCallback, useMemo } from "react";
import { useStoredState, useContext } from "../../hooks";
import { UpcomingTransaction } from "../../models/upcomingTransaction/UpcomingTransaction";
import { useHttp } from "../http";
import { UpcomingTransactionContext, UpcomingTransactionState } from "./UpcomingTransactionContext";

export const initialUpcomingTransactionState: UpcomingTransactionState = {
    upcomingTransactions: [],
};

export const UpcomingTransactionProvider = (props: PropsWithChildren) => {
    const { get } = useHttp();
    const [upcomingTransactionState, setUpcomingTransactionState] = useStoredState(
        "upcomingTransaction",
        initialUpcomingTransactionState
    );

    const getUpcomingTransactions = useCallback(
        async (tenantId: number) => {
            const upcomingTransactions = await get<UpcomingTransaction[]>({
                path: `upcoming-transaction/tenant/${tenantId}`,
            });
            if (!upcomingTransactions) return;
            setUpcomingTransactionState((prev) => ({ ...prev, upcomingTransactions }));
        },
        [get, setUpcomingTransactionState]
    );

    const value = useMemo(
        () => ({ ...upcomingTransactionState, getUpcomingTransactions }),
        [getUpcomingTransactions, upcomingTransactionState]
    );

    return <UpcomingTransactionContext.Provider value={value} {...props} />;
};

export const useUpcomingTransactions = () => useContext(UpcomingTransactionContext);
