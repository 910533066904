import { createContext } from "react";
import { Operable } from "../../models";
import { FacilityFile } from "../../models/facility-file/FacilityFile";

export interface FacilityFileState {
    facilityFiles: FacilityFile[];
    selectedFile?: FacilityFile;
}

interface IFacilityFileContext extends FacilityFileState {
    getAllFacilityFiles: () => Promise<void>;
    getFacilityFiles: (tenantId: number) => Promise<void>;
    getFacilityFilesBySystemId: (systemId: number) => Promise<void>;
    createFacilityFiles: (facilityFiles: FacilityFile[]) => Promise<boolean>;
    createFacilityFile: (facilityFile: FacilityFile) => Promise<boolean>;
    editFacilityFile: (facilityFile: Operable<FacilityFile>) => Promise<boolean>;
    deleteFacilityFile: (facilityFile: Operable<FacilityFile>) => Promise<boolean>;
    downloadFacilityFileFromS3: (awsFileId: string, fileName: string) => Promise<void>;
    selectFile: (facilityFile: FacilityFile) => void;
    clearSelectedFile: () => void;
}

export const FacilityFileContext = createContext<IFacilityFileContext | undefined>(undefined);
FacilityFileContext.displayName = "FacilityFile";
