import { PropsWithChildren, useCallback, useMemo } from "react";
import { Role } from "../../models";
import { useContext, useStoredState } from "../../hooks";
import { RoleContext, RoleState, useHttp } from "..";

export const RoleProvider = (props: PropsWithChildren) => {
    const { get } = useHttp();
    const [roleState, setRoleState] = useStoredState<RoleState>("roles", { roles: [] });

    const getRoles = useCallback(
        async () => {
            const roles = await get<Role[]>({ path: "roles" });
            if (roles) {
                setRoleState({ roles });
            }
        },
        [get]
    );

    const value = useMemo(
        () => ({
            getRoles,
            ...roleState,
        }),
        [getRoles, roleState]
    );

    return <RoleContext.Provider value={value} {...props} />;
};

export const useRoles = () => useContext(RoleContext);