import { createContext } from "react";
import { Operable } from "../../models";
import { KeyDate } from "../../models/key-date/KeyDate";

export interface KeyDateState {
    keyDates: KeyDate[];
    selectedKeyDate?: KeyDate;
}

interface IKeyDateContext extends KeyDateState {
    getAllKeyDates: () => Promise<void>;
    getAllUpcomingKeyDates: () => Promise<void>;
    createKeyDate: (keyDate: KeyDate) => Promise<KeyDate | void>;
    editKeyDate: (keyDate: Operable<KeyDate>) => Promise<boolean>;
    selectKeyDate: (keyDate: KeyDate) => void;
    clearSelectedKeyDate: () => void;
}

export const KeyDateContext = createContext<IKeyDateContext | undefined>(undefined);
KeyDateContext.displayName = "KeyDateContext";
