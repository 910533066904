import { useCallback } from "react";
import Alert from "react-bootstrap/esm/Alert";
import Button from "react-bootstrap/esm/Button";
import Modal from "react-bootstrap/esm/Modal";
import { useOidc } from "../../contexts";

export interface LogoutModalProps {
    show: boolean;
    onHide?: VoidFunction;
}

export const LogoutModal = ({ show, onHide }: LogoutModalProps) => {
    const { logout } = useOidc();
    const handleLogout = useCallback(
        () => {
            if (onHide) {
                onHide();
            }
            logout();
        },
        [logout]
    );
    return (
        <Modal size="sm" show={show} onHide={onHide} centered>
            <Modal.Body as={Alert} className="fw-bold m-0">
                <p>Ready to log out?</p>
                <div className="d-flex justify-content-between mt-2">
                    <Button variant="dark" onClick={onHide}>
                        Cancel
                    </Button>
                    <Button variant="primary" onClick={handleLogout}>
                        Log Out
                    </Button>
                </div>
            </Modal.Body>
        </Modal>
    );
};