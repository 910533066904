export const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
export const SSO_URL = process.env.REACT_APP_SSO_URL;
export const SSO_REDIRECT_URL = process.env.REACT_APP_SSO_REDIRECT_URL;
export const SSO_POST_LOGOUT_URL = process.env.REACT_APP_SSO_POST_LOGOUT_URL;
export const AWS_REGION = process.env.REACT_APP_AWS_REGION;
export const BUCKET_NAME = process.env.REACT_APP_BUCKET_NAME;
export const S3_URL = process.env.REACT_APP_S3_URL;
export const ROOT_TENANT_ID = process.env.REACT_APP_ROOT_TENANT_ID;

export const USER_MANAGER_SETTINGS = {
    authority: SSO_URL!,
    automaticSilentRenew: false,
    client_id: "AhcvIdentity",
    redirect_uri: SSO_REDIRECT_URL!,
    response_type: "code",
    scope: "AhcvIdentityApi offline_access",
    post_logout_redirect_uri: SSO_POST_LOGOUT_URL,
} as const;

export const AWS_CONFIG = {
    bucketName: BUCKET_NAME!,
    region: AWS_REGION!,
    s3Url: S3_URL!,
};
