import _ from "lodash";
import { useMemo } from "react";
import { Row, Col, Button } from "react-bootstrap";
import { AreaChart, CartesianGrid, XAxis, YAxis, Tooltip, Area, ResponsiveContainer } from "recharts";
import { usePayments, useTenants, useAuth } from "../../contexts";
import { Filter } from "../../models";
import axios from "axios";
import { API_BASE_URL } from "../../config/config";
import { formatBigCurrency } from "../../helper-functions/formatBigCurrency";

interface IMedicaidSuppPaymentProgramRevenue {
    facilityIdState: number;
}

export function MedicaidSuppPaymentProgramRevenue({ facilityIdState }: IMedicaidSuppPaymentProgramRevenue) {
    const { tenants } = useTenants();
    const { payments } = usePayments();
    const { accessToken } = useAuth();
    const currentYear = new Date().getFullYear() - 1;
    const previousYear = currentYear - 1;

    const yearGrouped = useMemo(() => _.groupBy(payments, (p) => p.year), [payments]);
    const yearlyData = useMemo(() => {
        let result = Object.entries(yearGrouped).map(([key, value]) => ({
            name: value[0].year,
            amt: value.reduce((a, v) => (a = a + v.amount), 0),
        }));
        if (result.length === 1) {
            result.push({ name: result[0].name + 1, amt: 0 });
            result.unshift({ name: result[0].name - 1, amt: 0 });
        }
        return result;
    }, [yearGrouped]);

    const total = payments.reduce((a, v) => (a = a + v.amount), 0);
    const currentYearTotal = yearlyData.find((yd) => yd.name === currentYear)
        ? yearlyData.find((yd) => yd.name === currentYear)?.amt
        : 0;
    const previousYearTotal = yearlyData.find((yd) => yd.name === previousYear)
        ? yearlyData.find((yd) => yd.name === previousYear)?.amt
        : 0;
    const minYear = Math.min(...payments.map((p) => p.year));

    const change = ((currentYearTotal! - previousYearTotal!) / previousYearTotal!) * 100;

    const CustomTooltip = ({ active, payload, label }: any) => {
        if (active && payload && payload.length) {
            return (
                <div className="custom-tooltip">
                    <p className="label">{`${label} : $${payload[0].value.toLocaleString(undefined, {
                        maximumFractionDigits: 0,
                    })}`}</p>
                </div>
            );
        }
        return null;
    };

    const getFacilityName = () => {
        if (facilityIdState === 0) return "All Locations";

        return tenants.find((t) => t.id === facilityIdState)?.name;
    };

    const isParentTenant = tenants.find((t) => t.id === facilityIdState)?.parentTenantId === null;

    const exportReport = function () {
        let filter: Filter = {
            stateId: 0,
            parentTenantId: isParentTenant ? facilityIdState : 0,
            tenantId: isParentTenant ? 0 : facilityIdState,
            programId: 0,
            year: 0,
            county: "",
            region: "",
            paymentDbAccess: false,
        };
        axios({
            method: "post",
            url: `${API_BASE_URL}actions/download`,
            data: filter,
            responseType: "blob",
            headers: {
                Authorization: `Bearer ${accessToken}`,
            },
        }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");

            link.href = url;
            link.setAttribute("download", `AhcvPayments_${Date.now()}.xlsx`);
            document.body.appendChild(link);
            link.click();
        });
    };

    return (
        <div className="chart-border h-100 d-flex flex-column justify-content-stretch">
            <h2 className="modal-header">Medicaid Supplemental Payment Program Revenue</h2>
            <Row className="mcs-chart-container chart-width">
                <Col lg={4}>
                    <Row className="mcs-blue-chart-border mcs">
                        <div className="mcs-value">
                            {change > 0 && <i className="fas fa-arrow-circle-up" />}
                            {change < 0 && <i className="fas fa-arrow-circle-down" />}
                            {`  ${change?.toLocaleString(undefined, { maximumFractionDigits: 2 })}%`}
                        </div>
                        <div className="mcs-value-title">over prior year</div>
                    </Row>
                    <Row className="mcs-blue-chart-border mcs">
                        <div className="mcs-value">
                            <i className="fas fa-dollar-sign" />

                            {`${currentYearTotal?.toLocaleString(undefined, {
                                maximumFractionDigits: 0,
                            })}`}
                        </div>
                        <div className="mcs-value-title">
                            <i className="fas fa-circle blue" /> {currentYear}
                        </div>
                    </Row>
                    <Row className="mcs-blue-chart-border mcs">
                        <div className="mcs-value">
                            <i className="fas fa-dollar-sign" />
                            {`${previousYearTotal?.toLocaleString(undefined, {
                                maximumFractionDigits: 0,
                            })}`}
                        </div>

                        <div className="mcs-value-title">
                            <i className="fas fa-circle red" /> {previousYear}
                        </div>
                    </Row>
                    <Row className="mcs-red-chart-border mcs">
                        <div className="mcs-value">
                            {`$${total?.toLocaleString(undefined, { maximumFractionDigits: 0 })}`}
                        </div>
                        <div className="mcs-value-title">{`${minYear} - ${currentYear}`}</div>
                    </Row>
                </Col>
                <Col lg={8}>
                    <Row className="d-flex justify-content-between">
                        <Col sm="auto">
                            <h3>{getFacilityName()}</h3>
                        </Col>
                        <Col sm="auto">
                            <Button className="margin" onClick={() => exportReport()}>
                                <i className="fas fa-file-export me-2" /> Export CSV
                            </Button>
                        </Col>
                    </Row>
                    <Row className="text-end">
                        <ResponsiveContainer height={325}>
                            <AreaChart
                                width={600}
                                height={325}
                                data={yearlyData}
                                margin={{
                                    top: 10,
                                    left: 30,
                                    right: 30,
                                    bottom: 0,
                                }}
                            >
                                <CartesianGrid strokeDasharray="3 3" />
                                <defs>
                                    <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                                        <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8} />
                                        <stop offset="95%" stopColor="#8884d8" stopOpacity={0} />
                                    </linearGradient>
                                </defs>
                                <XAxis dataKey="name" />
                                <YAxis tickFormatter={formatBigCurrency} />
                                <Tooltip content={<CustomTooltip />} />
                                <Area
                                    type="monotone"
                                    dataKey="amt"
                                    stroke="#8884d8"
                                    fillOpacity={1}
                                    fill="url(#colorUv)"
                                />
                            </AreaChart>
                        </ResponsiveContainer>
                    </Row>
                </Col>
            </Row>
        </div>
    );
}
