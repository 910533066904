import React from "react";

const AccessDeniedMessage = () => {
    return (
        <div className="access-denied-message d-flex align-items-center justify-content-center">
            <i className="fas fa-exclamation-triangle mr-2"></i>
            <p>You do not have permission to view this page.</p>
            If you belive this is an error, please contact our support team at webinfo@ahcv.com.
        </div>
    );
};

export default AccessDeniedMessage;
