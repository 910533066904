const invalidFileExtensions = [
    "exe",
    "bat",
    "dll",
    "swf",
    "jar",
    "zip",
    "rar",
    "iso",
    "msi",
    "ps1",
    "ps2",
    "cmd",
    "inf",
];

export const isValidFileExtension = (filename: string) => {
    const extensionSplit = filename.split(".");
    const extension = extensionSplit[extensionSplit.length - 1].toLowerCase();
    if (invalidFileExtensions.includes(extension)) {
        return false;
    }
    return true;
};
