import axios from "axios";
import { PreSignedUrlType } from "../models/pre-signed-url/PreSignedUrlType";
import { API_BASE_URL } from "../config/config";

export const getPreSignedUrl = async (
    accessToken: string,
    filename: string,
    bucketName: string,
    preSignedUrlType: PreSignedUrlType
) => {
    const preSignedUrlModel = {
        Filename: filename,
        BucketName: bucketName,
        PreSignedUrlType: preSignedUrlType,
    };
    const response = await axios.post(`${API_BASE_URL}pre-signed-url`, preSignedUrlModel, {
        headers: {
            Authorization: `Bearer ${accessToken}`,
        },
    });
    return response.data;
};

export async function uploadFileToS3WithPreSignedUrl(file: File, preSignedUrl: any): Promise<number> {
    try {
        const config = {
            headers: {
                "Content-Type": "application/octet-stream",
            },
        };
        var response = await axios.put(preSignedUrl, file, config);
        console.log("File successfully uploaded");
        return response.status;
    } catch (error) {
        console.error("Error uploading file:", error);
        return 500;
    }
}

export async function downloadFileFromS3WithPreSignedUrl(filename: string, preSignedUrl: string): Promise<void> {
    try {
        const link = document.createElement("a");
        link.href = preSignedUrl;
        link.setAttribute("download", filename);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    } catch (error) {
        console.error("Error downloading file:", error);
    }
}
