import { ChangeEvent, useCallback, useEffect, useMemo, useState } from "react";
import { Col, Form, Row, Modal, Button } from "react-bootstrap";
import { ConfirmationModal } from "../../component-library";
import { useAppSettings, usePhotos, useTenants } from "../../contexts";
import { FormProps, Tenant } from "../../models";

interface PartnerFormModel {
    ahcvIdentifier: string;
    name: string;
    logoUri: string;
    parentTenantId?: number;
    isActive: boolean;
    usersCount: number;
}

export const PartnerForm = ({ onClose, show, type }: FormProps) => {
    const { customerName, partnerName } = useAppSettings();
    const { awsFiles, clearImages, uploadImage } = usePhotos();
    const { createTenant, deleteTenant, editTenant, restoreTenant, selectedTenant } = useTenants();

    const errorMessage = useMemo(() => "File type is not valid", []);
    const defaultFileInputMessage = useMemo(() => "Choose a file...", []);
    const [filePath, setFilePath] = useState(defaultFileInputMessage);
    const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false);
    const [showConfirmRestoreModal, setShowConfirmRestoreModal] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const [errors, setErrors] = useState({
        ahcvIdentifier: "",
        name: "",
    });

    const [formValues, setFormValues] = useState<PartnerFormModel>({
        ahcvIdentifier: "",
        name: "",
        logoUri: "",
        parentTenantId: undefined,
        isActive: true,
        usersCount: 0,
    });

    useEffect(() => {
        if (!selectedTenant) return;
        const parentTenantId = type === "add" ? undefined : selectedTenant.parentTenantId;
        setFormValues({
            ...selectedTenant,
            parentTenantId,
        });
    }, [selectedTenant]);

    const stripFilePath = useCallback((path: string) => path.split("\\").pop()!, []);

    const isExtensionValid = useCallback((path: string) => {
        const extension = path.substring(path.lastIndexOf(".") + 1).toLowerCase();
        return extension === "gif" || extension === "png" || extension === "jpeg" || extension === "jpg";
    }, []);

    const handleFileInputChange = useCallback(
        (file: File) => {
            if (file) {
                if (isExtensionValid(file.name)) {
                    var reader = new FileReader();

                    reader.onload = (event) =>
                        uploadImage({
                            filename: file.name,
                            content: reader.result?.toString().split(",")[1] || "",
                            url: "",
                        });

                    reader.readAsDataURL(file);

                    if (reader.DONE === 2) {
                        return stripFilePath(file.name);
                    }

                    return defaultFileInputMessage;
                }

                if (file.name === "") {
                    return defaultFileInputMessage;
                }
            }

            return errorMessage;
        },
        [isExtensionValid, stripFilePath]
    );

    const isFormValid = useCallback(() => {
        let valid = true;
        if (!formValues.ahcvIdentifier) {
            setErrors((prev) => ({ ...prev, ahcvIdentifier: "State ID is required" }));
            valid = false;
        }
        if (formValues.ahcvIdentifier.length > 128) {
            setErrors((prev) => ({ ...prev, ahcvIdentifier: "State ID must be 128 characters or less" }));
            valid = false;
        }
        if (!formValues.name) {
            setErrors((prev) => ({ ...prev, name: "Name is required" }));
            valid = false;
        }
        if (formValues.name.length > 128) {
            setErrors((prev) => ({ ...prev, name: "Name must be 128 characters or less" }));
            valid = false;
        }
        return valid;
    }, [formValues]);

    const handleClose = useCallback(() => {
        clearImages();
        onClose();
    }, [clearImages, onClose]);

    const handleSubmit = useCallback(async () => {
        setErrors({
            ahcvIdentifier: "",
            name: "",
        });
        if (!isFormValid()) return;
        setIsSubmitting(true);
        let success = false;
        if (type === "add") {
            success = await createTenant({
                tenant: {
                    ...formValues,
                    id: 0,
                    county: "",
                },
            });
        } else {
            if (!selectedTenant) return;
            success = await editTenant({
                operable: {
                    original: selectedTenant,
                    updated: {
                        ...selectedTenant,
                        ...formValues,
                    },
                },
            });
        }
        if (success) {
            handleClose();
        } else {
            setIsSubmitting(false);
        }
    }, [createTenant, editTenant, formValues, handleClose, isFormValid, selectedTenant]);

    const handleDeletePartner = useCallback(
        async (tenant: Tenant) => {
            const success = await deleteTenant(tenant);
            if (success) {
                setShowConfirmDeleteModal(false);
                onClose();
            }
        },
        [deleteTenant, onClose]
    );

    const handleRestorePartner = useCallback(
        async (tenant: Tenant) => {
            const success = await restoreTenant(tenant);
            if (success) {
                setShowConfirmRestoreModal(false);
                onClose();
            }
        },
        [restoreTenant, onClose]
    );

    return (
        <>
            <Modal show={show} backdrop="static" keyboard={false} size="lg">
                <Modal.Header>{type === "add" ? `Add ${partnerName}` : `Edit ${partnerName}`}</Modal.Header>
                <Modal.Body>
                    <Form id="partnerForm">
                        <Row>
                            <Col sm={4}>
                                <Form.Group controlId="systemId" className="mb-2">
                                    <Form.Label>System/Facility</Form.Label>
                                    <Form.Control
                                        value={formValues.ahcvIdentifier}
                                        onChange={(e) =>
                                            setFormValues((prev) => ({ ...prev, ahcvIdentifier: e.target.value }))
                                        }
                                        isInvalid={!!errors.ahcvIdentifier}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.ahcvIdentifier}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                            <Col sm={4}>
                                <Form.Group controlId="partnerName" className="mb-2">
                                    <Form.Label>Name</Form.Label>
                                    <Form.Control
                                        value={formValues.name}
                                        onChange={(e) => setFormValues((prev) => ({ ...prev, name: e.target.value }))}
                                        isInvalid={!!errors.name}
                                    />
                                    <Form.Control.Feedback type="invalid">{errors.name}</Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                        </Row>
                    </Form>
                </Modal.Body>
                <Modal.Footer className="d-flex justify-content-between">
                    <Button variant="secondary" onClick={handleClose}>
                        Cancel
                    </Button>
                    <div>
                        <Row>
                            <Col>
                                {type === "edit" && selectedTenant?.isActive && (
                                    <Button variant="danger" onClick={() => setShowConfirmDeleteModal(true)}>
                                        Deactivate
                                    </Button>
                                )}
                                {type === "edit" && !selectedTenant?.isActive && (
                                    <Button
                                        variant="success"
                                        onClick={() => setShowConfirmRestoreModal(true)}
                                        className="ms-2"
                                    >
                                        Activate
                                    </Button>
                                )}
                            </Col>
                            <Col>
                                {(!selectedTenant || selectedTenant?.isActive) && (
                                    <Button
                                        variant="primary"
                                        onClick={handleSubmit}
                                        className="ms-2"
                                        disabled={isSubmitting}
                                    >
                                        {isSubmitting ? "Saving..." : "Save"}
                                    </Button>
                                )}
                            </Col>
                        </Row>
                    </div>
                </Modal.Footer>
            </Modal>

            {selectedTenant && (
                <>
                    <ConfirmationModal
                        id="confirmDeletePartnerModal"
                        show={showConfirmDeleteModal}
                        title={`Deactivate ${partnerName ?? "partner"}?`}
                        message={
                            `Do you really want to deactivate this ${partnerName ?? "partner"}?` +
                            ` This will also deactivate associated ${customerName ? customerName + "s" : "customers"}.`
                        }
                        onCloseButtonClick={() => setShowConfirmDeleteModal(false)}
                        onOkButtonClick={() => handleDeletePartner(selectedTenant)}
                        theme={"danger"}
                    />
                    <ConfirmationModal
                        id="confirmRestorePartnerModal"
                        show={showConfirmRestoreModal}
                        title={`Activate ${partnerName ?? "partner"}?`}
                        message={`Do you really want to activate this ${partnerName ?? "partner"}?`}
                        onCloseButtonClick={() => setShowConfirmRestoreModal(false)}
                        onOkButtonClick={() => handleRestorePartner(selectedTenant)}
                        theme={"danger"}
                    />
                </>
            )}
        </>
    );
};
