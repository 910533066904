import { PropsWithChildren, useCallback, useEffect, useMemo, useState } from "react";
import { useGeolocated } from "react-geolocated";
import { createPatch, Operation } from "rfc6902";
import { replaceInCollection } from "../../helper-functions";
import { useContext, useStoredState } from "../../hooks";
import { Coordinates, HttpOptions, Operable, User, UserTypes } from "../../models";
import { useAuth, useHttp, useRoles, UserContext, UserState } from "..";
import { ImmutableRole } from "../../models/role/ImmutableRole";

const compareUsers = (a: User, b: User): number => a.emailAddress.localeCompare(b.emailAddress);
const sortUsers = (users: User[]) => users.sort(compareUsers);

export const UserProvider = (props: PropsWithChildren) => {
    const { decodedAccessToken, isAuthenticated } = useAuth();
    const { get, post, patchUnique, del } = useHttp();
    const [httpOptions] = useState<HttpOptions>({ path: "users" });
    const { getRoles } = useRoles();
    const [userState, setUserState] = useStoredState<UserState>("user", { users: [] });
    const { loggedInUser } = userState;

    const userType = useMemo((): UserTypes => {
        if (!loggedInUser || !decodedAccessToken.user_id) {
            return UserTypes.Unauthenticated;
        }
        if (decodedAccessToken.roles === ImmutableRole.GlobalAdmin) {
            return UserTypes.GlobalAdmin;
        }
        if (decodedAccessToken.roles === ImmutableRole.Admin) {
            return UserTypes.Admin;
        }
        return UserTypes.Unauthenticated;
    }, [decodedAccessToken.user_id, decodedAccessToken.roles, loggedInUser]);

    const hasAdminAccess = [UserTypes.GlobalAdmin, UserTypes.Admin].includes(userType);

    const geolocationConfig = useMemo(
        () => ({
            positionOptions: {
                enableHighAccuracy: true,
            },
            watchPosition: true,
            userDecisionTimeout: 10000,
        }),
        []
    );
    const { coords, isGeolocationAvailable, isGeolocationEnabled } = useGeolocated(geolocationConfig);
    const hasGeolocation = isGeolocationAvailable && isGeolocationEnabled;
    const [coordinates, setCoordinates] = useState<Coordinates | undefined>();

    useEffect(() => {
        if (userState.loggedInUser) {
            getRoles();
        }
    }, [userState.loggedInUser]);

    useEffect(() => {
        if (coords) {
            setCoordinates({ ...coords });
        }
    }, [coords]);

    useEffect(() => {
        if (decodedAccessToken.user_id && userState.users.length > 0) {
            setUserState((prev) => ({
                ...prev,
                loggedInUser: userState.users.find((u) => u.id === +decodedAccessToken.user_id),
            }));
        }
    }, [decodedAccessToken.user_id, userState.users]);

    const getUsers = useCallback(async () => {
        if (!isAuthenticated) {
            return false;
        }
        const users = await get<User[]>(httpOptions);
        if (users) {
            setUserState((prev) => ({
                ...prev,
                users: sortUsers(users),
            }));
        }
        return !!users;
    }, [get, httpOptions, isAuthenticated]);

    useEffect(() => {
        getUsers();
    }, []);

    const createUser = useCallback(
        async (user: User) => {
            const createdUser = await post<User>({
                ...httpOptions,
                body: user,
            });
            if (createdUser) {
                setUserState((prev) => ({
                    ...prev,
                    users: sortUsers([...prev.users, createdUser]),
                }));
            }
            return !!createdUser;
        },
        [httpOptions, post]
    );

    const deleteUser = useCallback(
        async (user: User) => {
            const deleted = await del({ path: `users/${user.id}` });
            if (deleted) {
                setUserState((prev) => ({
                    ...prev,
                    users: replaceInCollection(
                        [...prev.users],
                        {
                            original: user,
                            updated: { ...user, isActive: false },
                        },
                        compareUsers
                    ),
                }));
            }
            return !!deleted;
        },
        [del]
    );

    const editUser = useCallback(
        async ({ original, updated }: Operable<User>): Promise<boolean> => {
            const patchedUser = await patchUnique<Operation[], User>({
                path: `users/${original.id}`,
                body: createPatch(original, updated),
            });
            if (patchedUser) {
                setUserState((prev) => ({
                    ...prev,
                    users: replaceInCollection([...prev.users], { original, updated }, compareUsers),
                }));
            }
            return !!patchedUser;
        },
        [patchUnique]
    );

    const restoreUser = useCallback(
        async (original: User): Promise<boolean> => {
            const updated = { ...original, isActive: true };
            const patchedUser = await editUser({ original, updated });
            return !!patchedUser;
        },
        [editUser]
    );

    const getInactiveUsers = useCallback(async () => {
        const users = await get<User[]>({ path: "users/inactive" });
        if (users) {
            setUserState((prev) => ({
                ...prev,
                users: sortUsers([...prev.users.filter((u) => u.isActive), ...users]),
            }));
        }
        return !!users;
    }, [get]);

    const selectUser = useCallback(
        (selectedUser: User | undefined) => setUserState((prev) => ({ ...prev, selectedUser })),
        []
    );

    const clearSelectedUser = useCallback(() => setUserState((prev) => ({ ...prev, selectedUser: undefined })), []);

    const value = useMemo(
        () => ({
            getUsers,
            createUser,
            deleteUser,
            editUser,
            restoreUser,
            getInactiveUsers,
            selectUser,
            clearSelectedUser,
            userType,
            hasAdminAccess,
            hasGeolocation,
            coordinates,
            ...userState,
        }),
        [
            getUsers,
            createUser,
            deleteUser,
            editUser,
            restoreUser,
            getInactiveUsers,
            selectUser,
            clearSelectedUser,
            userType,
            hasAdminAccess,
            hasGeolocation,
            coordinates,
            userState,
        ]
    );

    return <UserContext.Provider value={value} {...props} />;
};

export const useUsers = () => useContext(UserContext);
