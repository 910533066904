import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useAuth, useOidc } from "../../contexts";

export const Oidc = () => {
    const { isAuthorized, isLoggingIn, isLoggingOut } = useAuth();
    const navigate = useNavigate();
    const { login } = useOidc();

    useEffect(
        () => {
            if (isLoggingOut || isLoggingIn) {
                return;
            }
            if (isAuthorized) {
                navigate("/");
            } else {
                login();
            }
        },
        [isAuthorized, isLoggingOut, isLoggingIn, login, navigate]
    );

    return <div>Redirecting...</div>;
}