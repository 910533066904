import { PropsWithChildren, useCallback, useMemo } from "react";
import { useHttp } from "../http";
import { useContext, useStoredState } from "../../hooks";
import { RegionCounty } from "../../models/regionCounties/RegionCounty";
import { RegionCountyContext, RegionCountyState } from "./RegionCountyContext";

export const initialRegionCountyState: RegionCountyState = {
    regionCounties: [],
};

export const RegionCountyProvider = (props: PropsWithChildren) => {
    const { get } = useHttp();

    const [regionCounties, setRegionCounties] = useStoredState("regionCounty", initialRegionCountyState);

    const getRegionCounties = useCallback(
        async (stateId: number) => {
            const regionCounties = await get<RegionCounty[]>({ path: `region-county/state/${stateId}` });
            if (!regionCounties) return;
            setRegionCounties((prev) => ({ ...prev, regionCounties: regionCounties }));
        },
        [get, setRegionCounties]
    );

    const value = useMemo(
        () => ({
            ...regionCounties,
            getRegionCounties,
        }),
        [regionCounties, getRegionCounties]
    );

    return <RegionCountyContext.Provider value={value} {...props} />;
};

export const useRegionCounties = () => useContext(RegionCountyContext);
