import { PropsWithChildren, useCallback, useMemo, useState } from "react";
import { HttpContext } from "./HttpContext";
import { useContext } from "../../hooks";
import { API_BASE_URL } from "../../config/config";
import { ErrorMessages, HttpMethod, HttpOptions, HttpOptionsWithBody } from "../../models";
import { getErrorMessage } from "../../helper-functions";
import { useAuth, useToasts } from "..";
import { ErrorToast } from "../../component-library";

export const HttpProvider = (props: PropsWithChildren) => {
    const { accessToken, isAuthenticated, setAuthState } = useAuth();
    const { showToast } = useToasts();
    const [isFetching, setIsFetching] = useState(false);

    const request = useCallback(
        async ({ path, query, toastOnError = true, ...rest }: HttpOptions) => {
            if (isAuthenticated && !isFetching) {
                try {
                    setIsFetching(true);
                    const defaultOptions = {
                        method: HttpMethod.GET,
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: `Bearer ${accessToken}`,
                        },
                    };

                    const url = API_BASE_URL + path + (query ? `?${new URLSearchParams(query)}` : "");
                    const response = await fetch(url, { ...defaultOptions, ...rest });

                    if (response.ok) return response;

                    const { status, statusText } = response;
                    if (status === 401 && isAuthenticated && !statusText.includes(ErrorMessages.Authorization)) {
                        setAuthState((prev) => ({ ...prev, shouldLogout: true }));
                    }
                    throw Error(response.statusText);
                } catch (error) {
                    const { message } = getErrorMessage(error);
                    if (toastOnError) {
                        showToast(ErrorToast(message));
                    }
                } finally {
                    setIsFetching(false);
                }
            }
            return undefined;
        },
        [accessToken, isAuthenticated, isFetching, setAuthState, showToast]
    );

    const resolveContent = useCallback(async <T,>(promise: Promise<Response | undefined>) => {
        const response = await promise;
        if (response) {
            return response.json() as T;
        }
    }, []);

    const requestAndResolveContent = useCallback(
        <T,>(options: HttpOptions) => {
            return resolveContent<T>(request(options));
        },
        [request, resolveContent]
    );

    const requestWithBody = useCallback(
        <T,>({ body, stringify = true, ...rest }: HttpOptionsWithBody<T>) => {
            return requestAndResolveContent<T>({ ...rest, body: stringify ? JSON.stringify(body) : (body as any) });
        },
        [requestAndResolveContent]
    );

    const requestWithUniqueBody = useCallback(
        <TBody, TResponse>({ body, stringify = true, ...rest }: HttpOptionsWithBody<TBody>) => {
            return requestAndResolveContent<TResponse>({
                ...rest,
                body: stringify ? JSON.stringify(body) : (body as any),
            });
        },
        [requestAndResolveContent]
    );

    const del = useCallback(
        async (options: HttpOptions) => {
            return !!request({ ...options, method: HttpMethod.DELETE });
        },
        [request]
    );

    const get = useCallback(
        <T,>(options: HttpOptions) => {
            return requestAndResolveContent<T>({ ...options, method: HttpMethod.GET });
        },
        [requestAndResolveContent]
    );

    const getBlob = useCallback(
        async (options: HttpOptions) => {
            const response = await request({ ...options, method: HttpMethod.GET });
            if (response) {
                return response.blob();
            }
        },
        [request]
    );

    const patch = useCallback(
        <T,>(options: HttpOptionsWithBody<T>) => {
            return requestWithBody<T>({ ...options, method: HttpMethod.PATCH });
        },
        [requestWithBody]
    );

    const patchUnique = useCallback(
        <TBody, TResponse>(options: HttpOptionsWithBody<TBody>) => {
            return requestWithUniqueBody<TBody, TResponse>({ ...options, method: HttpMethod.PATCH });
        },
        [requestWithUniqueBody]
    );

    const post = useCallback(
        <T,>(options: HttpOptionsWithBody<T>) => {
            return requestWithBody<T>({ ...options, method: HttpMethod.POST });
        },
        [requestWithBody]
    );

    const postUnique = useCallback(
        <TBody, TResponse>(options: HttpOptionsWithBody<TBody>) => {
            return requestWithUniqueBody<TBody, TResponse>({ ...options, method: HttpMethod.POST });
        },
        [requestWithUniqueBody]
    );

    const put = useCallback(
        <T,>(options: HttpOptionsWithBody<T>) => {
            return requestWithBody<T>({ ...options, method: HttpMethod.PUT });
        },
        [requestWithBody]
    );

    const value = useMemo(
        () => ({
            del,
            get,
            getBlob,
            isFetching,
            patch,
            patchUnique,
            post,
            postUnique,
            put,
        }),
        [del, get, getBlob, isFetching, patch, patchUnique, post, postUnique, put]
    );

    return <HttpContext.Provider value={value} {...props} />;
};

export const useHttp = () => useContext(HttpContext);
