import { createContext } from "react";
import { KeyDateSystemType } from "../../models/key-date-system-type/KeyDateSystemType";

export interface KeyDateSystemTypeState {
    keyDateSystemTypes: KeyDateSystemType[];
}

interface IKeyDateSystemTypeContext extends KeyDateSystemTypeState {
    getKeyDateSystemTypes: () => Promise<void>;
}

export const KeyDateSystemTypeContext = createContext<IKeyDateSystemTypeContext | undefined>(undefined);
KeyDateSystemTypeContext.displayName = "KeyDateSystemType";
