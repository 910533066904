import { useState, useEffect, useCallback } from "react";
import { Col, Form, Modal, Row, Button, Alert } from "react-bootstrap";
import { useDropzone } from "react-dropzone";
import { useFacilityFiles } from "../../contexts/facility-file";
import { isValidFileExtension } from "../../helper-functions/isValidFileExtension";
import { uploadFileToS3WithPreSignedUrl, getPreSignedUrl } from "../../helper-functions/S3";
import { useStoredState } from "../../hooks";
import { FormProps } from "../../models";
import { FilesState } from "../../models/facility-file/FileState";
import { useAuth } from "../../contexts";
import { AWS_CONFIG } from "../../config/config";
import { PreSignedUrlType } from "../../models/pre-signed-url/PreSignedUrlType";
interface FacilityFileFormModel {
    filename: string;
    linkName: string;
    isActive: boolean;
}

export const FacilityFileEditForm = ({ onClose, show, type }: FormProps) => {
    const [filesState, setFilesState] = useStoredState<FilesState>("files", {
        files: [],
    });
    const { selectedFile, editFacilityFile } = useFacilityFiles();
    const [error, setError] = useState<string>("");
    const [filesUploaded, setFilesUploaded] = useState<string>("");

    const [isSubmitting, setIsSubmitting] = useState(false);
    const [errors, setErrors] = useState({
        filename: "",
        isActive: true,
        linkName: "",
    });

    const [formValues, setFormValues] = useState<FacilityFileFormModel>({
        filename: "",
        isActive: true,
        linkName: "",
    });
    const { accessToken } = useAuth();

    useEffect(() => {
        if (!selectedFile) return;
        setFormValues({ ...selectedFile });
    }, [selectedFile]);

    const isFormValid = useCallback(() => {
        const { linkName } = formValues;
        let valid = true;
        if (!linkName) {
            setErrors((prev) => ({ ...prev, linkName: "Display Name is required" }));
            valid = false;
        }
        return valid;
    }, [formValues]);

    const handleSubmit = useCallback(async () => {
        setErrors({
            filename: "",
            isActive: true,
            linkName: "",
        });

        if (!isFormValid()) return;

        setIsSubmitting(true);
        let success = false;

        if (!selectedFile) return;

        if (filesState.files.length > 0) {
            var file = filesState.files[0];
            const filename = file.name;
            const filenameWithoutExtension = filename.replace(/\.[^/.]+$/, "");

            var preSignedUrl = await getPreSignedUrl(
                accessToken,
                filenameWithoutExtension,
                AWS_CONFIG.bucketName!,
                PreSignedUrlType.Upload
            );
            if (!preSignedUrl) {
                setIsSubmitting(false);
                return;
            }

            const awsResult = await uploadFileToS3WithPreSignedUrl(file, preSignedUrl);
            if (awsResult === 200) {
                success = await editFacilityFile({
                    original: selectedFile,
                    updated: {
                        ...selectedFile,
                        ...formValues,
                        filename: filename,
                        awsFilename: `Uploads/${filename}`,
                    },
                });
            }
        } else {
            success = await editFacilityFile({
                original: selectedFile,
                updated: {
                    ...selectedFile,
                    ...formValues,
                },
            });
        }

        if (success) {
            onClose();
        } else {
            setIsSubmitting(false);
        }
    }, [editFacilityFile, formValues, isFormValid, onClose, selectedFile]);

    const onDrop = useCallback(
        (acceptedFiles: File[]) => {
            var errors = "";
            setError("");
            setFilesUploaded("");
            if (acceptedFiles.length > 1) {
                setError(`Please select a single file \n`);
                return;
            } else {
                var fileUploaded = "";
                acceptedFiles.forEach((file) => {
                    const isValid = isValidFileExtension(file.name);
                    if (!isValid) {
                        errors = `${errors}Invalid file extension for ${file.name} \n`;
                        return;
                    } else {
                        fileUploaded = `${fileUploaded} ${file.name} is valid \n`;
                        setFormValues((prev) => ({ ...prev, filename: file.name }));
                    }
                });
                if (errors !== "") {
                    setError(errors);
                    setFilesState({ files: [] });
                } else {
                    setFilesUploaded(fileUploaded);
                    setFilesState({ files: acceptedFiles });
                }
            }
        },
        [setFilesState]
    );

    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

    return (
        <>
            <Modal show={show} backdrop="static" keyboard={false} size="lg">
                <Modal.Header>Edit File</Modal.Header>
                <Modal.Body>
                    <Form id="facilityFileForm">
                        <Row>
                            <Col sm={4}>
                                <Form.Group controlId="filename" className="mb-2">
                                    <Form.Label>Filename</Form.Label>
                                    <Form.Control
                                        value={formValues.filename}
                                        onChange={(e) =>
                                            setFormValues((prev) => ({ ...prev, filename: e.target.value }))
                                        }
                                        isInvalid={!!errors.filename}
                                        disabled={true}
                                    />
                                    <Form.Control.Feedback type="invalid">{errors.filename}</Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                            <Col sm={4}>
                                <Form.Group controlId="linkName" className="mb-2">
                                    <Form.Label>Display Name</Form.Label>
                                    <Form.Control
                                        value={formValues.linkName}
                                        onChange={(e) =>
                                            setFormValues((prev) => ({ ...prev, linkName: e.target.value }))
                                        }
                                        isInvalid={!!errors.linkName}
                                    />
                                    <Form.Control.Feedback type="invalid">{errors.linkName}</Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                            <Col sm={4}>
                                <Form.Group controlId="isActive" className="mb-2">
                                    <Form.Label>Active</Form.Label>
                                    <Form.Select
                                        value={Number(formValues.isActive)}
                                        onChange={(e) =>
                                            setFormValues((prev) => ({
                                                ...prev,
                                                isActive: Boolean(+e.target.value),
                                            }))
                                        }
                                    >
                                        <option value={0}>No</option>
                                        <option value={1}>Yes</option>
                                    </Form.Select>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row md={12} className="edit-file-info">
                            {!!error && (
                                <Alert variant="danger">
                                    <div className="display-linebreak">{error}</div>
                                </Alert>
                            )}
                        </Row>
                        <Row md={12} className="edit-file-info">
                            {!!filesUploaded && (
                                <Alert variant="success">
                                    <div className="display-linebreak">{filesUploaded}</div>
                                </Alert>
                            )}
                        </Row>
                        <Row md={12} className="edit-file-info">
                            <div {...getRootProps()} className="dropzone-container">
                                <input {...getInputProps()} />
                                {isDragActive ? (
                                    <p>Drop the file here ...</p>
                                ) : (
                                    <p className="dropzone-placeholder">
                                        Drag and drop your file here, or click to select a file . Multiple files are not
                                        allowed.
                                    </p>
                                )}
                            </div>
                        </Row>
                    </Form>
                </Modal.Body>
                <Modal.Footer className="d-flex justify-content-between">
                    <Button variant="secondary" onClick={onClose}>
                        Cancel
                    </Button>
                    <Button
                        variant="primary"
                        onClick={handleSubmit}
                        className="ms-2"
                        disabled={isSubmitting || error !== ""}
                    >
                        {isSubmitting ? "Saving..." : "Save"}
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};
