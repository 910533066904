import { useMemo } from "react";
import { Modal } from "react-bootstrap";
import { DataTable } from "../../component-library";
import { ColumnDef } from "@tanstack/react-table";
import { usePayments, usePrograms, useTenants } from "../../contexts";
import _ from "lodash";
import { formatCurrencyWithoutDecimal } from "../../helper-functions/formatCurrencyWithoutDecimal";

export const TopPerformingFacilities = () => {
    const { tenants, states } = useTenants();
    const { programs } = usePrograms();
    const { payments } = usePayments();
    const facilityGrouped = _.groupBy(payments, (p) => p.tenantId);
    const stateGrouped = _.groupBy(payments, (p) => p.stateId);

    var stateData = Object.entries(stateGrouped).map(([key, value]) => ({
        stateId: key,
        amount: value.reduce((a, v) => (a = a + v.amount), 0.0),
    }));

    var facilities = Object.entries(facilityGrouped).map(([key, value]) => ({
        facility: tenants.find((p) => p.id === value[0].tenantId)?.name,
        program: programs.find((p) => p.id === value[0].programId)?.name,
        share:
            (value.reduce((a, v) => (a = a + v.amount), 0.0) /
                (stateData.find((p) => p.stateId === value[0].stateId.toString())?.amount || 1)) *
            100,
        location: states.find((s) => s.id === value[0].stateId)?.name,
        amount: value.reduce((a, v) => (a = a + v.amount), 0.0),
    }));

    const facilityData = _.orderBy(facilities, (facility) => facility.amount, "desc");

    const columnDefs = useMemo(
        (): ColumnDef<any>[] => [
            {
                header: `Facility`,
                accessorKey: "facility",
            },
            {
                header: "Location",
                accessorKey: "location",
            },
            {
                header: () => {
                    return <div className="currency-align">Revenue</div>;
                },
                accessorKey: "amount",
                cell: ({ row }) => {
                    return <div className="currency-align">{formatCurrencyWithoutDecimal(row.original.amount)}</div>;
                },
            },
            {
                header: () => {
                    return <div className="currency-align">% Share</div>;
                },
                accessorKey: "share",
                cell: ({ row }) => {
                    return (
                        <div className="currency-align">{`${row.original.share.toLocaleString(undefined, {
                            maximumFractionDigits: 0,
                        })} %`}</div>
                    );
                },
            },
        ],
        [payments]
    );
    return (
        <>
            <Modal.Header>Performing Facilities</Modal.Header>
            {facilityData.length > 0 && (
                <DataTable
                    data={facilityData}
                    columns={columnDefs}
                    paginated={facilityData.length > 10}
                    pageSize={10}
                />
            )}
            {facilityData.length === 0 && <div className="fw-bold no-data">No Data</div>}
        </>
    );
};
