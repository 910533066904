/* eslint-disable react-hooks/exhaustive-deps */

import { useMemo } from "react";
import { Modal, Button } from "react-bootstrap";
import { DataTable } from "../../component-library";
import { ColumnDef } from "@tanstack/react-table";
import { usePayments, usePrograms, useTenants } from "../../contexts";
import { FormProps, Payment } from "../../models";
import _ from "lodash";
import { formatCurrencyWithoutDecimal } from "../../helper-functions/formatCurrencyWithoutDecimal";

export const ViewProgramsTable = ({ onClose, show, type }: FormProps) => {
    const { programs } = usePrograms();
    const { payments } = usePayments();
    const { states } = useTenants();

    const stateGrouped = _.groupBy(payments, (p) => `${p.stateId}-${p.programId}`);

    const stateData = Object.entries(stateGrouped).map(([key, value]) => ({
        state: states.find((s) => s.id === value[0].stateId)?.name,
        program: programs.find((p) => p.id === value[0].programId)?.name,
        amount: value.reduce((a, v) => (a = a + v.amount), 0),
    }));

    const columnDefs = useMemo(
        (): ColumnDef<Payment>[] => [
            {
                header: "Program",
                accessorKey: "program",
            },
            {
                header: "State",
                accessorKey: "state",
            },
            {
                header: () => {
                    return <div className="currency-align">Revenue</div>;
                },
                accessorKey: "amount",
                cell: ({ row }) => {
                    return <div className="currency-align">{formatCurrencyWithoutDecimal(row.original.amount)}</div>;
                },
            },
        ],
        [payments]
    );
    return (
        <>
            <Modal show={show} backdrop="static" keyboard={false} size="lg">
                <Modal.Header>Program Information</Modal.Header>
                <Modal.Body>
                    <DataTable
                        data={stateData}
                        columns={columnDefs}
                        filterColumns
                        paginated={stateData.length > 10}
                        pageSize={10}
                    />
                </Modal.Body>
                <Modal.Footer className="d-flex justify-content-between">
                    <Button variant="primary" onClick={onClose} className="ms-2">
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};
