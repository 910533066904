import { createContext } from "react";
import { Coordinates, Operable, User, UserTypes } from "../../models";

export interface UserState {
    users: User[];
    selectedUser?: User;
    loggedInUser?: User;
}

interface IUserContext extends UserState {
    userType: UserTypes;
    hasAdminAccess: boolean;
    hasGeolocation: boolean;
    coordinates: Coordinates | undefined;
    getUsers: () => Promise<boolean>;
    createUser: (user: User) => Promise<boolean>;
    deleteUser: (user: User) => Promise<boolean>;
    editUser: (users: Operable<User>) => Promise<boolean>;
    restoreUser: (user: User) => Promise<boolean>;
    getInactiveUsers: () => Promise<boolean>;
    selectUser: (user: User) => void;
    clearSelectedUser: () => void;
}

export const UserContext = createContext<IUserContext | undefined>(undefined);
UserContext.displayName = "User";