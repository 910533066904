import _ from "lodash";
import { Row, Col } from "react-bootstrap";
import { DataTable } from "../../component-library";
import { ColumnDef } from "@tanstack/react-table";
import { formatCurrencyWithoutDecimal } from "../../helper-functions/formatCurrencyWithoutDecimal";
import { getMonthName } from "../../helper-functions/getMonthName";
import { useUpcomingTransactions } from "../../contexts";
import { useEffect, useMemo } from "react";

interface IUpcomingTransactions {
    facilityIdState: number;
}

export function UpcomingTransactions({ facilityIdState }: IUpcomingTransactions) {
    const { upcomingTransactions } = useUpcomingTransactions();
    const upcomingTransactionsSorted = _.orderBy(upcomingTransactions, (ut) => ut.expectedDate);
    const totalUpcomingTransactionsAmount = upcomingTransactions
        .map((ut) => ut.amount)
        .reduce((total, value) => total + value, 0);
    const totalUpcomingTransactionsAmountFormatted = formatCurrencyWithoutDecimal(totalUpcomingTransactionsAmount);
    const currentDate = new Date();
    const currentMonth = getMonthName(currentDate.getMonth());
    const nextMonth = getMonthName(currentDate.getMonth() + 1);

    const columnDefs = useMemo(
        (): ColumnDef<any>[] => [
            {
                header: `Facility`,
                accessorKey: "facility",
            },
            {
                header: `Type`,
                accessorKey: "type",
            },
            {
                header: () => {
                    return <div>Expected Date</div>;
                },
                accessorKey: "expectedDate",
                cell: ({ row }) => new Date(row.original.expectedDate).toLocaleDateString(),
            },
            {
                header: () => {
                    return <div className="currency-align">Amount</div>;
                },
                accessorKey: "amount",
                cell: ({ row }) => {
                    return <div className="currency-align">{formatCurrencyWithoutDecimal(row.original.amount)}</div>;
                },
            },
        ],
        []
    );

    return (
        <div className="chart-border h-100">
            <h2 className="modal-header">
                Upcoming Transactions ({currentMonth} - {nextMonth})
            </h2>
            <Row className="m-0">
                <Col>
                    <DataTable
                        data={upcomingTransactionsSorted}
                        columns={columnDefs}
                        filterColumns
                        paginated={upcomingTransactionsSorted.length > 10}
                        pageSize={10}
                    />
                </Col>
            </Row>
            <Row className="m-0">
                <Col lg={12} className="bg-white padding">
                    <span className="float-right fw-bold">
                        <span className="me-4">Total:</span>
                        {totalUpcomingTransactionsAmountFormatted}
                    </span>
                </Col>
            </Row>
        </div>
    );
}
