import { createContext } from "react";
import { HttpOptions, HttpRequest, HttpRequestNoContent, HttpRequestUniqueBody, HttpRequestWithBody } from "../../models";

interface IHttpContext {
    get: HttpRequest;
    getBlob: (options: HttpOptions) => Promise<Blob | undefined>;
    patch: HttpRequestWithBody;
    post: HttpRequestWithBody;
    put: HttpRequestWithBody;
    del: HttpRequestNoContent;
    patchUnique: HttpRequestUniqueBody;
    postUnique: HttpRequestUniqueBody;
    isFetching: boolean;
}

export const HttpContext = createContext<IHttpContext | undefined>(undefined);
HttpContext.displayName = "Http";