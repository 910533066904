import { Identifiable, Operable } from "../models";

export const replaceInCollection = <TModel extends Identifiable>(
    collection: TModel[],
    { original, updated }: Operable<TModel>,
    sortFn?: (a: TModel, b: TModel) => number
): TModel[] => {
    const newCollection = [
        ...collection.filter((m) => m.id !== original.id),
        updated
    ];
    return !!sortFn ? newCollection.sort(sortFn) : newCollection;
}
