import { PropsWithChildren, useCallback, useMemo, useState } from "react";
import { useContext } from "../../hooks";
import { EmailArchive } from "../../models/emailArchive/EmailArchive";
import { useHttp } from "../http";
import { EmailArchiveContext, EmailArchiveState } from "./EmailArchiveContext";

export const initialEmailArchiveState: EmailArchiveState = {
    emailArchives: [],
};

export const EmailArchiveProvider = (props: PropsWithChildren) => {
    const { get } = useHttp();
    const [emailArchives, setEmailArchives] = useState<EmailArchiveState>(initialEmailArchiveState);

    const getEmailArchives = useCallback(async () => {
        const emailArchives = await get<EmailArchive[]>({ path: "email-archive" });
        if (!emailArchives) return;
        setEmailArchives((prev) => ({ ...prev, emailArchives }));
    }, [get]);

    const value = useMemo(() => ({ ...emailArchives, getEmailArchives }), [emailArchives, getEmailArchives]);

    return <EmailArchiveContext.Provider value={value} {...props} />;
};

export const useEmailArchives = () => useContext(EmailArchiveContext);
