import { useCallback, useState } from "react";
import { Container, Nav, Navbar, NavDropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import logo from "../../images/ahcv_logo.png";
import { useAppSettings, useAuth, useUsers } from "../../contexts";
import { LogoutModal, LogoutModalProps } from "../logout/Logout";
import { ImmutableRole } from "../../models/role/ImmutableRole";

export default function Header() {
    const { logoUri } = useAppSettings();
    const { decodedAccessToken } = useAuth();
    const isAdmin =
        decodedAccessToken.roles === ImmutableRole.GlobalAdmin ||
        decodedAccessToken.roles === ImmutableRole.PartnerAdmin;
    const isGlobalAdmin = decodedAccessToken.roles === ImmutableRole.GlobalAdmin;
    const { loggedInUser } = useUsers();
    const userName = `${loggedInUser?.firstName} ${loggedInUser?.lastName}`;
    const [logoutModalProps, setLogoutModalProps] = useState<LogoutModalProps>({ show: false });

    const getLogo = useCallback(() => {
        if (logoUri) {
            return logoUri;
        }
        return logo;
    }, [logoUri]);

    const hideModal = useCallback(() => setLogoutModalProps({ show: false }), []);

    const handleLogout = useCallback(() => setLogoutModalProps({ show: true, onHide: hideModal }), []);

    return (
        <>
            <div className="keysys-header box-shadow">
                <Navbar collapseOnSelect expand="lg">
                    <Container fluid>
                        <Navbar.Brand>
                            <Link to="/">
                                <img alt="AHCV Logo" src={getLogo()} style={{ maxWidth: "210px", maxHeight: "90px" }} />
                            </Link>
                        </Navbar.Brand>
                        <Navbar.Toggle aria-controls="navbarScroll" />
                        <Navbar.Collapse id="responsive-navbar-nav" className="justify-content-end">
                            <Nav>
                                {isAdmin && (
                                    <>
                                        <Nav.Link as={Link} to="/portal" eventKey="portal">
                                            Portal
                                        </Nav.Link>
                                        {isGlobalAdmin && (
                                            <>
                                                <Nav.Link as={Link} to="/file-manager" eventKey="fileManager">
                                                    File Manager
                                                </Nav.Link>
                                                <Nav.Link as={Link} to="/file-upload" eventKey="fileUpload">
                                                    File Upload
                                                </Nav.Link>
                                                <Nav.Link as={Link} to="/key-dates" eventKey="keyDates">
                                                    Key Dates
                                                </Nav.Link>
                                            </>
                                        )}
                                        <Nav.Link as={Link} to="/tenants" eventKey="tenants">
                                            Systems / Facilities
                                        </Nav.Link>
                                    </>
                                )}

                                <Nav.Link as={Link} to="/users" eventKey="users">
                                    Users
                                </Nav.Link>

                                <Nav.Link as={Link} to="/legal-disclaimer" eventKey="legalDisclaimer">
                                    Legal Disclaimer
                                </Nav.Link>

                                <NavDropdown title={userName} className="header-item d-none d-lg-block">
                                    <NavDropdown.Item as={Link} to="/app-settings">
                                        App Settings
                                    </NavDropdown.Item>
                                    <NavDropdown.Item onClick={handleLogout}>Log Out</NavDropdown.Item>
                                </NavDropdown>
                            </Nav>
                        </Navbar.Collapse>
                    </Container>
                </Navbar>
            </div>
            <LogoutModal {...logoutModalProps} />
        </>
    );
}
