import { PropsWithChildren, useCallback, useEffect, useMemo, useState } from "react";
import { useContext, useStoredState } from "../../hooks";
import { HttpOptions, Program } from "../../models";
import { useHttp } from "../http";
import { ProgramContext, ProgramState } from "./ProgramContext";

export const initialProgramState: ProgramState = {
    programs: [],
};

export const ProgramProvider = (props: PropsWithChildren) => {
    const { get } = useHttp();
    const [httpOptions] = useState<HttpOptions>({ path: "program" });

    const [programState, setProgramState] = useStoredState("program", initialProgramState);

    const getPrograms = useCallback(async () => {
        const programs = await get<Program[]>(httpOptions);
        if (!programs) return;
        setProgramState((prev) => ({ ...prev, programs }));
    }, [get, httpOptions]);

    useEffect(() => {
        getPrograms();
    }, []);

    const value = useMemo(
        () => ({
            ...programState,
            getPrograms,
        }),
        [programState, getPrograms]
    );

    return <ProgramContext.Provider value={value} {...props} />;
};

export const usePrograms = () => useContext(ProgramContext);
