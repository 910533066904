import { useMemo, PropsWithChildren, useState } from "react";
import { useCallbackOnInterval, useContext, useStoredState } from "../../hooks";
import { DecodedAccessToken } from "../../models";
import { AuthContext, AuthState } from "./AuthContext";

const defaultDecodedAccessToken: DecodedAccessToken = {
    roles: "",
    user_id: "",
    sub: "",
    tenant_name: "",
    tenant_id: "",
    family_name: "",
    given_name: "",
    email: "",
    qr_code_image: "",
    manual_entry_code: "",
    nbf: -1,
    exp: -1,
    auth_time: -1,
    payment_database_access: "",
} as const;

export const defaultAuthState: AuthState = {
    accessToken: "",
    decodedAccessToken: defaultDecodedAccessToken,
    failedLoginAttempts: 0,
    isAuthenticated: false,
    isLoggingIn: false,
    isLoggingOut: false,
    refreshToken: "",
    shouldLogout: false,
} as const;

export const AuthProvider = (props: PropsWithChildren) => {
    const [authState, setAuthState] = useStoredState<AuthState>("auth", defaultAuthState);
    const { decodedAccessToken, isAuthenticated, isLoggingIn, isLoggingOut } = authState;

    const refreshAtSeconds = 120;
    const [currentTime, setCurrentTime] = useState<number>(new Date().getTime());

    useCallbackOnInterval(() => setCurrentTime(new Date().getTime()), 1000);

    const secondsRemaining = useMemo((): number => {
        const result = Math.floor(decodedAccessToken.exp - currentTime / 1000);
        return result < 0 ? 0 : result;
    }, [currentTime, decodedAccessToken.exp]);

    const canRefresh = useMemo(
        () => isAuthenticated && !isLoggingIn && !isLoggingOut && secondsRemaining < refreshAtSeconds,
        [isAuthenticated, isLoggingIn, isLoggingOut, secondsRemaining, refreshAtSeconds]
    );

    const isAuthorized = useMemo(
        () => isAuthenticated && !!decodedAccessToken.tenant_name,
        [isAuthenticated, decodedAccessToken]
    );

    const shouldLogin = useMemo(
        () => !isAuthenticated && !isLoggingIn && !isLoggingOut,
        [isAuthenticated, isLoggingIn, isLoggingOut]
    );

    const value = useMemo(
        () => ({
            ...authState,
            canRefresh,
            isAuthorized,
            setAuthState,
            shouldLogin,
        }),
        [authState, canRefresh, isAuthorized, setAuthState, shouldLogin]
    );

    return <AuthContext.Provider value={value} {...props} />;
};

export const useAuth = () => useContext(AuthContext);
