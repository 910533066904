import React, { useState } from "react";
import { Tabs, Tab, Form, Row, Col, Container } from "react-bootstrap";
import { useAuth, useTenants } from "../../contexts";
import AppSettingsForm from "./AppSettingsForm";
import { ImmutableRole } from "../../models/role/ImmutableRole";
import { ROOT_TENANT_ID } from "../../config/config";

export default function AppSettings() {
    const { decodedAccessToken } = useAuth();
    const { tenants } = useTenants();
    const [activeTenantId, setActiveTenantId] = useState<number>(0);
    const isGlobalAdmin = decodedAccessToken.roles === ImmutableRole.GlobalAdmin;
    const rootTenantId = ROOT_TENANT_ID ? +ROOT_TENANT_ID : 1;
    const rootTenant = tenants.find((t) => t.id === rootTenantId);

    if (isGlobalAdmin)
        return (
            <Container className="pt-2 app-settings">
                <Tabs className="mb-3">
                    <Tab eventKey="global" title="Global Settings">
                        <AppSettingsForm tenantId={rootTenant?.id || 1} />
                    </Tab>
                    <Tab eventKey="tenant" title="Partner Overrides">
                        <Row>
                            <Col sm={3}>
                                <Form.Select
                                    value={activeTenantId}
                                    onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
                                        setActiveTenantId(+e.target.value)
                                    }
                                    className="mb-3"
                                >
                                    <option value={0} disabled>
                                        Select a partner...
                                    </option>
                                    {tenants
                                        .filter((t) => t.id !== rootTenantId && !t.parentTenantId)
                                        .map((t) => (
                                            <option key={`tenant-${t.id}`} value={t.id}>
                                                {t.name}
                                            </option>
                                        ))}
                                </Form.Select>
                            </Col>
                        </Row>
                        {activeTenantId > 0 && <AppSettingsForm tenantId={activeTenantId} />}
                    </Tab>
                </Tabs>
            </Container>
        );

    // uncomment to allow partner admins to change their own settings
    // return (
    //     <div>
    //         <AppSettingsForm tenantId={decodedAccessToken.tenant_id} />
    //     </div>
    // );

    return null;
}
