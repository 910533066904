import { useEffect, useState } from "react";
import { useLocalStorage } from ".";

export const useStoredState = <T>(key: string, value: T) => {
    const [storedState, setStoredState] = useLocalStorage<T>({ key, value });
    const [state, setState] = useState<T>(storedState);

    useEffect(() => {
        setStoredState(state);
    }, [setStoredState, state]);

    return [state, setState] as const;
};