import { ColumnDef } from "@tanstack/react-table";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { DataTable } from "../../component-library";
import { useKeyDates } from "../../contexts/key-date";
import { useKeyDateSystemTypes } from "../../contexts/key-date-system-type";
import { getDateWithMonthName } from "../../helper-functions/getDateWithMonthName";
import { FormProps } from "../../models";
import { KeyDateForm } from "./KeyDateForm";
import { useAuth } from "../../contexts";
import AccessDeniedMessage from "../access-denied/AccessDenied";

export default function KeyDates() {
    const { decodedAccessToken } = useAuth();
    const isAdmin = decodedAccessToken.roles === "GlobalAdmin";

    const { keyDateSystemTypes } = useKeyDateSystemTypes();
    const { getAllKeyDates, keyDates, selectKeyDate, clearSelectedKeyDate } = useKeyDates();
    const [keyDateFormProps, setkeyDateFormProps] = useState<FormProps>({
        show: false,
        type: "add",
        onClose: () => {},
    });

    useEffect(() => {
        getAllKeyDates();
    }, []);

    const keyDateData = Object.entries(keyDates).map(([key, value]) => ({
        id: value.id,
        title: value.title,
        description: value.description,
        keyDateType: keyDateSystemTypes.find((kdst) => kdst.id === value.keyDateSystemTypeId)?.name,
        eventDate: getDateWithMonthName(value.eventDate),
        eventDisplayStartDate: getDateWithMonthName(value.eventDisplayStartDate),
        eventDisplayEndDate: getDateWithMonthName(value.eventDisplayEndDate),
        key: key,
    }));

    const hideKeyDateModal = () => setkeyDateFormProps((prev) => ({ ...prev, show: false }));
    const showKeyDateEditModal = useCallback(
        (id: number) => {
            const keyDate = keyDates.find((kd) => kd.id === id);
            selectKeyDate(keyDate!);
            setkeyDateFormProps({
                onClose: hideKeyDateModal,
                show: true,
                type: "edit",
            });
        },
        [keyDates, selectKeyDate]
    );

    const showKeyDateAddModal = useCallback(() => {
        clearSelectedKeyDate();
        setkeyDateFormProps({
            onClose: hideKeyDateModal,
            show: true,
            type: "add",
        });
    }, [clearSelectedKeyDate]);

    const columns = useMemo(
        (): ColumnDef<any>[] => [
            {
                header: "Title",
                accessorKey: "title",
            },
            {
                header: "Description",
                accessorKey: "description",
            },
            {
                header: "Type",
                accessorKey: "keyDateType",
            },
            {
                header: () => <div style={{ whiteSpace: "nowrap" }}>Event Date</div>,
                accessorKey: "eventDate",
            },
            {
                header: () => <div style={{ whiteSpace: "nowrap" }}>Display Start Date</div>,
                accessorKey: "eventDisplayStartDate",
            },
            {
                header: () => <div style={{ whiteSpace: "nowrap" }}>Display End Date</div>,
                accessorKey: "eventDisplayEndDate",
            },
            {
                header: "",
                id: "actions",
                cell: ({ row }) => {
                    return (
                        <div className="text-end">
                            <Button
                                size="sm"
                                variant="outline-info"
                                title="Edit"
                                onClick={() => showKeyDateEditModal(row.original.id)}
                            >
                                <i className="fas fa-edit" />
                            </Button>
                        </div>
                    );
                },
            },
        ],
        [showKeyDateEditModal]
    );

    return isAdmin ? (
        <Container fluid className="pt-2">
            <Row>
                <Col>
                    <h1>Key Dates</h1>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Button onClick={showKeyDateAddModal} variant="primary">
                        New Key Date
                    </Button>
                </Col>
            </Row>

            <DataTable data={keyDateData} columns={columns} paginated={keyDateData.length > 20} filterColumns />

            {keyDateFormProps.show && <KeyDateForm {...keyDateFormProps} />}
        </Container>
    ) : (
        <Container fluid className="pt-2">
            <AccessDeniedMessage />
        </Container>
    );
}
