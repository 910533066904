import { createContext } from "react";
import { Role } from "../../models";

export interface RoleState {
    roles: Role[];
}

interface IRoleContext extends RoleState {
    getRoles: () => void;
}

export const RoleContext = createContext<IRoleContext | undefined>(undefined);
RoleContext.displayName = "Role";