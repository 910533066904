import { useCallback, useMemo, useState, useEffect, PropsWithChildren } from "react";
import { createPatch, Operation } from "rfc6902";
import { AppSettings, HttpOptions, Operable } from "../../models";
import { AppSettingsContext, useAuth, useHttp } from "..";
import { useContext } from "../../hooks";

export const AppSettingsProvider = (props: PropsWithChildren) => {
    const { isAuthenticated, decodedAccessToken } = useAuth();
    const { get, post, patchUnique } = useHttp();
    const [httpOptions] = useState<HttpOptions>({ path: "appsettings" });
    const [appSettings, setAppSettings] = useState<AppSettings>({} as AppSettings);

    const customerName = appSettings.customerName || "Customer";
    const partnerName = appSettings.partnerName || "Partner";
    const logoUri = appSettings.logoUri || "";
    const siteName = appSettings.siteName || "KEYSYS";
    const menuBackgroundColor = appSettings.menuBackgroundColor || "#0879A8";
    const menuSelectedColor = appSettings.menuSelectedColor || "#001325";

    const getAppSettings = useCallback(async () => {
        if (isAuthenticated && decodedAccessToken.user_id) {
            const response = await get<AppSettings>(httpOptions);
            if (response) {
                setAppSettings(response);
            }
        }
    }, [get, httpOptions, decodedAccessToken.user_id, isAuthenticated]);

    useEffect(() => {
        getAppSettings();
    }, []);

    const createAppSettings = useCallback(
        (settings: AppSettings): Promise<AppSettings | void> => {
            return post<AppSettings>({ ...httpOptions, body: settings });
        },
        [httpOptions, post]
    );

    const editAppSettings = useCallback(
        async ({ original, updated }: Operable<AppSettings>): Promise<AppSettings | void> => {
            const response = await patchUnique<Operation[], AppSettings>({
                path: `appsettings/${original.id}`,
                body: createPatch(original, updated)
            });
            if (response && response.tenantId === +decodedAccessToken.tenant_id) {
                setAppSettings(response);
            }
            return response;
        },
        [patchUnique, decodedAccessToken.tenant_id]
    );

    const value = useMemo(
        () => ({
            customerName,
            partnerName,
            logoUri,
            siteName,
            menuBackgroundColor,
            menuSelectedColor,
            createAppSettings,
            editAppSettings,
        }),
        [
            customerName,
            partnerName,
            logoUri,
            siteName,
            menuBackgroundColor,
            menuSelectedColor,
            createAppSettings,
            editAppSettings,
        ]
    );

    return <AppSettingsContext.Provider value={value} {...props} />;
};

export const useAppSettings = () => useContext(AppSettingsContext);
