import { createContext } from "react";
import { Payment } from "../../models/payment/Payment";
import { Filter } from "../../models/filter/Filter";
import { AxiosResponse } from "axios";

export interface PaymentState {
    payments: Payment[];
    yoyPayments: Payment[];
}

interface IPaymentContext extends PaymentState {
    getPayments: (filters: Filter) => Promise<void>;
    downloadPayments: (filters: Filter) => Promise<AxiosResponse<any, any>> | void;
}

export const PaymentContext = createContext<IPaymentContext | undefined>(undefined);
PaymentContext.displayName = "Payment";
