import { PropsWithChildren, useCallback, useEffect, useMemo, useState } from "react";
import { useContext, useStoredState } from "../../hooks";
import { HttpOptions } from "../../models";
import { KeyDateSystemType } from "../../models/key-date-system-type/KeyDateSystemType";
import { useHttp } from "../http";
import { KeyDateSystemTypeContext, KeyDateSystemTypeState } from "./KeyDateSystemTypeContext";

export const initialKeyDateSystemTypeState: KeyDateSystemTypeState = {
    keyDateSystemTypes: [],
};

export const KeyDateSystemTypeProvider = (props: PropsWithChildren) => {
    const { get } = useHttp();
    const [httpOptions] = useState<HttpOptions>({ path: "key-date-system-type" });

    const [keyDateSystemTypeState, setKeyDateSystemTypeState] = useStoredState(
        "keyDateSystemType",
        initialKeyDateSystemTypeState
    );

    const getKeyDateSystemTypes = useCallback(async () => {
        const keyDateSystemTypes = await get<KeyDateSystemType[]>(httpOptions);
        if (!keyDateSystemTypes) return;
        setKeyDateSystemTypeState((prev) => ({ ...prev, keyDateSystemTypes }));
    }, [get, httpOptions]);

    useEffect(() => {
        getKeyDateSystemTypes();
    }, []);

    const value = useMemo(
        () => ({
            ...keyDateSystemTypeState,
            getKeyDateSystemTypes,
        }),
        [getKeyDateSystemTypes, keyDateSystemTypeState]
    );

    return <KeyDateSystemTypeContext.Provider value={value} {...props} />;
};

export const useKeyDateSystemTypes = () => useContext(KeyDateSystemTypeContext);
