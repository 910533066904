import { useCallback, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { FacilityFilterForm } from "./FacilityFilter";
import { FormProps } from "../../models";
import { ViewFacilitiesTable } from "./ViewFacilitiesTable";
import { ViewProgramsTable } from "./ViewProgramsTable";
import { PieChart, Pie, Tooltip, Label, ResponsiveContainer } from "recharts";
import { useAuth, usePayments, usePrograms, useTenants } from "../../contexts";
import _ from "lodash";
import { TopPerformingFacilities } from "./TopPerformingFacilities";
import { YOYComparision } from "./YOYComparision";
import { ViewSystemsTable } from "./ViewSystemsTable";
import { ViewStatesTable } from "./ViewStatesTable";
import { ChartTooltip } from "../charts/ChartTooltip";
import { ViewCountiesTable } from "./ViewCountiesTable";
import { ViewHospitalTypeTable } from "./ViewHospitalTypeTable";
import { getChartColorByKey } from "../../helper-functions/getChartColorByKey";
import { Link } from "react-router-dom";
import AccessDeniedMessage from "../access-denied/AccessDenied";

export const Facilities = () => {
    const { decodedAccessToken } = useAuth();
    const isAdmin = decodedAccessToken.roles === "GlobalAdmin";
    const hasPaymentDatabaseAccess = decodedAccessToken.payment_database_access === "True";
    //Systems
    const [viewSystemsTableProps, setViewSystemsTableProps] = useState<FormProps>({
        show: false,
        type: "add",
        onClose: () => {},
    });

    const hideSystemsViewTable = useCallback(() => setViewSystemsTableProps((prev) => ({ ...prev, show: false })), []);

    const showSystemsViewTable = useCallback(() => {
        setViewSystemsTableProps({
            onClose: hideSystemsViewTable,
            show: true,
            type: "add",
        });
    }, [hideSystemsViewTable]);

    //Facilities
    const [viewFacilitiesTableProps, setViewFacilitiesTableProps] = useState<FormProps>({
        show: false,
        type: "add",
        onClose: () => {},
    });

    const hideFacilitiesViewTable = useCallback(
        () => setViewFacilitiesTableProps((prev) => ({ ...prev, show: false })),
        []
    );

    const showFacilitiesViewTable = useCallback(() => {
        setViewFacilitiesTableProps({
            onClose: hideFacilitiesViewTable,
            show: true,
            type: "add",
        });
    }, [hideFacilitiesViewTable]);

    //Programs
    const [viewProgramsTableProps, setViewProgramsTableProps] = useState<FormProps>({
        show: false,
        type: "add",
        onClose: () => {},
    });

    const hideProgramsViewTable = useCallback(
        () => setViewProgramsTableProps((prev) => ({ ...prev, show: false })),
        []
    );

    const showProgramsViewTable = useCallback(() => {
        setViewProgramsTableProps({
            onClose: hideProgramsViewTable,
            show: true,
            type: "add",
        });
    }, [hideProgramsViewTable]);

    //States
    const [viewStatesTableProps, setViewStatesTableProps] = useState<FormProps>({
        show: false,
        type: "add",
        onClose: () => {},
    });

    const hideStatesViewTable = useCallback(() => setViewStatesTableProps((prev) => ({ ...prev, show: false })), []);

    const showStatesViewTable = useCallback(() => {
        setViewStatesTableProps({
            onClose: hideStatesViewTable,
            show: true,
            type: "add",
        });
    }, [hideStatesViewTable]);

    //Counties
    const [viewCountiesTableProps, setViewCountiesTableProps] = useState<FormProps>({
        show: false,
        type: "add",
        onClose: () => {},
    });

    const hideCountiesViewTable = useCallback(
        () => setViewCountiesTableProps((prev) => ({ ...prev, show: false })),
        []
    );

    const showCountiesViewTable = useCallback(() => {
        setViewCountiesTableProps({
            onClose: hideCountiesViewTable,
            show: true,
            type: "add",
        });
    }, [hideCountiesViewTable]);

    //HospitalTypes
    const [viewHospitalTypesTableProps, setViewHospitalTypesTableProps] = useState<FormProps>({
        show: false,
        type: "add",
        onClose: () => {},
    });

    const hideHospitalTypesViewTable = useCallback(
        () => setViewHospitalTypesTableProps((prev) => ({ ...prev, show: false })),
        []
    );

    const showHospitalTypesViewTable = useCallback(() => {
        setViewHospitalTypesTableProps({
            onClose: hideHospitalTypesViewTable,
            show: true,
            type: "add",
        });
    }, [hideHospitalTypesViewTable]);

    const { payments } = usePayments();
    const { programs } = usePrograms();
    const { tenants, states } = useTenants();
    var isStateSelected = localStorage.getItem("FilterStateId") !== "0";

    const total = payments.reduce((a, v) => (a = a + v.amount), 0);
    const totalFormatted = `$${total.toLocaleString(undefined, { maximumFractionDigits: 0 })}`;
    const systemGrouped = _.groupBy(payments, (p) => p.systemId);
    const programGrouped = _.groupBy(payments, (p) => p.programId);
    const stateGrouped = _.groupBy(payments, (p) => p.stateId);
    const countyGrouped = _.groupBy(payments, (p) => p.county);
    const hospitalTypeGrouped = _.groupBy(payments, (p) => p.hospitalType);

    const systemData = Object.entries(systemGrouped).map(([key, value]) => ({
        name: tenants.find((t) => t.id === value[0].systemId)?.name,
        value: value.reduce((a, v) => (a = a + v.amount), 0),
    }));

    const systemDataSorted = _.orderBy(systemData, (s) => s.value, "desc").slice(0, 5);
    const systemDataForChart = Object.entries(systemDataSorted).map(([key, value]) => ({
        name: value.name,
        value: value.value,
        fill: getChartColorByKey(key),
    }));

    const programData = Object.entries(programGrouped).map(([key, value]) => ({
        name: programs.find((p) => p.id === value[0].programId)?.name,
        value: value.reduce((a, v) => (a = a + v.amount), 0),
    }));

    const programDataSorted = _.orderBy(programData, (s) => s.value, "desc").slice(0, 5);
    const programDataForChart = Object.entries(programDataSorted).map(([key, value]) => ({
        name: value.name,
        value: value.value,
        fill: getChartColorByKey(key),
    }));

    const stateData = Object.entries(stateGrouped).map(([key, value]) => ({
        name: states.find((s) => s.id === value[0].stateId)?.name,
        value: value.reduce((a, v) => (a = a + v.amount), 0),
    }));

    const stateDataSorted = _.orderBy(stateData, (s) => s.value, "desc").slice(0, 5);
    const stateDataForChart = Object.entries(stateDataSorted).map(([key, value]) => ({
        name: value.name,
        value: value.value,
        fill: getChartColorByKey(key),
    }));

    const countyData = Object.entries(countyGrouped).map(([key, value]) => ({
        name: value[0].county,
        value: value.reduce((a, v) => (a = a + v.amount), 0),
    }));

    const countyDataSorted = _.orderBy(countyData, (s) => s.value, "desc").slice(0, 5);
    const countyDataForChart = Object.entries(countyDataSorted).map(([key, value]) => ({
        name: value.name,
        value: value.value,
        fill: getChartColorByKey(key),
    }));

    const hospitalTypeData = Object.entries(hospitalTypeGrouped).map(([key, value]) => ({
        name: value[0].hospitalType,
        value: value.reduce((a, v) => (a = a + v.amount), 0),
    }));

    const hospitalTypeDataSorted = _.orderBy(hospitalTypeData, (s) => s.value, "desc").slice(0, 5);
    const hospitalTypeDataForChart = Object.entries(hospitalTypeDataSorted).map(([key, value]) => ({
        name: value.name,
        value: value.value,
        fill: getChartColorByKey(key),
    }));

    const renderLabel = function (data: any) {
        return data?.name?.length > 10 ? `${data.name.substring(0, 10)}...` : data.name;
    };

    return (
        <Container fluid>
            <Row className="gx-2">
                {isAdmin || hasPaymentDatabaseAccess ? (
                    <>
                        <Col lg={3} className="background-grey select-sidebar filter-margin">
                            <FacilityFilterForm />
                        </Col>
                        <Col lg={9} className="pt-1">
                            <h2>Medicaid Supplemental Payment Database</h2>
                            <Row className="gx-2">
                                <Col lg={6} xl={4} className="mb-2">
                                    <div className="chart-border h-100">
                                        <div className="d-flex justify-content-between">
                                            <div>
                                                <h4 className="modal-header">System</h4>
                                            </div>
                                            <div className="view-table-text">
                                                <Link to="" onClick={() => showSystemsViewTable()}>
                                                    View Table
                                                </Link>
                                            </div>
                                        </div>
                                        {systemData.length ? (
                                            <ResponsiveContainer height={350}>
                                                <PieChart width={375} height={350}>
                                                    <Pie
                                                        dataKey="value"
                                                        data={systemDataForChart}
                                                        innerRadius={65}
                                                        outerRadius={80}
                                                        label={renderLabel}
                                                    >
                                                        <Label
                                                            value={totalFormatted}
                                                            position="centerBottom"
                                                            className="label-top fw-bold"
                                                            fontSize="15px"
                                                        />
                                                    </Pie>
                                                    <Tooltip content={<ChartTooltip total={total} />} />
                                                </PieChart>
                                            </ResponsiveContainer>
                                        ) : (
                                            <div className="fw-bold no-data">No Data</div>
                                        )}
                                    </div>
                                </Col>
                                <Col lg={6} xl={4} className="mb-2">
                                    <div className="chart-border h-100">
                                        <div className="d-flex justify-content-between">
                                            <div>
                                                <h4 className="modal-header">Program</h4>
                                            </div>
                                            <div className="view-table-text">
                                                <Link to="" onClick={() => showProgramsViewTable()}>
                                                    View Table
                                                </Link>
                                            </div>
                                        </div>
                                        {programData.length ? (
                                            <ResponsiveContainer height={350}>
                                                <PieChart width={375} height={350}>
                                                    <Pie
                                                        dataKey="value"
                                                        data={programDataForChart}
                                                        innerRadius={65}
                                                        outerRadius={80}
                                                        label={renderLabel}
                                                    >
                                                        <Label
                                                            value={totalFormatted}
                                                            position="centerBottom"
                                                            className="label-top fw-bold"
                                                            fontSize="15px"
                                                        />
                                                    </Pie>
                                                    <Tooltip content={<ChartTooltip total={total} />} />
                                                </PieChart>
                                            </ResponsiveContainer>
                                        ) : (
                                            <div className="fw-bold no-data">No Data</div>
                                        )}
                                    </div>
                                </Col>
                                {!isStateSelected && (
                                    <Col lg={6} xl={4} className="mb-2">
                                        <div className="chart-border h-100">
                                            <div className="d-flex justify-content-between">
                                                <div>
                                                    <h4 className="modal-header">Location</h4>
                                                </div>
                                                <div className="view-table-text">
                                                    <Link to="" onClick={() => showStatesViewTable()}>
                                                        View Table
                                                    </Link>
                                                </div>
                                            </div>
                                            {stateData.length ? (
                                                <ResponsiveContainer height={350}>
                                                    <PieChart width={375} height={350}>
                                                        <Pie
                                                            dataKey="value"
                                                            data={stateDataForChart}
                                                            innerRadius={65}
                                                            outerRadius={80}
                                                            label={renderLabel}
                                                        >
                                                            <Label
                                                                value={totalFormatted}
                                                                position="centerBottom"
                                                                className="label-top fw-bold"
                                                                fontSize="15px"
                                                            />
                                                        </Pie>
                                                        <Tooltip content={<ChartTooltip total={total} />} />
                                                    </PieChart>
                                                </ResponsiveContainer>
                                            ) : (
                                                <div className="fw-bold no-data">No Data</div>
                                            )}
                                        </div>
                                    </Col>
                                )}
                                {isStateSelected && (
                                    <Col lg={6} xl={4} className="mb-2">
                                        <div className="chart-border h-100">
                                            <div className="d-flex justify-content-between">
                                                <div>
                                                    <h4 className="modal-header">Location</h4>
                                                </div>
                                                <div className="view-table-text">
                                                    <Link to="" onClick={() => showCountiesViewTable()}>
                                                        View Table
                                                    </Link>
                                                </div>
                                            </div>
                                            {countyData.length ? (
                                                <ResponsiveContainer height={350}>
                                                    <PieChart width={375} height={350}>
                                                        <Pie
                                                            dataKey="value"
                                                            data={countyDataForChart}
                                                            innerRadius={65}
                                                            outerRadius={80}
                                                            label={renderLabel}
                                                        >
                                                            <Label
                                                                value={totalFormatted}
                                                                position="centerBottom"
                                                                className="label-top fw-bold"
                                                                fontSize="15px"
                                                            />
                                                        </Pie>
                                                        <Tooltip content={<ChartTooltip total={total} />} />
                                                    </PieChart>
                                                </ResponsiveContainer>
                                            ) : (
                                                <div className="fw-bold no-data">No Data</div>
                                            )}
                                        </div>
                                    </Col>
                                )}
                            </Row>
                            <Row className="d-flex align-items-stretch gx-2">
                                <Col lg={6} xl={4} className="mb-2">
                                    <div className="chart-border h-100">
                                        <div className="d-flex justify-content-between">
                                            <div>
                                                <h4 className="modal-header">Hospital Type</h4>
                                            </div>
                                            <div className="view-table-text">
                                                <Link to="" onClick={() => showHospitalTypesViewTable()}>
                                                    View Table
                                                </Link>
                                            </div>
                                        </div>
                                        {hospitalTypeData.length ? (
                                            <ResponsiveContainer height={350}>
                                                <PieChart width={375} height={350}>
                                                    <Pie
                                                        dataKey="value"
                                                        data={hospitalTypeDataForChart}
                                                        innerRadius={65}
                                                        outerRadius={80}
                                                        label={renderLabel}
                                                    >
                                                        <Label
                                                            value={totalFormatted}
                                                            position="centerBottom"
                                                            className="label-top fw-bold"
                                                            fontSize="15px"
                                                        />
                                                    </Pie>
                                                    <Tooltip content={<ChartTooltip total={total} />} />
                                                </PieChart>
                                            </ResponsiveContainer>
                                        ) : (
                                            <div className="fw-bold no-data">No Data</div>
                                        )}
                                    </div>
                                </Col>
                                <Col lg={6} xl={8} className="mb-2">
                                    <div className="chart-border h-100">
                                        <YOYComparision />
                                    </div>
                                </Col>
                            </Row>
                            <Row className="gx-2">
                                <Col lg={12}>
                                    <div className="chart-border">
                                        <TopPerformingFacilities />
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </>
                ) : (
                    <AccessDeniedMessage />
                )}
            </Row>
            {viewSystemsTableProps.show && <ViewSystemsTable {...viewSystemsTableProps} />}
            {viewFacilitiesTableProps.show && <ViewFacilitiesTable {...viewFacilitiesTableProps} />}
            {viewProgramsTableProps.show && <ViewProgramsTable {...viewProgramsTableProps} />}
            {viewStatesTableProps.show && <ViewStatesTable {...viewStatesTableProps} />}
            {viewCountiesTableProps.show && <ViewCountiesTable {...viewCountiesTableProps} />}
            {viewHospitalTypesTableProps.show && <ViewHospitalTypeTable {...viewHospitalTypesTableProps} />}
        </Container>
    );
};
