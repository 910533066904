import { FacilityFileContext, FacilityFileState } from "./FacilityFileContext";
import { PropsWithChildren, useCallback, useMemo, useState } from "react";
import { HttpOptions, Operable } from "../../models";
import { useContext, useStoredState } from "../../hooks";
import { FacilityFile } from "../../models/facility-file/FacilityFile";
import { useHttp } from "../http";
import { useAuth } from "../auth";
import { AWS_CONFIG } from "../../config/config";
import { createPatch, Operation } from "rfc6902";
import { replaceInCollection } from "../../helper-functions";
import { downloadFileFromS3WithPreSignedUrl, getPreSignedUrl } from "../../helper-functions/S3";
import { PreSignedUrlType } from "../../models/pre-signed-url/PreSignedUrlType";

export const initialFacilityFileState: FacilityFileState = {
    facilityFiles: [],
};

export const FacilityFileProvider = (props: PropsWithChildren) => {
    const { get, post, patchUnique } = useHttp();
    const [httpOptions] = useState<HttpOptions>({ path: "facility-file" });
    const [facilityFileState, setFacilityFileState] = useStoredState<FacilityFileState>(
        "facilityFile",
        initialFacilityFileState
    );
    const { accessToken } = useAuth();

    const getAllFacilityFiles = useCallback(async () => {
        const facilityFiles = await get<FacilityFile[]>({ path: `facility-file` });
        if (!facilityFiles) return;
        setFacilityFileState((prev) => ({ ...prev, facilityFiles }));
    }, [get, setFacilityFileState]);

    const getFacilityFiles = useCallback(
        async (tenantId: number) => {
            const facilityFiles = await get<FacilityFile[]>({ path: `facility-file/tenant/${tenantId}` });
            if (!facilityFiles) return;
            setFacilityFileState((prev) => ({ ...prev, facilityFiles }));
        },
        [get, setFacilityFileState]
    );

    const getFacilityFilesBySystemId = useCallback(
        async (systemId: number) => {
            const facilityFiles = await get<FacilityFile[]>({ path: `facility-file/system/${systemId}` });
            if (!facilityFiles) return;
            setFacilityFileState((prev) => ({ ...prev, facilityFiles }));
        },
        [get, setFacilityFileState]
    );

    const createFacilityFiles = useCallback(
        async (facilityFiles: FacilityFile[]) => {
            const createdFacilityFiles = await post<FacilityFile[]>({ ...httpOptions, body: facilityFiles });
            if (createdFacilityFiles) {
                setFacilityFileState((prev) => ({ ...prev, createdFacilityFiles }));
            }
            return !!createdFacilityFiles;
        },
        [httpOptions, post, setFacilityFileState]
    );

    const createFacilityFile = useCallback(
        async (facilityFile: FacilityFile) => {
            const createdFacilityFile = await post<FacilityFile>({ ...httpOptions, body: facilityFile });
            if (createdFacilityFile) {
                console.log(createFacilityFile);
            }
            return !!createdFacilityFile;
        },
        [httpOptions, post]
    );

    const editFacilityFile = useCallback(
        async ({ original, updated }: Operable<FacilityFile>) => {
            const patchedFile = await patchUnique<Operation[], FacilityFile>({
                path: `facility-file/${original.id}`,
                body: createPatch(original, updated),
            });
            if (patchedFile) {
                setFacilityFileState((prev) => ({
                    ...prev,
                    facilityFiles: replaceInCollection([...prev.facilityFiles], { original, updated }),
                }));
            }
            return !!patchedFile;
        },
        [patchUnique, setFacilityFileState]
    );

    const deleteFacilityFile = useCallback(
        async ({ original, updated }: Operable<FacilityFile>) => {
            const patchedFile = await patchUnique<Operation[], FacilityFile>({
                path: `facility-file/delete/${original.id}`,
                body: createPatch(original, updated),
            });
            if (patchedFile) {
                setFacilityFileState((prev) => ({
                    ...prev,
                    facilityFiles: [...prev.facilityFiles.filter((f) => f.id !== patchedFile.id), patchedFile],
                }));
            }
            return !!patchedFile;
        },
        [patchUnique, setFacilityFileState]
    );

    const downloadFacilityFileFromS3 = useCallback(
        async (awsFileId: string, fileName: string) => {
            var preSignedUrl = await getPreSignedUrl(
                accessToken,
                awsFileId,
                AWS_CONFIG.bucketName!,
                PreSignedUrlType.Download
            );
            await downloadFileFromS3WithPreSignedUrl(awsFileId, preSignedUrl);
        },
        [accessToken]
    );

    const selectFile = useCallback(
        (selectedFile: FacilityFile | undefined) => setFacilityFileState((prev) => ({ ...prev, selectedFile })),
        []
    );

    const clearSelectedFile = useCallback(
        () => setFacilityFileState((prev) => ({ ...prev, selectedFile: undefined })),
        []
    );

    const value = useMemo(
        () => ({
            ...facilityFileState,
            getAllFacilityFiles,
            getFacilityFiles,
            getFacilityFilesBySystemId,
            createFacilityFiles,
            createFacilityFile,
            editFacilityFile,
            deleteFacilityFile,
            downloadFacilityFileFromS3,
            selectFile,
            clearSelectedFile,
        }),
        [
            createFacilityFile,
            createFacilityFiles,
            editFacilityFile,
            deleteFacilityFile,
            downloadFacilityFileFromS3,
            facilityFileState,
            getAllFacilityFiles,
            getFacilityFiles,
            getFacilityFilesBySystemId,
            selectFile,
            clearSelectedFile,
        ]
    );
    return <FacilityFileContext.Provider value={value} {...props} />;
};

export const useFacilityFiles = () => useContext(FacilityFileContext);
