import { useMemo } from "react";
import { Modal } from "react-bootstrap";
import { DataTable } from "../../component-library";
import { ColumnDef } from "@tanstack/react-table";
import { usePayments, useTenants } from "../../contexts";
import _ from "lodash";
import { formatCurrencyWithoutDecimal } from "../../helper-functions/formatCurrencyWithoutDecimal";

export const YOYComparision = () => {
    const { tenants } = useTenants();
    const { yoyPayments } = usePayments();

    const facilityGrouped = _.groupBy(yoyPayments, (p) => p.tenantId);

    const currentYear = yoyPayments.reduce((a, v) => (a = a > v.year ? a : v.year), 0);
    var previousYear = currentYear - 1;

    var facilities = Object.entries(facilityGrouped).map(([key, value]) => ({
        facility: tenants.find((p) => p.id === value[0].tenantId)?.name,
        previousYearRevenue: value.filter((v) => v.year === previousYear).reduce((a, v) => (a = a + v.amount), 0),
        currentYearRevenue: value.filter((v) => v.year === currentYear).reduce((a, v) => (a = a + v.amount), 0),
    }));

    var yoyData = Object.entries(facilities).map(([key, value]) => ({
        facility: value.facility,
        currentYearRevenue: value.currentYearRevenue,
        previousYearRevenue: value.previousYearRevenue,
        change:
            value.currentYearRevenue > 0 || value.previousYearRevenue > 0
                ? (((value.currentYearRevenue - value.previousYearRevenue) / value.previousYearRevenue) * 100).toFixed(
                      0
                  )
                : 0,
    }));

    const facilityData = _.orderBy(yoyData, (facility) => facility.currentYearRevenue, "desc");
    const getBackgroundColor = function (change: number) {
        switch (true) {
            case change > 0:
                return "rgb(0, 235, 94, 0.6)";
            case change < 0:
                return "rgb(235, 42, 0, 0.6)";
            default:
                return "rgb(202, 235, 0, 0.6)";
        }
    };

    const columnDefs = useMemo(
        (): ColumnDef<any>[] => [
            {
                header: `Facility`,
                accessorKey: "facility",
            },
            {
                header: () => {
                    return <div className="currency-align">Previous Year ({previousYear})</div>;
                },
                accessorKey: "previousYearRevenue",
                cell: ({ row }) => {
                    return (
                        <div className="currency-align">
                            {formatCurrencyWithoutDecimal(row.original.previousYearRevenue)}
                        </div>
                    );
                },
            },
            {
                header: () => {
                    return <div className="currency-align">Current Year ({currentYear})</div>;
                },
                accessorKey: "currentYearRevenue",
                cell: ({ row }) => {
                    return (
                        <div className="currency-align">
                            {formatCurrencyWithoutDecimal(row.original.currentYearRevenue)}
                        </div>
                    );
                },
            },
            {
                header: () => {
                    return <div className="change-align">Change</div>;
                },
                accessorKey: "change",
                cell: ({ row }) => (
                    <div
                        style={{
                            borderRadius: "5px",
                            backgroundColor: `${getBackgroundColor(row.original.change)}`,
                            padding: "3px",
                        }}
                    >
                        <div style={{ textAlign: "center", fontSize: "0.9em" }}>
                            <strong>{`${row.original.change}%`}</strong>
                        </div>
                    </div>
                ),
            },
        ],
        [currentYear, previousYear]
    );
    return (
        <>
            <Modal.Header>YOY Facility Comparision</Modal.Header>
            {facilityData.length > 0 && (
                <DataTable data={facilityData} columns={columnDefs} paginated={facilityData.length > 5} pageSize={5} />
            )}
            {facilityData.length === 0 && <div className="fw-bold no-data">No Data</div>}
        </>
    );
};
