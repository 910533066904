const fillColors = [
    "#00B1FF",
    "#263238",
    "#CFD8DC",
    "#4E105E",
    "#1A93C9",
    "#0850CB",
    "#22437C",
    "#0741A5",
    "#063688",
    "#00B1FF",
];

export function getChartColorByKey(key: string) {
    switch (key) {
        case "1":
            return fillColors[0];
        case "2":
            return fillColors[1];
        case "3":
            return fillColors[2];
        case "4":
            return fillColors[3];
        case "5":
            return fillColors[4];
        default:
            return fillColors[5];
    }
}
