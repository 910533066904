import { createContext } from "react";

interface IOidcContext {
    login: () => Promise<void>;
    loginFail: () => Promise<void>;
    logout: () => Promise<void>;
    redirectToLogin: () => Promise<void>;
    refresh: () => Promise<void>;
}

export const OidcContext = createContext<IOidcContext | undefined>(undefined);
OidcContext.displayName = "Oidc";