import { useCallback, useMemo, useState } from "react";
import { Alert, Button, Container, Row } from "react-bootstrap";
import { useDropzone } from "react-dropzone";
import { useAuth, useTenants } from "../../contexts";
import { useFacilityFiles } from "../../contexts/facility-file";
import { useFileTypes } from "../../contexts/filetype";
import { useStoredState } from "../../hooks";
import { FilesState } from "../../models/facility-file/FileState";
import { FileFormProps } from "../../models/form/FileFormProps";
import { SingleFileUpload } from "./SingleFileUpload";
import { uploadFileToS3WithPreSignedUrl, getPreSignedUrl } from "../../helper-functions/S3";
import { AWS_CONFIG } from "../../config/config";
import { PreSignedUrlType } from "../../models/pre-signed-url/PreSignedUrlType";
import AccessDeniedMessage from "../access-denied/AccessDenied";

export const Dropzone = () => {
    const [filesState, setFilesState] = useStoredState<FilesState>("files", {
        files: [],
    });

    const [error, setError] = useState<string>("");
    const [success, setSuccess] = useState<string>("");
    const [filesUploaded, setFilesUploaded] = useState<string>("");
    const { tenants, states } = useTenants();
    const { fileTypes } = useFileTypes();
    const { decodedAccessToken, accessToken } = useAuth();
    const isAdmin = decodedAccessToken.roles === "GlobalAdmin";
    const { createFacilityFile } = useFacilityFiles();
    const [singleFileUploadFormProps, setSingleFileUploadFormProps] = useState<FileFormProps>({
        show: false,
        onClose: () => {},
        onSave: () => {},
        files: [],
    });

    const invalidFileExtensions = useMemo(() => {
        return ["exe", "bat", "dll", "swf", "jar", "zip", "rar", "iso", "msi", "ps1", "ps2", "cmd", "inf"];
    }, []);

    const hideSingleFileUploadModal = () => {
        setSuccess("");
        setSingleFileUploadFormProps((prev) => ({ ...prev, show: false }));
    };

    const hideSingleFileUploadModalOnSave = () => {
        setSuccess("Successfully Uploaded Files");
        setSingleFileUploadFormProps((prev) => ({ ...prev, show: false }));
    };

    const isValidFileExtension = useCallback(
        (filename: string) => {
            const extensionSplit = filename.split(".");
            const extension = extensionSplit[extensionSplit.length - 1].toLowerCase();
            if (invalidFileExtensions.includes(extension)) {
                return false;
            }
            return true;
        },
        [invalidFileExtensions]
    );

    const showSingleFileUploadModal = useCallback(
        (files: File[]) => {
            const filename = files[0].name;
            const isValid = isValidFileExtension(filename);
            if (!isValid) {
                setError(`${error}Invalid file extension for ${filename} \n`);
                return;
            }

            setSingleFileUploadFormProps({
                onSave: hideSingleFileUploadModalOnSave,
                onClose: hideSingleFileUploadModal,
                show: true,
                files: files,
            });
        },
        [error, isValidFileExtension]
    );

    const onDrop = useCallback(
        (acceptedFiles: File[]) => {
            if (acceptedFiles.length > 1) {
                setError("");
                setFilesUploaded("");
                setSuccess("");
                var errors = "";
                var filesUploaded = "";
                acceptedFiles.forEach((file) => {
                    const isValid = isValidFileExtension(file.name);
                    if (!isValid) {
                        errors = `${errors}Invalid file extension for ${file.name} \n`;
                        return;
                    }

                    const fileNameSplit = file.name.split("_");
                    var fileError = "";
                    if (fileNameSplit.length === 1)
                        errors = `${errors}Please correct filename format for ${file.name} \n`;

                    const fileType = fileTypes.find((ft) => ft.prefix === fileNameSplit[0])?.prefix;
                    if (!fileType) fileError = `Filetype not recognized for ${file.name} \n`;

                    if (!tenants.find((tenant) => tenant.id.toString() === fileNameSplit[1]))
                        fileError = `${fileError}System not recognized for ${file.name} \n`;

                    if (fileType === "LPPF" || fileType === "OTHER" || fileType === "BA" || fileType === "SPT") {
                        const state = fileNameSplit[2];
                        if (!state || !states.find((s) => s.abbreviation === state))
                            fileError = `${fileError} State not recognized or a valid state for ${file.name} \n`;
                    }

                    if (fileType === "LPPF") {
                        const county = fileNameSplit[3];
                        if (!county) fileError = `${fileError} Enter county for ${file.name} \n`;
                    }

                    if (fileError !== "") {
                        errors = `${errors}${fileError}`;
                    } else {
                        const fileType = fileTypes.find((ft) => ft.prefix === fileNameSplit[0])?.name;
                        const facility = tenants.find((t) => t.id.toString() === fileNameSplit[1])?.name;
                        filesUploaded = `${filesUploaded}${file.name} is valid ${fileType} file for ${facility} \n`;
                    }
                });
                if (filesUploaded !== "") {
                    setFilesUploaded(filesUploaded);
                }

                if (errors !== "") {
                    setError(errors);
                    setFilesState({ files: [] });
                } else {
                    setFilesState({ files: acceptedFiles });
                }
            } else {
                showSingleFileUploadModal(acceptedFiles);
            }
        },
        [fileTypes, isValidFileExtension, setFilesState, showSingleFileUploadModal, states, tenants]
    );

    const uploadFiles = useCallback(() => {
        filesState.files.forEach(async (file: File) => {
            console.log(file.webkitRelativePath);
            const filename = file.webkitRelativePath;
            var preSignedUrl = await getPreSignedUrl(
                accessToken,
                filename,
                AWS_CONFIG.bucketName!,
                PreSignedUrlType.Upload
            );
            if (!preSignedUrl) {
                setError("Error getting pre-signed url");
                return;
            }

            const awsResult = await uploadFileToS3WithPreSignedUrl(file, preSignedUrl);
            if (awsResult === 200) {
                const fileNameSplit = file.name.split("_");
                const fileType = fileTypes.find((ft) => ft.prefix === fileNameSplit[0]);
                const fileTypeId = fileType ? fileType.id : 0;
                var stateId = 0;
                if (
                    fileType?.prefix === "LPPF" ||
                    fileType?.prefix === "OTHER" ||
                    fileType?.prefix === "BA" ||
                    fileType?.prefix === "SPT"
                ) {
                    var state = states.find((s) => s.abbreviation === fileNameSplit[2]);
                    stateId = state ? state.id : 0;
                }
                var county = "";
                if (fileType?.prefix === "LPPF") {
                    county = fileNameSplit[3];
                }
                const linkName = fileType?.prefix === "LPPF" ? fileNameSplit[4] : fileNameSplit[3];
                createFacilityFile({
                    tenantId: parseInt(decodedAccessToken.tenant_id.toString()),
                    filename: filename,
                    awsFilename: `Uploads/${filename}`,
                    fileTypeId: fileTypeId,
                    isActive: true,
                    id: 0,
                    systemId: +fileNameSplit[1],
                    stateId: stateId,
                    county: county,
                    linkName: linkName ? linkName.replace(/\.[^/.]+$/, "") : "",
                });
            }
            setSuccess("Successfully Uploaded Files");
        });
    }, [accessToken, createFacilityFile, decodedAccessToken.tenant_id, fileTypes, filesState.files, states]);

    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });
    let uploadInstructions =
        "For Budget Analysis (BA), use format BA_AHCVId_StateAbbreviation_Displayname.extension. e.g. BA_5_TX_BudgetAnalysis.csv \n";
    uploadInstructions = `${uploadInstructions}For LPPF (LPPF), use format LPPF_AHCVId_StateAbbreviation_CountyName_Linkname.extension. e.g. LPPF_5_TX_DallasCounty_LPPFWorkbook.csv \n`;
    uploadInstructions = `${uploadInstructions}For Monthly Cashflow (MCS), use format MCS_AHCVId_Displayname.extension. e.g. MCS_5_MonthlyCashflow.csv \n`;
    uploadInstructions = `${uploadInstructions}For Other (OTHER), use format OTHER_AHCVId_StateAbbreviation_Linkname.extension. e.g. OTHER_5_TX_OtherFile.csv \n`;
    uploadInstructions = `${uploadInstructions}For Supplemental Payment Timeline (SPT), use format SPT_AHCVId_StateAbbreviation_Linkname.extension. e.g. SPT_5_TX_SupplementalTime2022.csv \n`;
    uploadInstructions = `${uploadInstructions}For Upcoming Transactions (UT), use format UT_AHCVId_Linkname.extension. e.g. UT_5_UpcomingTransactions.csv \n`;

    return isAdmin ? (
        <>
            <Container className="pt-2 full-height full-width">
                <Row md={12}>
                    <Alert variant="info">Valid File Types: BA, LPPF, MCS, OTHER, SPT, UT</Alert>
                    <Alert variant="info">
                        <div className="display-linebreak">{uploadInstructions}</div>
                    </Alert>
                </Row>
                <Row md={12}>
                    {!!error && (
                        <Alert variant="danger">
                            <div className="display-linebreak">{error}</div>
                        </Alert>
                    )}
                </Row>
                <Row md={12}>
                    {!!success && (
                        <Alert variant="success">
                            <div className="display-linebreak">{success}</div>
                        </Alert>
                    )}
                </Row>
                <Row md={12}>
                    {!!filesUploaded && (
                        <Alert variant="success">
                            <div className="display-linebreak">{filesUploaded}</div>
                        </Alert>
                    )}
                </Row>
                <Row md={12} className="mb-2">
                    <div {...getRootProps()} className="dropzone-container">
                        <input {...getInputProps()} />
                        {isDragActive ? (
                            <p>Drop the files here ...</p>
                        ) : (
                            <p className="dropzone-placeholder">
                                Drag and drop your files here, or click to select files
                            </p>
                        )}
                    </div>
                </Row>
                <Row md={12}>
                    <div>
                        <Button
                            className={"add-partner-button finosec-button-info"}
                            data-testid={"add-partner-button"}
                            onClick={() => uploadFiles()}
                            disabled={filesState.files.length === 0 || error !== ""}
                        >
                            Upload Files
                        </Button>
                    </div>
                </Row>

                {singleFileUploadFormProps.show && <SingleFileUpload {...singleFileUploadFormProps} />}
            </Container>
        </>
    ) : (
        <Container fluid className="pt-2">
            <AccessDeniedMessage />
        </Container>
    );
};
