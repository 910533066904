import { CSSProperties } from "react";
import { Toast } from "react-bootstrap";
import { KeysysToastProps } from "./KeysysToastProps";
import { MAX_ZINDEX } from "../../constants/ZIndexConstants";
import { useToasts } from "../../contexts";

export const KeysysToast = (props: KeysysToastProps) => {
    const { hideToast } = useToasts();

    const iconClasses = props.theme === "success" ? "fas fa-check-circle" : "fas fa-exclamation-triangle";
    const iconStyle = props.theme === "success" ? { color: "green" } : { color: "red" };
    const defaultCloseDelay = 3000;

    const toastStyle: CSSProperties = {
        position: "fixed",
        zIndex: MAX_ZINDEX,
        right: 0,
        top: 0,
    };

    return (
        <div className="mt-2" style={toastStyle}>
            <Toast
                show={true}
                autohide={true}
                delay={!!props.delay ? props.delay : defaultCloseDelay}
                onClose={() => hideToast(props.name)}
                animation={true}
            >
                <Toast.Header closeButton={true}>
                    <i className={iconClasses} style={iconStyle} />
                    &nbsp; <strong className="me-auto">{props.titleInHeader}</strong>
                </Toast.Header>
                <Toast.Body style={{ background: "white" }}>{props.body}</Toast.Body>
            </Toast>
        </div>
    );
};
