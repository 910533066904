import "core-js/stable";
import "core-js/features/map";
import "reflect-metadata";
import "regenerator-runtime/runtime";
import "react-datepicker/dist/react-datepicker.css";
import "./scss/main.scss";
import "@fortawesome/fontawesome-free/css/all.min.css";
import ReactDOM from "react-dom/client";
import * as serviceWorker from "./serviceWorker";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { createBrowserHistory } from "history";
import { LicenseManager } from "ag-grid-enterprise";
import App from "./components/app/App";
import { Provider } from "./contexts";
import { Oidc } from "./components/oidc/Oidc";

//TODO: Need to move this to an enviromnet
LicenseManager.setLicenseKey(
    "CompanyName=KEY SYSTEMS CONSULTING INC,LicensedGroup=KEYSYS,LicenseType=MultipleApplications,LicensedConcurrentDeveloperCount=3,LicensedProductionInstancesCount=1,AssetReference=AG-010451,ExpiryDate=11_September_2021_[v2]_MTYzMTMxNDgwMDAwMA==56d0b93898a8e2d846c40b184cb1b2d8"
);

const history = createBrowserHistory();
const path = (/#!(\/.*)$/.exec(window.location.hash) || [])[1];
if (path) {
    history.replace(path);
}

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

root.render(
    <Provider>
        <BrowserRouter>
            <Routes>
                <Route path="/signin-oidc" element={<Oidc />} />
                <Route path="*" element={<App />} />
            </Routes>
        </BrowserRouter>
    </Provider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
