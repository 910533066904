import { createContext } from "react";
import { EmailArchive } from "../../models/emailArchive/EmailArchive";

export interface EmailArchiveState {
    emailArchives: EmailArchive[];
}

interface IEmailArchiveContext extends EmailArchiveState {
    getEmailArchives: () => Promise<void>;
}

export const EmailArchiveContext = createContext<IEmailArchiveContext | undefined>(undefined);
EmailArchiveContext.displayName = "EmailArchive";
