import { PropsWithChildren, useCallback, useMemo } from "react";
import { useContext, useStoredState } from "../../hooks";
import { FilterContext, FilterState } from "./FilterContext";
import { Filter } from "../../models/filter/Filter";

export const initialFilterState: FilterState = {
    filter: {
        stateId: 0,
        parentTenantId: 0,
        tenantId: 0,
        programId: 0,
        year: 0,
        county: "",
        region: "",
        paymentDbAccess: false,
    },
};

export const FilterProvider = (props: PropsWithChildren) => {
    const [filterState, setFilterState] = useStoredState("filter", initialFilterState);

    const setFilter = useCallback(
        (filter: Filter) => {
            setFilterState((prev) => ({ ...prev, filter }));
        },
        [setFilterState]
    );

    const value = useMemo(() => ({ ...filterState, setFilter }), [filterState, setFilter]);
    return <FilterContext.Provider value={value} {...props} />;
};

export const useFilter = () => useContext(FilterContext);
