import { createContext } from "react";
import { UpcomingTransaction } from "../../models/upcomingTransaction/UpcomingTransaction";

export interface UpcomingTransactionState {
    upcomingTransactions: UpcomingTransaction[];
}

interface IUpcomingTransactionContext extends UpcomingTransactionState {
    getUpcomingTransactions: (tenantId: number) => Promise<void>;
}

export const UpcomingTransactionContext = createContext<IUpcomingTransactionContext | undefined>(undefined);
UpcomingTransactionContext.displayName = "UpcomingTransaction";
